<template>
  <div class="w-full flex flex-col flex-grow items-center p-1">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div class="w-full flex flex-col flex-grow items-center flex-auto h-0 overflow-y-auto">
        <div class="w-full flex flex-col space-y-1 flex-grow max-w-3xl">
    <!-- Method -->
            <div class="w-full flex p-1 border-b truncate">
                <div class="flex-grow text-left p-1 whitespace-normal truncate font-semibold text-base">{{capitalizeFirstLetter(shipment.method)}}</div>
                <div v-if="shipment.shipmentState > 2 && warehouse" class="flex items-center p-1">
                    <Popper hover arrow placement="top" :content="'Mark as Unshipped.'">
                        <button class="bn-icon-only" :disabled="isWorking || shipment.deliveredDate" @click="moveToNotShipped()" >
                            <ReturnIcon />
                        </button>
                    </Popper>
                </div>
            </div>
    <!-- Date and Time -->
            <div class="w-full py-1">
                <div class="w-full text-left whitespace-normal truncate p-1 border-b">Date and Time</div>
                <div class="w-full flex flex-col py-1">
                    <div class="w-full flex flex-row items-center flex-wrap">
                        <div class="w-44 p-1">
                            <input v-model="shipment.shipmentDate" type="datetime-local" class="w-full" 
                             @keydown="event=> event.key !== 'Tab' && event.preventDefault()"
                             @change="updateShipmentSchedule('shipmentDate',shipment.shipmentDate,shipment.shipmentDate)"
                            :disabled="isWorking || shipment.shipmentState > 2"
                            >
                        </div>
                        <div class="w-auto p-1" :class="shipment.scheduleType === 'firm' ? 'text-red-600':'text-green-600'">
                            {{shipment.scheduleType}}
                        </div>
                        <div class="w-auto p-1">
                            <input v-model="shipment.duration" type="number" min="1" max="15" class="w-14 text-center flex-shrink-0"
                            @focus="prevValue = shipment.duration"
                            @blur="handleNumberInput();shipment.duration !== prevValue ? updateShipmentSchedule('duration',shipment.duration) : null"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full py-1">
                <div class="w-full text-left whitespace-normal truncate p-1 border-b">Delivery Details</div>
    <!-- Vehicle -->
                <div v-if="['delivery','tool'].includes(shipment.method)" class="w-full flex flex-col">
                    <div v-if="shipment?.vehicleId?.name" class="w-full text-left whitespace-normal truncate px-2 py-1">{{shipment?.vehicleId?.name}}</div>
                    <div class="w-full flex p-1 max-w-md">
                        <searchApi :apiEndpoint="'search/vehicles'" :placeholder="`${shipment?.vehicleId?._id ? 'change' : 'assign'} vehicle`" :displayResult="false" @resultSelected="updateShipmentSchedule('vehicleId',$event?._id)" :disabled="isWorking || shipment.shipmentState > 2 || !warehouse" />
                    </div>
                </div>
    <!-- Driver -->
                <div v-if="['delivery','tool'].includes(shipment.method)" class="w-full flex flex-col">
                    <div v-if="shipment?.driverId?.name" class="w-full text-left whitespace-normal truncate px-2 py-1">{{shipment?.driverId?.name}}</div>
                    <div class="w-full flex p-1 max-w-md">
                        <searchApi :apiEndpoint="'search/vehicleAssignee'" :placeholder="`${shipment?.driverId?._id ? 'change' : 'assign'} driver`" :displayResult="false" :disabled="isWorking || shipment.shipmentState > 2 || !warehouse" @resultSelected="updateShipmentSchedule('driverId',$event?._id)" />
                    </div>
                </div>
            </div>
    <!-- Address -->
            <div v-if="shipment.shipmentAddress && Object.keys(shipment.shipmentAddress).length > 0" class="w-full flex flex-col p-1">
                <div class="w-full text-left whitespace-normal truncate p-1 border-b">Address</div>
                <div class="p-1 flex flex-col">
                    <DisplayAddress :address="shipment.shipmentAddress" :showAddressTwo="true" />
                </div>
            </div>
            <div v-if="shipment.method !== 'tool'" class="w-full flex flex-col p-1">
                <div class="flex flex-col max-w-lg">
                    <addressLookup ref="addressComponent" :placeholder_text="'change address'" :zIndex_class="'z-90'" :disabled="isWorking || shipment.shipmentState > 2"  @addressSelected="setNewAddress($event)" />
                </div>
                <div v-if="newAddress && Object.keys(newAddress).length > 0" class="w-full flex items-center">
                    <button class="bn-solid-green px-2 py-1" :class="{'animate-bounce':!isWorking}" @click="updateShipmentSchedule('shipmentAddress',newAddress)">
                        <div>Save New Address</div>
                    </button>
                </div>
            </div>
            <div class="w-full flex flex-col justify-center p-1">
                <div class="w-full text-left whitespace-normal truncate p-1 border-b">Warehouse Note</div>
                <div class="w-full max-w-lg py-2">
                    <textarea v-model.trim="shipment.warehouseNote" v-auto-resize class="w-full max-w-lg resize-none" cols="1" rows="3" maxlength="500" placeholder="warehouse note" 
                    @focus="prevValue = shipment.warehouseNote" 
                    @blur="shipment.warehouseNote !== prevValue ? updateShipmentSchedule('warehouseNote',shipment.warehouseNote) : null"
                    :disabled ="isWorking || !warehouse"
                    />
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import searchApi from '@/components/ui/searchApi.vue'
import addressLookup from '@/components/ui/addressLookup.vue'
import DisplayAddress from '@/components/ui/DisplayAddress.vue'
import { inject,onMounted,onUnmounted,ref } from 'vue'
import { parseISO,format,isValid } from 'date-fns'
import {capitalizeFirstLetter,formatISODateforDateTimeInput} from '@/shared'
import ReturnIcon from '@/components/customIcons/ReturnIcon.vue'
import autoResize from '@/components/ui/autoResize'
import api from '@/api'

export default {
    directives:{
        autoResize
    },
    props:{
        shipmentId:{type:String,default:null}
    },
    emits:["updateShipment"],
    components:{searchApi,addressLookup,DisplayAddress,ReturnIcon},
    setup (props,{emit}) {
        const isWorking = ref(false)
        const responseError = ref(null)
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent,authenticated} = global
        const warehouse = ref(authenticated.value?.role?.accessModel?.warehouseQueue > 0)
        const shipment = ref({})

        const newShipmentDate = ref(null)
        const newAddress = ref({})
        const addressComponent = ref(null)


        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getShipment()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const setNewAddress = (event)=>{
            newAddress.value = event
        }

        const getShipment = async (type)=>{
            responseError.value = null
            if(type !== 'silent') {
                isWorking.value = true
                setModalBlocked(true)
            }
            
            await api
            .get(`shipments/${props.shipmentId}`)
            .then((res)=>{
                res.data?.data && typeof res.data.data === 'object'
                ? shipment.value = processDates(res.data.data)
                : null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const updateShipmentSchedule = async (key,value)=>{
            responseError.value = null
            isWorking.value = true
            setModalBlocked(true)
            if(key === 'shipmentDate') {
                value = isValid(new Date(value)) ? new Date(value).toISOString() : null
            }
            let body = {
                [key]:value
            }
            await api
            .put(`shipments/schedule/${props.shipmentId}`,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("updateShipment",res.data.data)
                    sendChangeEvent('shipment',res.data?.data?.projectId?._id,'',{
                        projectId:res.data?.data?.projectId?._id,
                        shipmentId:res.data?.data?._id,
                        shipmentState:res?.data?.data?.shipmentState,
                        shipmentDate:res?.data?.data?.shipmentDate,
                        driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                    })
                    shipment.value = processDates(res.data.data)
                }
                if(addressComponent.value) {addressComponent.value.clearAddress()}
            })
            .catch((err)=>{
                body.shipmentDate ? newShipmentDate.value = null : null
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const moveToNotShipped = async ()=>{
            responseError.value = null
            isWorking.value = true
            setModalBlocked(true)
            await api
            .put(`shipments/state/notShipped/${props.shipmentId}`,{})
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("updateShipment",res.data.data)
                    sendChangeEvent('shipment',res.data?.data?.projectId?._id,'',{
                        projectId:res.data?.data?.projectId?._id,
                        shipmentId:res.data?.data?._id,
                        shipmentState:res?.data?.data?.shipmentState,
                        shipmentDate:res?.data?.data?.shipmentDate,
                        driverId:res?.data?.data?.driverId?._id || res?.data?.data?.driverId
                    })
                    shipment.value = processDates(res.data.data)
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const processDates = (data)=>{
            return {...data,shipmentDate:formatISODateforDateTimeInput(data.shipmentDate)|| null}
        }

        const handleNumberInput = ()=>{
            if(!shipment.value.duration) {
                shipment.value.duration = 1
                return;
            } else {
                let value = parseFloat(shipment.value.duration);
                value = Math.max(1, Math.min(value, 15)); // Clamp the value between 1 and 10
                // Round to nearest 0.25
                value = Math.round(value / 0.25) * 0.25;
                shipment.value.duration = value; 
            }
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type && e.detail.type === 'shipment') {
                if(e.detail?.data?.shipmentId) {
                    if(e.detail?.data?.shipmentId === shipment.value?._id) {
                        getShipment('silent')
                    }
                } 
            }
        }


        return {
            isWorking,
            responseError,
            format,
            parseISO,
            isValid,
            shipment,
            newShipmentDate,
            setNewAddress,
            capitalizeFirstLetter,
            updateShipmentSchedule,
            newAddress,
            addressComponent,
            handleNumberInput,
            warehouse,
            moveToNotShipped
        }
    }
}
</script>

<style>

</style>