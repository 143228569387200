<template>
  <div class="w-full flex flex-col flex-grow items-center p-1">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div class="w-full flex flex-col flex-grow max-w-4xl p-1 space-y-1">
        <div v-if="noHistory" class="w-full text-center p-1 whitespace-normal text-sm font-semibold">
            No logs returned
        </div>
        <div v-else-if="Array.isArray(history) && history.length > 0" class="w-full text-left p-1 border-b whitespace-normal text-sky-700 dark:text-sky-600">
            {{`${history.length} items`}}
        </div>
        <div v-if="Array.isArray(history) && history.length > 0" class="w-full flex flex-col flex-auto h-0 p-2 overflow-y-auto space-y-1">
            <div v-for="item in history" :key="item._id" class="w-full p-1" >
                <div class="w-full p-1 rounded-md border">
                    <div class="w-full flex flex-row items-center flex-wrap truncate">
                        <div class="flex-grow p-1">
                            <div v-if="item.createdAt && isValid(parseISO(item.createdAt))" class="w-full whitespace-normal text-left line-clamp-2 truncate">
                                {{format(parseISO(item.createdAt),'EEE MMM d,yyyy h:mm aaa')}}
                            </div>
                        </div>
                        <div class="w-auto text-left whitespace-normal line-clamp-2 p-1">
                            {{item.userName}}
                        </div>
                    </div>
                    <div v-if="item.log" class="w-full text-left p-1 border-t whitespace-normal truncate">
                        {{item.log}}
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref } from 'vue'
import {parseISO,isValid,compareDesc,format} from 'date-fns'
import api from '@/api'
export default {
    props:{
        shipment:{
            type:Object,
            default:()=>{return {}}
        }
    },
    components:{},
    setup (props) {
        const global = inject('global')
        const {setModalBlocked} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const history = ref([])
        const noHistory = ref(false)

        onMounted(()=>{
            getToolHistory()
        })

        const historySorted = computed(() => {
            const sortedHistory = history.value.slice().sort((a, b) => {
                const dateA = parseISO(a.createdAt);
                const dateB = parseISO(b.createdAt);

                // Check if dates are valid
                if (!isValid(dateA) && !isValid(dateB)) {
                    return 0; // Both dates are invalid, consider them equal
                } else if (!isValid(dateA)) {
                    return 1; // Only dateA is invalid, consider it smaller
                } else if (!isValid(dateB)) {
                    return -1; // Only dateB is invalid, consider it smaller
                }

                // Compare dates in descending order using date-fns
                return compareDesc(dateA, dateB);
            });

            return sortedHistory;
        });

        const getToolHistory= async () =>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            noHistory.value = false
            await api
            .get(`shipments/shipmentHistory/${props.shipment?._id}`)
            .then(res=>{
                if(Array.isArray(res.data.data)) {
                    if(res.data.data.length === 0) {noHistory.value = true}
                    history.value = res.data.data
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        return {
            isWorking,
            responseError,
            history,
            historySorted,
            noHistory,
            parseISO,
            isValid,
            format
        }
    }
}
</script>

<style>

</style>