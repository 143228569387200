// Autocomplete for for street addresses to API endpoint /addresses/autocomplete
// Takes in props:
// resultsId: {type:String}
// placeholder_text: { type: String }
// zIndex_class: { type: String } : used so lower drop down doesnt display over drop down above it when near each other.
// emits: ("addressSelected", this.selectedAddress)

<template>
  <div class="w-full">
    <!-- FORM AUTOCOMPLETE: Autocomplete Form -->
    <form @submit.prevent class="w-full flex flex-col items-center" v-if="!showAddressForm">
      <!-- User address search input -->
      <input
        id="userInput"
        ref="userInput"
        type="text"
        v-model="userInput"
        class="w-full"
        :placeholder="placeholder_text || 'search address'"
        autocomplete="off"
        :disabled="disabled"
        @keydown.down="scrollList($event)"
        @keydown.up="scrollList($event)"
        @keydown.enter="selectAddress($event)"
        @paste.prevent="convertPastedText"
      />
      <div class="w-full relative p-1">
        <div class="w-full p-1 absolute top-0 left-0 z-40">
          <div v-if="noResultsReturned" class="text-red-700 text-sm panel rounded-t-none border-t-none">No Matching Results</div>
          <!-- dropdown with addresses provided -->
          <div v-if="(userInput != '' && addressResults && addressResults.length > 0) || isRunningApi" class="w-full max-h-96 panel border-t-0 rounded-t-none overflow-y-auto"
            :class="zIndex_class"
            :style="resultsDivOffsetWidth"
          >
            <div><LoadingData v-if="isRunningApi" :class="isRunningApi ? 'p-1' : 'p-0'" /></div>
            <!-- list of autocomplete addresses -->
            <ul :id="resultsId" @mouseenter="resetKeyboardSelection" ref="results" class="w-full flex flex-col justify-start">
              <li v-for="address in addressResults" :key="address.place_id"
                class="flex justify-start w-full truncate hover:bg-sky-600 font-bold p-1 m-1"
                @click="selectAddress($event)"
                :id="address.place_id"
              >
              <LocationMarkerIcon class="h-5 w-5 p-0 flex-shrink-0 pointer-events-none" />
                <div class="w-full whitespace-normal truncate pointer-events-none">{{ address.description }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
<!-- FORM ADDRESS SELECTED: Form displayed once the address has been chosen, display street, city, state, postal  -->
    <form v-if="showAddressForm" @submit.prevent class="w-full flex flex-col space-y-1 py-2 relative" @mouseover="showClearAddress = true" @mouseleave="showClearAddress = false">
      <div class="flex flex-wrap space-y-1">
        <div class="flex flex-row space-x-1 items-center w-full">
          <input type="text" :value="`${selectedAddress.street_number} ${selectedAddress.street}`" class="w-full flex-grow" readonly/>
          <button class="bn-icon-only"  @click="clearAddress">
            <TrashIcon />
          </button>
        </div>
        <input v-model="addressTwo" type="text" placeholder="Address 2 (optional)" class="w-full" @focus="inAddressTwo = true" @blur="inAddressTwo = false" @keyup="sendAddressTwo"/>
      </div>
      <div class="flex flex-wrap md:space-x-1">
        <input type="text" :value="selectedAddress.city" class=" mb-1 w-full md:w-1/3 flex-grow" readonly/>
        <input type="text" :value="selectedAddress.state" class=" mb-1 w-full md:w-1/4" readonly/>
        <input type="text" :value="selectedAddress.postalCode" class=" mb-1 w-full md:w-1/4" readonly/>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/api"

import {LocationMarkerIcon} from "@heroicons/vue/solid"
import {TrashIcon} from "@heroicons/vue/outline"

export default {
  props: {
    resultsId: { type: String,default:'main' },
    placeholder_text: { type: String,default:'address' },
    zIndex_class: { type: String,default:'z-50' },
    disabled:{type:Boolean,default:false}
  },
  emits: ["addressSelected"],
  components: {
    LocationMarkerIcon,
    TrashIcon
  },
  mounted() {
    // Resizes the initial value of the results div below to match the size of input.
    this.resultsDivOffsetWidth = `width: ${this.$refs.userInput.offsetWidth}px`;
    window.addEventListener("resize", this.resizeResultsDiv);
  },

  unmounted() {
    window.removeEventListener("resize", this.resizeResultsDiv);
  },

  data: function () {
    return {
      inProgress: false,
      isRunningApi: false,
      userInput: "",
      currentLI: 0,
      resultsDivOffsetWidth: 0,
      selectedAddress: {},
      showAddressForm: false,
      addressResults: [],
      noResultsReturned: false,
      showClearAddress: false,
      addressTwo: "",
      inAddressTwo: false,
    };
  },

  watch: {
    // delays API until the input greater than 5 characters.
    userInput: function (val) {
      if (val.length > 5) {
        this.apiThrottle();
      } else {
        this.addressResults = [];
        this.noResultsReturned = false;
      }
    },
  },

  methods: {
    // resizes the results div to match the input div on resize
    resizeResultsDiv: function () {
      let resultsWidth = this.$refs.userInput?.offsetWidth;
      if (resultsWidth) {
        this.resultsDivOffsetWidth = `width: ${resultsWidth}px`;
      }
    },
    // Delays API call while user types for 1s and prevents rerunning during SetTimeout
    apiThrottle: function () {
      if (!this.inProgress) {
        this.inProgress = true;
        setTimeout(() => {
          this.getResults();
          this.inProgress = false;
        }, 1000);
      }
    },
    // GET: Removes highlight from previous list and gets result of autocomplete by running addressLookup
    getResults: async function () {
      this.isRunningApi = true;
      if (this.addressResults.length > 0) {
          let liElements = this.$refs?.results?.getElementsByTagName("li");
          if(liElements) {
            for (let i = 0; i < liElements.length; i++) {
              const li = liElements[i];
              li.classList.remove("bg-sky-600");
            }
          }

      }
      this.currentLI = 0;

      await this.addressLookup();
      this.isRunningApi = false;
    },

    // GET: Gets results of autocomplete and filters out non number specific addresses
    addressLookup: async function () {
      if (this.userInput.length > 0) {
        this.noResultsReturned = false;
        await api
          .get("addresses/autocomplete/" + this.userInput)
          .then((res) => {
            let filteredPredictions = res.data.data.predictions.filter(
              (value) => ["premise", "subpremise", "street_address"].some(type => value.types.includes(type))
            );
            filteredPredictions.length
              ? (this.addressResults = filteredPredictions)
              : (this.noResultsReturned = true);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    // GET: When address selected from drowndown, sends google place id to API endpoint /addresses/addresdetails to retreive individual elements
    // Sets selectedAddress elements to response and emits
    addressDetailsLookup: async function (placeId) {
      await api
        .get("addresses/addressdetails/" + placeId)
        .then((res) => {
          const googlePlacesData = res.data.data.result;
          this.selectedAddress.googlePlacesId = placeId;
          const addressComponents = googlePlacesData.address_components;
          for (let i = 0; i < addressComponents.length; i++) {
            const n = addressComponents[i];
            if (n.types.includes("street_number")) {
              this.selectedAddress.street_number = n.long_name;
            }
            if (n.types.includes("route")) {
              this.selectedAddress.street = n.long_name;
            }
            if (n.types.includes("locality") || n.types.includes("sublocality")) {
              this.selectedAddress.city = n.short_name;
            }
            if (n.types.includes("administrative_area_level_1")) {
              this.selectedAddress.state = n.long_name;
            }
            if (n.types.includes("postal_code")) {
              this.selectedAddress.postalCode = n.long_name;
            }
          }
          this.selectedAddress.location = {
            type: "Point",
            coordinates: [
              googlePlacesData.geometry.location.lng,
              googlePlacesData.geometry.location.lat,
            ],
          };
          this.$emit("addressSelected", this.selectedAddress);
          this.showAddressForm = true
        })
        .catch((err) => {
          console.error(err);
        });
    },
    sendAddressTwo: function () {
      this.selectedAddress.addressTwo = this.addressTwo;
      this.$emit("addressSelected", this.selectedAddress);
    },

    // Allows User to scroll results with up/down arrow keys when input is in focus.
    scrollList: function (event) {
      if (this.addressResults.length > 0 && !this.userInput == " ") {
        let previousLI;
        let listItems = this.$refs.results.getElementsByTagName("li");

        for (var i = 0;i < listItems.length; i++) {
          if(listItems[i].classList.contains("bg-sky-600")) {
            previousLI = listItems[i]
          }
        }

        if (previousLI) {
          if (event.code == "ArrowDown") {
            if (this.currentLI != this.addressResults.length - 1) {
              this.currentLI++;
            }
          } else {
            if (this.currentLI != 0) {
              this.currentLI--;
            }
          }
          previousLI.classList.remove("bg-sky-600");
          listItems[this.currentLI].classList.add("bg-sky-600");
        } else {
          listItems[0].classList.add("bg-sky-600");
        }
      }
    },

    // Gets the selected address, active selection, when the user hits the enter key
    selectAddress: function (event) {
      if (event.code == "Enter") {
        const liElements = this.$refs.results.getElementsByTagName("li");
        for (let i = 0; i < liElements.length; i++) {
          const li = liElements[i];
          if (li.classList.contains("bg-sky-600")) {
            this.setSelectedAddress(li.id);
          }
        }
      } else {
       let element =  document.getElementById(event.target?.id)
       if(element) {
          element.classList.add("bg-sky-600");
       }
          this.setSelectedAddress(event.target?.id);
      }
    },

    setSelectedAddress: function (address) {
      this.addressDetailsLookup(address);
      this.userInput = "";
    },

    // Gets selected address from results when selected by mouse
    resetKeyboardSelection: function () {
      const liElements = this.$refs.results.getElementsByTagName("li");
      for (let i = 0; i < liElements.length; i++) {
        const li = liElements[i];
        if (li.classList.contains("bg-sky-600")) {
          li.classList.remove("bg-sky-600");
        }
      }
    },
    // converts to text when address pasted into input field.
    convertPastedText: function (event) {
      let intendedText =
        event.originalEvent || event.clipboardData.getData("text/plain");
      this.userInput = intendedText.toString();
    },

    // Clears address and resets the form
    clearAddress: function () {
      this.selectedAddress = [];
      this.addressTwo = "";
      this.showAddressForm = false;
      this.$emit("addressSelected", this.selectedAddress);
    },
  },
};
</script>