<template>
  <div class="w-full flex flex-col flex-grow items-center p-1">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
<!-- New Link -->
    <div v-if="model === 'projects' && route !== 'photos' && !invoiceId && allowEdit" class="w-full p-1 flex border-b">
        <button class="px-2 py-0.5 bn-solid-green" @click="toggleNewLinkDialog()" :disabled="isWorking">
            <div>new link</div>
            <PlusIcon :class="{'rotate-45':showNewLinkDialog}" />
        </button>
    </div>
    <div v-if="model === 'projects' && route !== 'photos' && allowEdit && showNewLinkDialog" class="w-full flex flex-col p-1 max-w-5xl border-b">
        <div class="w-full flex flex-col max-w-5xl p-1 panel">
            <div class="w-full text-left truncate p-1 border-b">New Document Link</div>
            <div class="w-full flex flex-row items-center p-1 truncate">
                <div class="flex-grow flex flex-row items-center flex-wrap p-1">
                    <div class="w-full max-w-xs p-1">
                        <input v-model.trim="newDocumentLinkDisplayName" type="text" class="w-full" placeholder="Display Name" maxlength="25" :disabled="isWorking">
                    </div>
                    <div class="flex-grow min-w-72 p-1">
                        <input v-model.trim="newDocumentLinkUrl" type="text" class="w-full" placeholder="link" :disabled="isWorking">
                    </div>
                </div>
                <div class="w-auto p-1">
                    <button class="bn-icon-small bn-solid-green" @click="saveNewLink()" :disabled="isWorking || !canSaveNewLink">
                        <SaveIcon />
                    </button>
                </div>
            </div>
        </div>
    </div>
<!-- Documents Filter -->
    <div v-if="Array.isArray(documents) && documents.length > 0" class="w-full flex flex-row items-center p-1 max-w-5xl truncate border-b">
        <div class="flex flex-row flex-grow items-center truncate flex-wrap">
            <div class="text-left p-1 w-full max-w-sm">
                <input v-model.trim="documentFilter" type="search" :placeholder="`filter ${route || ''} ${route === 'photos' ? ' by file name' :''}`" class="w-full">
            </div>
            <div v-if="!secure && model === 'projects'" class="w-full p-1 max-w-xs">
                <select v-model.trim="locationFilter" class="w-full">
                    <option selected :value="null">Room/Rack</option>
                    <option v-for="(option,index) in locationOptions" :key="index" :value="option.id">{{option.name}}</option>
                </select>
            </div>
        </div>
        <div v-if="route === 'photos'" class="p-1">
            <Popper hover arrow placement="top" :content="'Photo Grid'">
                <button class="bn-icon-only" @click="showGrid = !showGrid" :class="showGrid ? 'text-green-500 focus:text-green-600' : 'opacity-30'"
                >
                    <ViewGridIcon />
                </button>
            </Popper>
        </div>
        <div class="p-1">
            <Popper hover arrow placement="top" :content="'Show Thumbnails'">
                <button class="bn-icon-only" @click="showThumbnails = !showThumbnails" :class="showThumbnails ? 'text-green-500 focus:text-green-600' : 'opacity-30'"
                :disabled="showGrid"
                >
                    <PhotographIcon />
                </button>
            </Popper>
        </div>
        <div class="p-1">
            <Popper hover arrow placement="top" :content="showArchived ? 'UnArchived' : 'Show Archived'">
                <button class="bn-icon-only" @click="showArchived = !showArchived" :class="showArchived ? 'text-green-500 focus:text-green-600' : 'opacity-30'"
                :disabled="isWorking"
                >
                    <ArchiveIcon />
                </button>
            </Popper>
        </div>
    </div>
<!-- Returned Documents -->
    <div v-if="filesLoaded && canShowDocumentsAndLinks" class="w-full flex flex-col space-y-1 flex-auto h-0 flex-grow overflow-y-auto max-w-5xl p-1">
        <div class="w-full flex flex-col flex-grow overflow-y-auto">
        <!-- Project Links -->
            <div v-if="model === 'projects' && route !== 'photos' && (secure ? Array.isArray(entity.financialDocumentLinks) && entity.financialDocumentLinks.length > 0 : Array.isArray(entity.documentLinks) && entity.documentLinks.length > 0)" class="w-full flex flex-col py-2">
                <div v-show="!showArchived" class="w-full sticky top-0 has-details bg-indigo-300 dark:bg-indigo-600 rounded-md" @click="openDetails($event.target)">
                    <div class="w-full flex flex-row items-center">
                        <div class="text-left flex-grow whitespace-normal break-words p-1">
                            Links to External Documents
                        </div>
                        <div class="font-semibold p-1">
                            {{secure ? entity.financialDocumentLinks.length : entity.documentLinks.length}}
                        </div>
                    </div>
                </div>
                <div v-show="!showArchived" class="w-full hidden p-1">
                    <div class="w-full flex flex-col space-y-2">
                        <div v-for="link in secure ? entity.financialDocumentLinks : entity.documentLinks" :key="link._id" class="w-full flex flex-col items-center p-1 border rounded-md">
                            <div class="w-full flex flex-row flex-nowrap items-center space-x-2 truncate">
                                <div class="flex items-center justify-center px-1 flex-shrink-0">
                                    <LinkIcon class="h-4 w-4" />
                                </div>
                                <div class="flex-grow text-left px-1 whitespace-normal break-words truncate overflow-x-clip">{{link.displayName}}</div>
                                <div class="w-auto flex flex-row space-x-2 items-center">
                                    <div v-if="allowEdit">
                                        <button class="bn-icon-only" @click="toggleLinkEdit(link) ">
                                            <XIcon v-if="editLinkId === link._id" />
                                            <PencilAltIcon v-else />
                                        </button>
                                    </div>
                                    <div v-if="allowEdit" class="flex items-center">
                                        <ButtonWithConfirm :iconComponent="TrashIcon" :popperContent="'Delete Link'" @confirmed="deleteLink(link._id)" :disabled="isWorking" />
                                    </div>
                                    <div>
                                        <button class="bn-icon-only" @click="link.url ? openLink(link.url): null">
                                            <ExternalLinkIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="allowEdit && editLinkId === link._id" class="w-full flex flex-col p-1 max-w-5xl">
                                <div class="w-full flex flex-col max-w-5xl p-1 border rounded-md border-sky-600">
                                    <div class="w-full text-left truncate p-1 border-b">Edit Document Link</div>
                                    <div class="w-full flex flex-row items-center p-1 truncate">
                                        <div class="flex-grow flex flex-row items-center flex-wrap p-1">
                                            <div class="w-full max-w-xs p-1">
                                                <input v-model.trim="editLinkDisplayName" type="text" class="w-full" placeholder="Display Name" maxlength="25" :disabled="isWorking">
                                            </div>
                                            <div class="flex-grow min-w-72 p-1">
                                                <input v-model.trim="editLinkUrl" type="text" class="w-full" placeholder="link" :disabled="isWorking">
                                            </div>
                                        </div>
                                        <div class="w-auto p-1">
                                            <button class="bn-icon-small bn-solid-green" @click="updateLink()" :disabled="isWorking || (editLinkDisplayName === link.displayName && editLinkUrl === link.url) || !canEditLink">
                                                <SaveIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- Project Documents -->
            <div class="w-full flex flex-col space-y-2">
                <div v-for="(item,index) in groupedAndSortedDocuments" :key="index" class="w-full flex flex-col">
                    <div class="w-full sticky top-0 has-details panel" @click="openDetails($event.target)">
                        <div class="w-full flex flex-row items-center">
                            <div class="text-left flex-grow whitespace-normal break-words p-1">
                                {{item.docPurpose}}
                            </div>
                            <div v-if="model === 'organizations' && !showArchived && Array.isArray(item.files) && item.files.some(x=>x.expireColor)" class="p-1 text-left whitespace-normal truncate">
                                (expiring documents)
                            </div>
                            <div class="font-semibold p-1">
                                {{item.total}}
                            </div>
                        </div>
                    </div>
                    <div v-if="Array.isArray(item.files) && item.files.length > 0" class="w-full p-1 hidden">
                        <!-- Photo View -->
                        <div v-if="route === 'photos' && showGrid" class="w-full p-1 flex flex-row flex-wrap justify-center md:justify-evenly">
                            <div v-for="file in item.files" :key="file.id" class="flex flex-col items-center justify-center px-1 py-2 w-56 flex-shrink-0 border-y sm:border-y-0">
                                <div class="w-full flex flex-row truncate flex-wrap items-center justify-end">
                                    <div v-if="file.mimeType && canShowFile(file.mimeType)" class="flex items-center p-1">
                                        <Popper hover arrow placement="top" :content="'view'">
                                            <button class="bn-icon-only" @click="viewFile(file.id,file.mimeType,file.name)" :disabled="filesOpening.includes(file.id)">
                                                <RefreshIcon v-if="filesOpening.includes(file.id)" class="animate-spin-slow" />
                                                <EyeIcon v-else />
                                            </button>
                                        </Popper>
                                    </div>
                                    <div class="flex items-center p-1">
                                        <Popper hover arrow placement="top" :content="'download'">
                                            <button class="bn-icon-only" @click="downloadFile(file.id,file.mimeType,file.name)" :disabled="filesDownloading.includes(file.id)">
                                                <RefreshIcon v-if="filesDownloading.includes(file.id)" class="animate-spin-slow" />
                                                <DownloadIcon v-else />
                                            </button>
                                        </Popper>
                                    </div>
                                </div>
                                <div class="flex flex-row flex-grow flex-wrap space-x-2 p-1 truncate">
                                    <div v-if="file.thumbnailLink" class="flex items-center justify-center px-1 py-2 flex-shrink-0 p-2 max-w-56 max-h-56 object-contain">
                                        <img
                                        referrerpolicy="no-referrer"
                                        v-bind:src="file.thumbnailLink"
                                        max-height="240px"
                                        max-width="240px"
                                        contain
                                        class="p-1 sm:p-0"
                                        />
                                    </div>
                                </div>
                                <div v-if="file.properties?.notes" class="w-full whitespace-normal truncate line-clamp-4">
                                    {{file.properties?.notes}}
                                </div>
                            </div>
                        </div>
                        <div v-else class="w-full flex flex-col space-y-1 p-1">
                            <div v-for="file in item.files" :key="file.id" class="w-full flex flex-col items-center p-1 border rounded-md">
                                <div class="w-full flex flex-row flex-wrap space-x-2 p-1 truncate">
                                    <div v-if="file.thumbnailLink && showThumbnails" class="w-full flex items-center justify-center px-1 py-2 flex-shrink-0 sm:h-16 sm:w-16 p-2">
                                        <img
                                        referrerpolicy="no-referrer"
                                        v-bind:src="file.thumbnailLink"
                                        max-height="64px"
                                        max-width="64px"
                                        contain
                                        class="p-1 sm:p-0"
                                        />
                                    </div>
                                    <div v-else-if="file.mimeType" class="flex items-center justify-center px-1 flex-shrink-0">
                                        <PhotographIcon v-if="file.mimeType.includes('image')" class="w-5 h-5" />
                                        <FileTypePdf v-else-if="file.mimeType.includes('pdf')" class="w-5 h-5" />
                                        <DocumentIcon v-else class="w-5 h-5" />
                                    </div>
                                    <div class="flex items-center text-left flex-grow whitespace-normal overflow-hidden break-words truncate font-semibold p-1">
                                        {{file.name}}
                                    </div>
                                    <div class="w-auto flex flex-row space-x-2 items-center p-1">
                                        <div v-if="file.expireColor" class="w-auto p-1" :class="file.expireColor"  >
                                            <CalendarIcon class="h-4 w-4" />
                                        </div>
                                        <div v-if="file.mimeType && canShowFile(file.mimeType)" class="flex items-center">
                                            <Popper hover arrow placement="top" :content="'view'">
                                                <button class="bn-icon-only" @click="viewFile(file.id,file.mimeType,file.name)" :disabled="filesOpening.includes(file.id)">
                                                    <RefreshIcon v-if="filesOpening.includes(file.id)" class="animate-spin-slow" />
                                                    <EyeIcon v-else />
                                                </button>
                                            </Popper>
                                        </div>
                                        <div class="flex items-center">
                                            <Popper hover arrow placement="top" :content="'download'">
                                                <button class="bn-icon-only" @click="downloadFile(file.id,file.mimeType,file.name)" :disabled="filesDownloading.includes(file.id)">
                                                    <RefreshIcon v-if="filesDownloading.includes(file.id)" class="animate-spin-slow" />
                                                    <DownloadIcon v-else />
                                                </button>
                                            </Popper>
                                        </div>
                                        <div class="flex items-center">
                                            <Popper hover arrow placement="top" :content="'details'">
                                                <button class="bn-icon-only" @click="showFileDetailsId === file.id ? showFileDetailsId = null : showFileDetailsId = file.id">
                                                    <XIcon v-if="showFileDetailsId === file.id" />
                                                    <MenuAlt3Icon v-else />
                                                </button>
                                            </Popper>
                                        </div>
                                        <div v-if="allowEdit" class="flex items-center">
                                            <Popper hover arrow placement="top" :content="file.properties?.archived === 'true' ? 'unarchive' : 'archive'">
                                                <button class="bn-icon-only" @click="archiveDocument(file.id,{archived:file.properties?.archived == 'true' ? false : true},file.name)" 
                                                :class="{'text-green-600' : file.properties?.archived === 'true'}"
                                                :disabled="filesArchiving.includes(file.id)"
                                                >
                                                    <RefreshIcon v-if="filesArchiving.includes(file.id)" class="animate-spin-slow" />
                                                    <ArchiveIcon v-else />
                                                </button>
                                            </Popper>
                                        </div>
                                        <div v-if="allowEdit" class="flex items-center">
                                            <ButtonWithConfirm :iconComponent="TrashIcon" :popperContent="'Delete File'" @confirmed="deleteDocument(file.id,file.name)" :disabled="isWorking" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showFileDetailsId === file.id" class="w-full flex flex-col items-center border-t">
                                    <div class="w-full flex flex-row items-center space-x-1 py-1 px-2 flex-wrap truncate">
                                        <div class="flex flex-grow items-center truncate p-1">
                                            <div v-if="allowEdit && route!== 'photos' && file.properties && Array.isArray(documentTypeOptions) && documentTypeOptions.length > 0" class="text-left flex-grow flex items-center">
                                                <select v-if="file.properties?.documentType !== '_Estimate Details'" v-model="file.properties.documentType" class="w-full text-left max-w-72" :disabled="isWorking"
                                                @click="prevValue = file.properties.documentType"
                                                @change="updateDocument(file.id,{documentType:file.properties.documentType},file.name,'documentType',prevValue)"
                                                >
                                                    <option value="">document type</option>
                                                    <option v-for="(option,index) in documentTypeOptions" :key="index" :value="option">{{option}}</option>
                                                </select>
                                            </div>
                                            <div v-else-if="file.properties?.documentType " class="flex items-center text-left flex-grow whitespace-normal overflow-hidden break-words truncate">
                                                {{`type: ${file.properties?.documentType}`}}
                                            </div>
                                        </div>
                                        <div v-if="model === 'organizations' && file.properties?.expiration && isValid(new Date(file.properties.expiration))" class="w-auto p-1">
                                            <div v-if="file.properties?.expiration && isValid(new Date(file.properties.expiration))" class="w-auto flex flex-col" :class="file.expireColor">
                                                <input v-model="file.properties.expiration" type="date" :class="file.expireColor" @click="prevValue === file.properties.expiration " @keydown.prevent="null"
                                                :disabled="isWorking"
                                                @change="updateDocument(file.id,{expiration:file.properties.expiration},file.name,'expiration',prevValue)"
                                                >
                                                <div class="text-left opacity-70 px-1">expires</div>
                                            </div>
                                        </div>
                                        <div v-else-if="route === 'photos'">
                                            <div class="w-auto flex flex-col">
                                                <input v-model="file.properties.photoDate" type="date" @click="prevValue === file.properties.photoDate " @keydown.prevent="null"
                                                :disabled="isWorking || !allowEdit"
                                                @change="updateDocument(file.id,{photoDate:dateInputToISO(file.properties.photoDate)},file.name,'photoDate',prevValue)"
                                                >
                                                <div class="text-left opacity-70 px-1">report date</div>
                                            </div>
                                        </div>
                                        <div v-else-if="route !== 'photos' && model !== 'organizations' && file.createdTime && isValid(new Date(file.createdTime))" class="w-auto p-1">
                                            {{formatCreatedTime(file.createdTime)}}
                                        </div>
                                    </div>
                                    <div v-if="file.properties" class="w-full flex flex-row items-center space-x-1 py-1 border-t truncate px-2">
                                        <div v-if="allowEdit && Array.isArray(locationOptions) && locationOptions.length > 0" class="w-full flex px-1">
                                            <select v-model="file.properties.location" class="w-full text-left p-1 max-w-72" :disabled="isWorking"
                                            @click="prevValue = file.properties.location"
                                            @change="updateDocument(file.id,{location:file.properties.location},file.name,'location',prevValue)"
                                            >
                                                <option value="">Room/Rack</option>
                                                <option v-for="(option,index) in locationOptions" :key="index" :value="option.id">{{option.name}}</option>
                                            </select>
                                        </div>
                                        <div v-else class="px-1 text-left whitespace-normal truncate">{{getLocationName(file.properties?.location)}}</div>
                                    </div>
                                    <div v-if="route === 'photos'" class="w-full flex-col p-1 border-t px-1">
                                        <div class="w-full text-left whitespace-normal truncate font-semibold p-1">Notes:</div>
                                        <textarea v-model.trim="file.properties.notes" v-auto-resize placeholder="photo notes" class="w-full resize-none min-h-12" rows="2" maxlength="100" 
                                        :disabled="isWorking || !allowEdit"
                                        @focus="prevValue = file.properties?.notes"
                                        @blur="prevValue != file.properties?.notes ? updateDocument(file.id,{notes:file.properties.notes},file.name,'notes',prevValue) : null"
                                        />
                                    </div>
                                    <div v-else-if="route !== 'photos' && file.description" class="w-full flex-col p-1 border-t px-1">
                                        <div class="w-full text-left whitespace-normal truncate font-semibold">Description:</div>
                                        <div class="w-full text-left p-1 whitespace-normal overflow-clip break-words truncate">
                                            {{file.description}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="filesLoaded && Array.isArray(documents) && documents.length === 0" class="text-center whitespace-normal p-2">
        <div>No Files Returned</div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { onMounted, ref, inject,computed, watch, onUnmounted } from 'vue'
import {openDetails,formatISODateforInput,dateInputToISO} from '@/shared'
import {EyeIcon,DownloadIcon,MenuAlt3Icon,PhotographIcon,XIcon,RefreshIcon,ExternalLinkIcon,SaveIcon,PencilAltIcon,DocumentIcon,ArchiveIcon} from '@heroicons/vue/outline'
import {TrashIcon,LinkIcon,PlusIcon,CalendarIcon,ViewGridIcon} from '@heroicons/vue/solid'
import FileTypePdf from '../customIcons/FileTypePdf.vue'
import {isValid,format, parseISO, differenceInDays, isBefore} from 'date-fns'
import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'
import autoResize from '@/components/ui/autoResize'

export default {
    directives:{
        autoResize
    },
    props:{
        model:{type:String,default:'projects'},
        route:{type:String,default:'documents'},
        entity:{type:Object,default:()=>{return {}}},
        //project:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false},
        secure:{type:Boolean,default:false},
        //getRoute:{type:String,default:''},
        locationOptions:{type:Array,default:()=>{return []}},
        documentTypeOptions:{type:Array,default:()=>{return []}},
        fileUploaded:{type:Object,default:()=>{return {}}},
        invoiceId:{type:String,default:null},
        external:{type:Boolean,default:false},
        secureCode:{type:String,default:null}
    },
    emits:["triggerProjectUpdate"],
    components:{EyeIcon,DownloadIcon,MenuAlt3Icon,PhotographIcon,XIcon,ButtonWithConfirm,ArchiveIcon,FileTypePdf,DocumentIcon,RefreshIcon,LinkIcon,ExternalLinkIcon,PlusIcon,SaveIcon,PencilAltIcon,CalendarIcon,ViewGridIcon},
    setup (props,{emit}) {
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent,authenticated} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const documents = ref([])
        const filesLoaded = ref(false)
        const showFileDetailsId = ref(null)
        const filesDownloading = ref([])
        const filesOpening = ref([])
        const filesArchiving =ref([])
        const documentFilter = ref(null)
        const locationFilter = ref(null)
        const showArchived = ref(false)
        const showNewLinkDialog = ref(false)
        const newDocumentLinkDisplayName = ref(null)
        const newDocumentLinkUrl=ref(null)
        const editLinkId = ref(null)
        const editLinkDisplayName = ref(null)
        const editLinkUrl = ref(null)
        const showThumbnails = ref(false)
        const showGrid = ref(props.external || (authenticated.value.organization?.orgType && authenticated.value.organization?.orgType.includes('integrator')) ? true : false )

        const supportedFileTypes = [
            'application/pdf',
            'image/jpeg',
            'image/png',
            'image/gif',
            'image/webp',
            'image/bmp',
            'image/svg+xml'
        ]

        onMounted(()=>{
            getDocuments()
            props.route === 'photos' ? showThumbnails.value = true : null
            window.addEventListener("data_change_from_socket",handleSocketChange)
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        watch(() => props.fileUploaded, (newFile) => {
            if (typeof newFile === 'object' && Object.keys(newFile).length > 0) {
                let index = documents.value.findIndex(x => x.id === newFile.id);
                if (index === -1) {
                    documents.value.push(newFile);
                }
            }
        }, { immediate: true });

        const canShowDocumentsAndLinks = computed(() => {
                if(props.route === 'photos' || props.model === 'organizations') {
                    return (Array.isArray(documents.value) && documents.value.length > 0)
                } else if(props.model === 'projects') {
                    return props.secure 
                    ? (Array.isArray(documents.value) && documents.value.length > 0) || (Array.isArray(props.entity.financialDocumentLinks) && props.entity.financialDocumentLinks.length > 0)
                    : (Array.isArray(documents.value) && documents.value.length > 0) || (Array.isArray(props.entity.documentLinks) && props.entity.documentLinks.length > 0)
                } else {
                    return (Array.isArray(props.entity.documentLinks) && props.entity.documentLinks.length > 0)
                }
        });

        const groupedAndSortedDocuments = computed(()=>{
            props.locationOptions
            let files = [...documents.value];

            files = files.filter(file=> showArchived.value ? file.properties.archived  === 'true'  : (!file.properties.archived || file.properties.archived === 'false'))

            if(documentFilter.value) {
                files = files.filter(file=>
                    (file.name && file.name.toLowerCase().includes(documentFilter.value.toLowerCase()) )||
                    (file.properties.documentType && file.properties.documentType.toLowerCase().includes(documentFilter.value.toLowerCase()))
                )
            }

            if(locationFilter.value) {
                files = files.filter(file=> file.properties?.location && file.properties.location === locationFilter.value)
            }

            if(props.route !== 'photos') {
                if(props.model === 'organizations') {
                    files = files.map(x => ({
                    ...x,
                    expireColor: isValid(new Date(x.properties?.expiration)) ? getExpiresIconColor(x.properties?.expiration) : null
                    }));
                }

                files.sort((a, b) => {
                    const docTypeA = a.properties?.documentType || '';
                    const docTypeB = b.properties?.documentType || '';
                    
                    if (docTypeA === '' && docTypeB !== '') return -1;
                    if (docTypeA !== '' && docTypeB === '') return 1;
   
                    const isSpecialA = docTypeA.startsWith('_');
                    const isSpecialB = docTypeB.startsWith('_');
                        
                    if (isSpecialA && !isSpecialB) return -1; // '_...' comes first
                    if (!isSpecialA && isSpecialB) return 1;

                    if (docTypeA < docTypeB) return -1;
                    if (docTypeA > docTypeB) return 1;

                    return new Date(a.createdTime) - new Date(b.createdTime);
                });

                // Group by documentType
                const groups = {};
                files.forEach(file => {
                    const docPurpose = file.properties?.documentType || 'Not Categorized';
                    if (!groups[docPurpose]) {
                    groups[docPurpose] = { docPurpose, files: [], total: 0 };
                    }
                    groups[docPurpose].files.push(file);
                    groups[docPurpose].total += 1;
                });

                return Object.values(groups);
            } else {
                files = files.map(x => ({
                    ...x,
                    docPurpose: x.properties.location ? getLocationName(x.properties.location) : 'No Location',
                    properties:{
                        ...x.properties,
                        photoDate: formatISODateforInput(x.properties?.photoDate)
                    }
                }));

                const compareStringsAsNumbers = (strA, strB) => {
                    const partsA = strA ? strA.match(/(\D+|\d+)/g) : [];
                    const partsB = strB ? strB.match(/(\D+|\d+)/g) : [];
                    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
                        if (partsA[i] !== partsB[i]) {
                            const numA = parseInt(partsA[i], 10);
                            const numB = parseInt(partsB[i], 10);
                            if (!isNaN(numA) && !isNaN(numB)) {
                                return numA - numB;
                            }
                            return partsA[i].localeCompare(partsB[i]);
                        }
                    }
                    return partsA.length - partsB.length;
                };

                files.sort((a, b) => {
                    const docPurposeA =  (a.docPurpose || '').trim();
                    const docPurposeB = (b.docPurpose || '').trim();

                    // Handle 'No Location' first
                    if (docPurposeA === 'No Location' && docPurposeB !== 'No Location') return -1;
                    if (docPurposeA !== 'No Location' && docPurposeB === 'No Location') return 1;

                    // Handle dashes at the bottom
                    const isDashA = docPurposeA.startsWith('-');
                    const isDashB = docPurposeB.startsWith('-');

                    if (isDashA && !isDashB) return 1;
                    if (!isDashA && isDashB) return -1;

                    return compareStringsAsNumbers(docPurposeA, docPurposeB) || new Date(a.createdTime) - new Date(b.createdTime);
                });

                const groups = {};
                files.forEach(file => {
                    const docPurpose = file.docPurpose || 'Not Categorized';
                    if (!groups[docPurpose]) {
                        groups[docPurpose] = { docPurpose, files: [], total: 0 };
                    }
                    groups[docPurpose].files.push(file);
                    groups[docPurpose].total += 1;
                });

                return Object.values(groups);
            }
        })

        const getExpiresIconColor = (expiration)=>{
            const today = new Date();
            const expirationDate = parseISO(expiration);
            const daysToExpire = differenceInDays(expirationDate, today);

            if (isBefore(expirationDate, today)) {
                return 'text-red-500'; // Expired
            } else if (daysToExpire <= 30) {
                return 'text-red-500'; // Within 30 days
            } else if (daysToExpire <= 90) {
                return 'text-yellow-500'; // Within 30 to 90 days
            } else {
                return null; // No icon needed
            }
        }

        const canSaveNewLink = computed(()=>{
            let testRegexURL = /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/(?!\/)[\w-.?%&=/]*)?$/
            return testRegexURL.test(newDocumentLinkUrl.value) && newDocumentLinkDisplayName.value && newDocumentLinkDisplayName.value.length > 3
        })

        const canEditLink = computed(()=>{
            let testRegexURL = /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/(?!\/)[\w-.?%&=/]*)?$/
            return testRegexURL.test(editLinkUrl.value) && editLinkDisplayName.value && editLinkDisplayName.value.length > 3
        })

        const getDocuments = async (type)=>{
            startAPI(type)
            await api
            .get(`${props.external ? 'external/' : ''}${props.model}/${props.secure ? 'secure/':''}${props.route}/${props.entity._id}${props.external && props.secureCode ? '/'+props.secureCode :''}`)
            .then((res)=>{
                if(Array.isArray(res.data.data)) {
                    !filesLoaded.value ? filesLoaded.value = true : null
                    documents.value = res.data.data
                }
            })
            .catch((err)=>{
                err.response?.data?.error?.includes("DOCTYPE") 
                ? responseError.value = 'Could not retrieve documents from Google.'
                : responseError.value =  err.response?.data?.error || err.message  || "Error Getting Documents."
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const viewFile = async (id,mimeType)=>{
            responseError.value = null
            filesOpening.value.push(id)
            await api
            .get(`${props.external ? 'external/' : ''}${props.model}/${props.secure ? 'secure/':''}${props.route}/download/${props.entity._id}/${props.external && props.secureCode ? props.secureCode + '/' : ''}${id}`,{responseType:'blob'})
            .then((response)=>{
                let blob = new Blob([response.data],{type:mimeType})
                let blobUrl = URL.createObjectURL(blob);

                // Open the file in a new tab
                window.open(blobUrl, '_blank');

                // Clean up
                setTimeout(() => {
                    URL.revokeObjectURL(blobUrl);
                }, 100);
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                let index = filesOpening.value.findIndex(x=>x===id)
                if(index > -1) {
                    filesOpening.value.splice(index,1)
                }
            })
        }

        const downloadFile = async (id,mimeType,name)=>{
            responseError.value = null
            filesDownloading.value.push(id)
            await api
            .get(`${props.external ? 'external/' : ''}${props.model}/${props.secure ? 'secure/':''}${props.route}/download/${props.entity._id}/${props.external && props.secureCode ? props.secureCode + '/' : ''}${id}`,{responseType:'blob'})
            .then((response)=>{
                let blob = new Blob([response.data],{type:mimeType})
                let link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = name

                document.body.appendChild(link)
                link.click()
                setTimeout(() => {
                    URL.revokeObjectURL(link.href);
                    link.parentNode.removeChild(link);
                }, 0);
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                let index = filesDownloading.value.findIndex(x=>x===id)
                if(index > -1) {
                    filesDownloading.value.splice(index,1)
                }
            })
        }

        const archiveDocument = async (id,requestBody,name) =>{
            startAPI()
            filesArchiving.value.push(id)
            let body = {"properties":requestBody}
            await api
            .put(`${props.model}/${props.secure ? 'secure/':''}${props.route}/update/${props.entity?._id}/${id}`,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    let index = documents.value.findIndex(x=>x.id === res.data?.data?.id)
                    if(index > -1) {
                        documents.value[index] = res.data.data
                    }
                    let changeEventKey
                    switch (props.model) {
                        case 'projects':
                            changeEventKey = 'projectId'
                            break;
                        case 'organizations':
                            changeEventKey = 'organizationId'
                            break;
                        default:
                            changeEventKey = 'projectId'
                            break;
                    }

                    let changeEventPayload = {
                        [changeEventKey]:props.entity?._id,
                        secure:props.secure,
                        file:res?.data?.data
                    }
                    sendChangeEvent('document_update','','',changeEventPayload)
                }
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
                responseError.value = `Could Not Archive ${name}`
            })
            .finally(()=>{
                stopAPI()
                let index = filesArchiving.value.findIndex(x=>x===id)
                if(index > -1) {
                    filesArchiving.value.splice(index,1)
                }
            })
        }

        const updateDocument = async (id,requestBody,name,key,prevValue)=>{
            startAPI()
            let body = {"properties":requestBody}
            await api
            .put(`${props.model}/${props.secure ? 'secure/':''}${props.route}/update/${props.entity._id}/${id}`,body)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    let index = documents.value.findIndex(x=>x.id === res.data?.data?.id)
                    if(index > -1) {
                        documents.value[index] = res.data.data
                    }
                    let changeEventKey
                    switch (props.model) {
                        case 'projects':
                            changeEventKey = 'projectId'
                            break;
                        case 'organizations':
                            changeEventKey = 'organizationId'
                            break;
                        default:
                            changeEventKey = 'projectId'
                            break;
                    }
                    let changeEventPayload = {
                        [changeEventKey]:props.entity?._id,
                        file:res?.data?.data
                    }
                    console.log(changeEventPayload)
                    sendChangeEvent('document_update','','',changeEventPayload)
                }
            })
            .catch((err)=>{
                let index = documents.value.findIndex(x=>x.id === id)
                if(index > -1) {
                    if(documents.value[index].properties[key]) {
                        documents.value[index].properties[key] = prevValue
                    }
                }
                console.error(err.response?.data?.error || err.message)
                responseError.value = `Could Not Update ${name}`
            })
            .finally(()=>{
                stopAPI()
                isWorking.value = false
            })
        }

        const deleteDocument = async (id,name)=>{
            startAPI()
            await api
            .delete(`${props.model}/${props.secure ? 'secure/':''}${props.route}/${props.entity._id}/${id}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    let index = documents.value.findIndex(x=>x.id === res.data.data?.id)
                    if(index > -1) {
                        documents.value.splice(index,1)
                    }
                    let changeEventKey
                    switch (props.model) {
                        case 'projects':
                            changeEventKey = 'projectId'
                            break;
                        case 'organizations':
                            changeEventKey = 'organizationId'
                            break;
                        default:
                            changeEventKey = 'projectId'
                            break;
                    }
                    let changeEventPayload = {
                        [changeEventKey]:props.entity?._id,
                        secure:props.secure,
                        file:res?.data?.data
                    }
                    sendChangeEvent('document_delete','','',changeEventPayload)
                }
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
                responseError.value = `Could Not delete ${name}`
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const saveNewLink = async ()=>{
            startAPI()
            let body = {
              displayName:newDocumentLinkDisplayName.value,
              url:newDocumentLinkUrl.value
            }
            await api
            .post(`${props.model}/${props.secure ? 'secure/':''}documentLink/${props.entity._id}/`,body)
            .then(()=>{
                toggleNewLinkDialog()
                emit("triggerProjectUpdate")
                sendChangeEvent('project','','',{projectId:props.entity._id})
            })
            .catch((err)=>{
              responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
              stopAPI()
            })
        }

        const deleteLink = async (id)=>{
            startAPI()
            await api
            .delete(`${props.model}/${props.secure ? 'secure/':''}documentLink/${props.entity._id}/${id}`)
            .then(()=>{
                emit("triggerProjectUpdate")
                sendChangeEvent('project','','',{projectId:props.entity._id})
            })
            .catch((err)=>{
              responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
              stopAPI()
            })
        }

        const updateLink = async ()=>{
            startAPI()
            let body = {
                _id:editLinkId.value,
                displayName:editLinkDisplayName.value,
                url:editLinkUrl.value
            }
            await api
            .put(`${props.model}/${props.secure ? 'secure/':''}documentLink/${props.entity._id}/`, body )
            .then(()=>{
                editLinkId.value = null
                editLinkDisplayName.value = null
                editLinkUrl.value = null
                emit("triggerProjectUpdate")
                sendChangeEvent('project','','',{projectId:props.entity._id})
            })
            .catch((err)=>{
              responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
              stopAPI()
            })
        }

        const canShowFile = (mimeType)=>{
            return supportedFileTypes.includes(mimeType)
        }

        const startAPI = (type)=>{
            responseError.value = null
            setModalBlocked(true)
            if(type !== 'silent') { 
                isWorking.value = true
                setModalBlocked(true)
            }
        }

        const stopAPI = ()=>{
            isWorking.value = false
            setModalBlocked(false)
        }

        const formatCreatedTime = (date)=>{
            if(isValid(new Date(date))) {
                return format(new Date(date), 'MMM d, yyyy')
            } else {
                return null
            }
            
        }

        const getLocationName = (id)=>{
            if(id && Array.isArray(props.locationOptions)) {
                let location = props.locationOptions.find(x=>x.id === id)
                if(location) {return location.name}
            }
        }

        const toggleNewLinkDialog = ()=>{
            newDocumentLinkDisplayName.value = null
            newDocumentLinkUrl.value = null
            showNewLinkDialog.value = !showNewLinkDialog.value
        }

        const toggleLinkEdit = (link)=>{
            if(editLinkId.value === link?._id) {
                editLinkId.value = null
                editLinkDisplayName.value = null
                editLinkUrl.value = null
            } else {
                editLinkId.value = link._id
                editLinkDisplayName.value = link.displayName
                editLinkUrl.value = link.url
            }
        }

        const openLink = (url)=>{
            if(url) {
                window.open(url,'_blank')
            }
        }

        const handleSocketChange = (e)=>{
             if(e.detail?.type) {
                console.log(e.detail?.type)
                switch (e.detail.type) {
                    case 'document_update':
                        if((e.detail.data?.projectId === props.entity?._id || e.detail.data?.organizationId === props.entity?._id)  && e.detail?.data?.file?.id) {
                            let index = documents.value.findIndex(x=>x.id === e.detail?.data?.file?.id)
                            if(index > -1) {
                                documents.value[index] = e.detail.data.file
                            }
                        }
                        break;
                    case 'document_delete':
                        if((e.detail.data?.projectId === props.entity?._id || e.detail.data?.organizationId === props.entity?._id)  && e.detail?.data?.file?.id) {
                            let index = documents.value.findIndex(x=>x.id === e.detail?.data?.file?.id)
                            if(index > -1) {
                                documents.value.splice(index,1)
                            }
                        }
                        break;
                    case 'document_upload':
                        if((e.detail.data?.projectId === props.entity?._id || e.detail.data?.organizationId === props.entity?._id)  && e.detail?.data?.file?.id) {
                            if(e.detail?.data?.secure === props.secure) {
                                let index = documents.value.findIndex(x=>x.id === e.detail?.data?.file?.id)
                                if(index === -1) {
                                    documents.value.push(e.detail.data.file)
                                }
                            }
                        }
                        break;
                    default:
                        break;
                }
             }
        }

        return {
            isWorking,
            responseError,
            documents,
            groupedAndSortedDocuments,
            filesLoaded,
            openDetails,
            isValid,
            formatCreatedTime,
            showFileDetailsId,
            TrashIcon,
            canShowFile,
            getLocationName,
            downloadFile,
            viewFile,
            filesDownloading,
            filesOpening,
            filesArchiving,
            documentFilter,
            locationFilter,
            showArchived,
            archiveDocument,
            deleteDocument,
            updateDocument,
            openLink,
            showNewLinkDialog,
            toggleNewLinkDialog,
            newDocumentLinkDisplayName,
            newDocumentLinkUrl,
            canSaveNewLink,
            saveNewLink,
            deleteLink,
            editLinkId,
            editLinkDisplayName,
            editLinkUrl,
            toggleLinkEdit,
            canEditLink,
            updateLink,
            canShowDocumentsAndLinks,
            showThumbnails,
            dateInputToISO,
            showGrid
        }
    }

}
</script>

<style>

</style>