<template>
  <div class="w-full flex flex-row items-center truncate">
    <div class="w-full flex flex-row items-center flex-wrap">
        <div class="w-full max-w-48 p-1">
            <input v-model.trim="contactInfo.name" type="search" class="w-full" maxlength="50" placeholder="name" :disabled="!allowEdit" />
        </div>
            <div class="w-full max-w-48 px-1">
                <input v-model="contactInfo.email" type="email" class="w-full" placeholder="email" :class="{'border border-red-500 text-red-500':contactInfo.email && !isEmailValid(contactInfo.email)}"
                :disabled="!allowEdit" 
                >
            </div>
            <div class="w-auto px-1">
                <PhoneInput :allowDelete="true" @phoneValidated="contactInfo.phone = $event" :allowEdit="allowEdit"/>
            </div>
    </div>
    <div class="p-1">
        <button class="bn-icon-small bn-solid-green" :disabled="!allowEdit || !canAdd" @click="emitContact()">
            <PlusIcon />
        </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import {PlusIcon} from '@heroicons/vue/solid'
import PhoneInput from './PhoneInput.vue'
import {isEmailValid} from '@/shared'

export default {
    props:{allowEdit:{type:Boolean,default:false}},
    components:{PhoneInput,PlusIcon},
    emits:["emitContact"],
    setup (_,{emit}) {
        const initialContactInfo = {
            name:null,
            email:null,
            phone:{}
        }
        const contactInfo = ref({...initialContactInfo})

        const canAdd = computed(()=>{
            return contactInfo.value.name && (isEmailValid(contactInfo.value.email) || contactInfo.value.phone.rawValue)
        })

        const emitContact = ()=>{
            emit("emitContact",contactInfo.value)
            contactInfo.value = initialContactInfo
        }

        return {
            contactInfo,
            canAdd,
            isEmailValid,
            emitContact
        }
    }

}
</script>

<style>

</style>