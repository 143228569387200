export default {
    mounted(el) {
      const handleFocus = (event) => {
        if (parseFloat(event.target.value) === 0) {
          event.target.value = '';
        }
      };
  
      const handleBlur = (event) => {
        let value = event.target.value;
  
        // Set to zero if empty
        if (value === '') {
          event.target.value = '0';
        } else {
          // Parse and round to the nearest 0.25
          value = parseFloat(value);
          if (isNaN(value)) {
            event.target.value = '0';
            return;
          }
  
          // Limit to max if defined
          const max = parseFloat(el.getAttribute('max'));
          if (!isNaN(max) && value > max) {
            value = max;
          }
  
          // Limit to min if defined, or 0 if min is not set
          const min = parseFloat(el.getAttribute('min'));
          const minValue = isNaN(min) ? 0 : min;
          if (value < minValue) {
            value = minValue;
          }
  
          // Round to the nearest 0.25
          value = Math.round(value * 4) / 4;
  
          // Update the value in the input
          event.target.value = value;
        }
  
        // Trigger the input event to update v-model
        el.dispatchEvent(new Event('input', { bubbles: true }));
      };
  
      el.addEventListener('focus', handleFocus);
      el.addEventListener('blur', handleBlur);
  
      // Cleanup function to remove event listeners
      el._cleanup = () => {
        el.removeEventListener('focus', handleFocus);
        el.removeEventListener('blur', handleBlur);
      };
    },
    beforeUnmount(el) {
      el._cleanup();
    }
  };