export default {
  beforeMount(el, binding) {
    // Function to format the number with commas and two decimal places
    const formatNumber = (value) => {
      const numberValue = value.replace(/,/g, ''); // Remove commas

      // If the input is empty or just '-', return it as-is (do not format)
      if (numberValue === '' || numberValue === '-') return numberValue;

      // Allow negative sign and remove non-numeric characters except the negative sign and period
      const cleanedValue = numberValue.replace(/[^0-9.-]/g, '');

      // Ensure it has two decimal places (but no division by 100)
      const parsedNumber = parseFloat(cleanedValue).toFixed(2);

      // Format the number with commas and two decimal places, retaining the negative sign
      return isNaN(parsedNumber)
        ? ''
        : parseFloat(parsedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    // Function to update the raw numeric value without commas in the binding
    const updateBindingValue = (value) => {
      const numberValue = value.replace(/,/g, ''); // Remove commas
      if (numberValue === '' || numberValue === '-' || isNaN(numberValue)) {
        return null; // If the input is empty or invalid, return null
      }
      return parseFloat(numberValue); // Return the raw number
    };

    // Handler for input event
    const onInput = () => {
      const currentValue = el.value;

      // Handle the case where the user types only '-'
      if (currentValue === '-') {
        binding.instance[binding.expression] = null; // Set model to null if input is just '-'
        return;
      }

      const formattedValue = formatNumber(currentValue);

      // Only update the value if it's different from the formatted value
      if (currentValue !== formattedValue && formattedValue !== null) {
        const cursorPos = el.selectionStart;
        el.value = formattedValue;
        el.setSelectionRange(cursorPos, cursorPos); // Keep cursor position consistent
        el.dispatchEvent(new Event('input')); // Manually trigger input event for reactivity
      }

      // Update the binding (v-model) with the raw value (without commas)
      const rawValue = updateBindingValue(currentValue);
      binding.instance[binding.expression] = rawValue;
    };

    // Prevent the user from typing commas, but allow the negative sign and digits
    const onKeyPress = (e) => {
      const invalidKeys = ['.', ','];
      if (invalidKeys.includes(e.key)) {
        e.preventDefault(); // Prevent typing of invalid characters
      }
    };

    el.addEventListener('input', onInput);
    el.addEventListener('keypress', onKeyPress); // Listen for keypress to block decimals and commas
  },
};