<template>
    <div v-if="showConfirm" class="flex items-center justify-center">
        <Popper hover arrow placement="top" :content="'confirm'">
            <button class="px-1 bn-solid"
            :disabled="disabled"
            >
                <CheckIcon class="text-green-500 hover:text-green-900 cursor-pointer" @click="confirm()" />
                <XIcon class="text-red-500 hover:text-red-900 cursor-pointer" @click="showConfirm = false" />
            </button>
        </Popper>
    </div>
    <div v-else class="flex items-center justify-center">
        <Popper hover arrow placement="top" :content="popperContent">
            <button :class="buttonClass" @click="showConfirm = true" :disabled="disabled">
                <component :is="isDelete ? TrashIcon : iconComponent" />
                <div v-if="buttonText">{{buttonText}}</div>
            </button>
        </Popper>
    </div>
</template>

<script>
import {CheckIcon,XIcon,TrashIcon} from "@heroicons/vue/solid"
import { ref } from 'vue'
export default {
    props:{
        buttonClass:{type:String,default:'bn-icon-only'},
        disabled:{type:Boolean,default:false},
        popperContent:{type:String,default:''},
        isDelete:{type:Boolean,default:false},
        iconComponent:{type:[Object, Function],default:()=>CheckIcon},
        buttonText:{type:String,default:null}
    },
    components:{CheckIcon,XIcon},
    emits:["confirmed"],
    setup (_,{emit}) {
        const showConfirm = ref(false)

        const confirm = ()=>{
            emit("confirmed")
            showConfirm.value = false
        }

        return {
            showConfirm,
            confirm,
            TrashIcon
        }
    }

}
</script>