<template>
    <QueueDisplay :total="Number(count)">
        <template v-slot:icon>
            <CurrencyDollarIcon class="h-5 w-5" />
        </template>
    </QueueDisplay>
</template>

<script>
import api from '@/api'
import {CurrencyDollarIcon} from "@heroicons/vue/solid"
import {onMounted, onUnmounted, ref } from 'vue'
import QueueDisplay from "@/components/ui/QueueDisplay.vue"
export default {
    components:{CurrencyDollarIcon,QueueDisplay},
    setup () {

        const count = ref(0)

        onMounted (()=>{
            getInitialCount()
            window.addEventListener('socket_connected', handleReconnect);
            window.addEventListener("invoices_queue", handleWebSocketData);
        })
        
        onUnmounted (()=>{
            window.removeEventListener("socket_connected", handleReconnect);
            window.removeEventListener("invoices_queue",handleWebSocketData)
        })

        const handleWebSocketData = (e)=>{
            const data = Number(e.detail?.data)
            if (isNaN(data)) {
                count.value = 0;
            } else {
                count.value = e.detail?.data
            }
        }

        const joinRoom = ()=>{
            let eventJoin = new CustomEvent("requestRoom", {detail: { route: "invoices", room: "invoices_queue" }});
            dispatchEvent(eventJoin);
        }

        const handleReconnect = ()=>{
            joinRoom()
        }

        const getInitialCount = async ()=>{
            await api
            .get("invoices/openCount")
            .then((res)=>{
                res.data?.data && Number(res.data.data) ? count.value = Number(res.data.data) : null
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        return {count}
    }

}
</script>

<style>

</style>