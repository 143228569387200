<template>
    <div class="w-full flex flex-col items-center p-1 rounded-md border" :class="{'border-green-600' : shipment.shipmentState > 2}">
        <div class="w-full flex flex-row items-center justify-start px-1 flex-wrap">
            <div class="flex-grow flex items-center space-x-2 flex-wrap">
                <div class="text-left whitespace-normal truncate">{{`${shipment.projectId?.companyProjectNumber} - ${shipment.shipmentNumber}`}}</div>
                <div v-if="shipment.duration" class="w-auto px-1">{{`${shipment.duration} hrs`}}</div>
            </div>
            <div class="w-auto flex items-center flex-nowrap space-x-1">
                <div class="w-auto whitespace-normal p-1" :class="shipment.scheduleType === 'firm' ? 'text-red-600' : 'text-green-600'">{{shipment.scheduleType}}</div>
                <div>
                    <button class="bn-icon-only" @click="emitShipmentId()" :disabled="shipment.deliveredDate">
                        <CalendarIcon />
                    </button>
                </div>
                <div>
                    <button class="bn-icon-only" @click="showDetails = !showDetails">
                        <XIcon v-if="showDetails" />
                        <MenuAlt3Icon v-else />
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full flex items-center truncate border-t flex-wrap">
            <div class="text-left whitespace-normal overflow-x-clip break-words p-1 flex-grow">{{shipment.projectId?.nickName || shipment.projectId?.name}}</div>
            <div v-if="showDistance && (['delivery','tool'].includes(shipment.method))" class="w-auto p-1 text-indigo-700 dark:text-indigo-500">{{`${deliveryDistance(shipment.shipmentAddress) || 'N/A'} mi.`}}</div>
        </div>
        <div v-if="shipment.deliveredDate && isValid(new Date(shipment.deliveredDate))" class="w-full whitespace-normal truncate text-left p-1 border-t text-green-700">
            {{format(new Date(shipment.deliveredDate),'M/d/yyyy h:mm aa')}}
        </div>
        <div v-if="shipment.internalDeliveryContact?.name || shipment.externalDeliveryContact?.name" class="w-full border-t flex items-center truncate">
            <div class="w-auto font-semibold p-1">Contact:</div>
            <div v-if="shipment.internalDeliveryContact?.name" class="flex-grow whitespace-normal text-left p-1">{{shipment.internalDeliveryContact?.name}}</div>
            <div v-else-if="shipment.externalDeliveryContact?.name" class="flex-grow whitespace-normal text-left p-1">{{shipment.externalDeliveryContact?.name}}</div>
        </div>
        <div v-if="showDetails" class="w-full">
            <div class="w-full flex flex-row items-center justify-between px-1 border-t">
                <div class="flex-grow text-left whitespace-normal truncate p-1">
                    {{`Coordinator: ${shipment.projectId?.companyMgrId?.name}`}}
                </div>
            </div>
            <div v-if="shipment.shipmentAddress && Object.keys(shipment.shipmentAddress).length > 0" class="w-full flex flex-col p-1 border-t">
                <DisplayAddress :address="shipment.shipmentAddress" />
            </div>
            <div v-if="shipment.shipmentInstructions" class="w-full flex flex-col p-1 border-t">
                <div class="text-left font-semibold whitespace-normal">Instructions</div>
                <div class="text-left whitespace-pre-wrap p-1">
                    {{shipment.shipmentInstructions}}
                </div>
            </div>
            <div v-if="shipment.warehouseNote" class="w-full flex flex-col p-1 border-t">
                <div class="text-left font-semibold whitespace-normal text-sky-600">Warehouse Note</div>
                <div class="text-left whitespace-pre-wrap p-1">
                    {{shipment.warehouseNote}}
                </div>
            </div>
        </div>
        <div v-if="shipment.method !== 'delivery'" class="w-full p-1 border-t">
            <div v-if="shipment.method === 'tool'" class="text-left whitespace-normal truncate text-sky font-semibold">Tool Pickup</div>
            <div v-else-if="shipment.method" class="text-left whitespace-normal truncate">{{shipment.method.charAt(0).toUpperCase() + shipment.method.slice(1)}}</div>
        </div>
        <div v-if="shipment.method === 'courier'" class="w-full flex flex-col items-center truncate border-t">
            <div class="w-full flex flex-row items-center justify-between p-1 flex-wrap sm:flex-nowrap">
                <div class="w-auto text-left whitespace-normal truncate">{{shipment.carrier}}</div>
                <div class="w-auto text-left whitespace-normal truncate">{{shipment.acctNumber}}</div>
            </div>
            <div v-if="shipment.courierTrackingNumber" class="w-full p-1 flex items-center">
            <div class="w-full text-left whitespace-normal truncate">{{shipment.courierTrackingNumber}}</div>
            </div>
        </div>
        <div v-if="shipment.driverId?.name" class="w-full flex flex-row items-center p-1 truncate space-x-1 border-t">
            <SteeringWheelIcon class="w-3 h-3 flex-shrink-0" />
            <div class="w-full p-1 text-left whitespace-normal truncate">
                {{shipment.driverId?.name}}
            </div>
        </div>
    </div>
</template>

<script>
import {MenuAlt3Icon,XIcon,CalendarIcon} from '@heroicons/vue/solid'
import {distanceBetweenPoints} from '@/shared'
import {isValid,format} from 'date-fns'
import SteeringWheelIcon from '@/components/customIcons/SteeringWheelIcon.vue'
import DisplayAddress from '@/components/ui/DisplayAddress.vue'
import { ref } from 'vue'


export default {
    props:{
        shipment:{type:Object,default:()=>{return {}}},
        showDistance:{type:Boolean,default:false},
        startAddress:{type:Object,default:()=>{return {}}}
    },
    emits:["shipmentId"],
    components:{DisplayAddress,MenuAlt3Icon,XIcon,SteeringWheelIcon,CalendarIcon},
    setup(props,{emit}) {
        const showDetails = ref(false)

        const emitShipmentId = ()=>{
            emit("shipmentId",props.shipment?._id)
        }

        const deliveryDistance = (address)=>{
            if(props.showDistance && props.startAddress?.location && address.location) {
                if(!props.startAddress.location?.coordinates[1] || !address?.location?.coordinates[1]) {
                    return null
                } else {
                    return distanceBetweenPoints(
                        props.startAddress?.location?.coordinates[1] || null,
                        props.startAddress?.location?.coordinates[0] || null,
                        address.location?.coordinates[1] || null,
                        address.location?.coordinates[0]
                    ) || null
                }
            }
        }

        return {
            showDetails,
            emitShipmentId,
            deliveryDistance,
            isValid,
            format
        }
    }
}
</script>

<style>

</style>