import {isValid} from 'date-fns'

export default {
    mounted(el) {
        // Add keydown listener to restrict allowed keys
        el.addEventListener('keydown', (event) => {
          const allowedKeys = ['Tab', ' ', 'Enter'];
          if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
          }
        });
    
        // Add change listener to check if the date is valid using date-fns isValid
        el.addEventListener('change', () => {
          const date = new Date(el.value); // Parse date in ISO format
          if (!isValid(date)) {
            el.classList.add('border', 'border-red-600', 'text-red-600'); // Add invalid styling
          } else {
            el.classList.remove('border', 'border-red-600', 'text-red-600'); // Remove invalid styling
          }
        });
      },
      unmounted(el) {
        // Clean up listeners when the element is unmounted
        el.removeEventListener('keydown', null);
        el.removeEventListener('change', null);
      }
}