export default {
    mounted(el) {
      let lastValue = el.value; // Track the last known value
  
      // Initial validation to apply styles if the input is empty
      const initialValidation = () => {
        if (el.value === "") {
          el.classList.add('border', 'border-red-600', 'text-red-600');
        }
      };
  
      // Handler function for real-time styling on input
      const handleInput = (event) => {
        const value = event.target.value;
  
        // Apply red border for partial or invalid input
        if (value === "" || value.length < 5 || event.target.validity.badInput) {
          el.classList.add('border', 'border-red-600', 'text-red-600');
        } else {
          el.classList.remove('border', 'border-red-600', 'text-red-600');
        }
      };
  
      // Handler function for validation on blur
      const handleBlur = (event) => {
        const value = event.target.value;
  
        // Skip validation if the value hasn't changed
        if (value === lastValue) {
          return;
        }
  
        // Update the last known value
        lastValue = value;
  
        // Perform validation only on blur
      // Emit null if the input is empty
        if (value === "" || value === null) {
            el.dispatchEvent(new CustomEvent("valid-time", { detail: null }));
        } 
        // Emit the valid value only if fully filled and valid
        else if (value.length >= 5 && !event.target.validity.badInput) {
            el.dispatchEvent(new CustomEvent("valid-time", { detail: value }));
        }
      };
  
      // Run initial validation on mount
      initialValidation();
  
      // Attach event listeners for real-time styling on input and final validation on blur
      el.addEventListener("input", handleInput);
      el.addEventListener("blur", handleBlur);
  
      // Cleanup function to remove event listeners when the directive is unmounted
      el._cleanup = () => {
        el.removeEventListener("input", handleInput);
        el.removeEventListener("blur", handleBlur);
      };
    },
    unmounted(el) {
      // Remove event listeners using the cleanup function
      el._cleanup();
    }
  };