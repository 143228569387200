<template>
    <div class="w-full relative">
        <div v-if="isWorking"  class="absolute w-full panel bg-opacity-40 p-1 z-70">
            <LoadingData :message="message" />
        </div>
        <div v-else-if="error" class="w-full error">{{error}}</div>
    </div>
</template>

<script>
export default {
    props:{
        isWorking:{type:Boolean,default:false},
        error:{type:String,default:null},
        message:{type:String,default:null}
    },
    setup () {
        return {}
    }

}
</script>

<style>

</style>