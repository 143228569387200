<template>
    <div class="w-full flex flex-col flex-grow p-1">
        <div v-if="!inModal" class="page-header">
            <div>Shipment Schedule</div>
        </div>
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
        <div class="w-full flex items-center flex-wrap border-b truncate">
            <div class="w-auto p-1">
                <input v-model="searchDate" type="date" class="w-28" @keydown.prevent="null" @change="handleDateInput()">
            </div>
            <div class="w-auto flex flex-row items-center">
                <div class="py-1 px-2">
                    <Popper hover arrow placement="top" :content="'By Time'">
                        <button class="bn-icon-only" :class="{'opacity-30' : showView !== 'time'}" @click="showView = 'time'">
                            <ClockIcon />
                        </button>
                    </Popper>
                </div>
                <div class="py-1 px-2">
                    <Popper hover arrow placement="top" :content="'By Vehicle'">
                        <button class="bn-icon-only" :class="{'opacity-30' : showView !== 'vehicle'}" @click="showView = 'vehicle'">
                            <TruckIcon />
                        </button>
                    </Popper>
                </div>
            </div>
            <div v-if="warehouseAuth" class="p-1">
                <ToolReturns @update="updateToolReturn($event)" />
            </div>
        </div>
        <div class="w-full flex flex-grow overflow-x-auto p-1 border">
            <div v-show="showView === 'time'" v-for="(day,index) in scheduleDays" :key="index" class="flex flex-col w-80 sm:w-96 p-1 flex-shrink-0 overscroll-y-auto">
                <div class="w-full flex flex-row items-center truncate panel py-1 px-2">
                    <div v-if="warehouseAuth" class="px-1">
                        <ButtonWithConfirm :popperContent="'email drivers'" :iconComponent="MailIcon" @confirmed="emailDrivers(day)"/>
                    </div>
                    <div class="w-full text-center whitespace-normal truncate">{{format(day,'MMM d, yyyy')}}</div>
                    <div class="px-1">
                        <Popper hover arrow placement="top" :content="'Print day schedule'" @click="printDaySchedule(day)">
                            <button class="bn-icon-only">
                                <PrinterIcon />
                            </button>
                        </Popper>
                    </div>
                </div>
                <div class="w-full flex flex-col flex-grow flex-auto h-0 p-1 overflow-y-auto">
                    <div class="w-full flex flex-col flex-grow overflow-y-auto">
                        <div v-for="obj in groupByDateTime(day,'time')" :key="obj" class="w-full p-1">
                            <div class="w-full flex flex-row items-center justify-between truncate p-1 bg-indigo-300 dark:bg-indigo-700 bg-opacity-60 flex-wrap">
                                <div class="flex-grow flex items-center truncate">
                                    <div class="text-left whitespace-normal overflow-hidden break-words font-semibold">
                                        {{isValid(parseISO(obj.dateTime)) ? format(parseISO(obj.dateTime),'h:mm aaa') : 'Invalid Shipment Time'}}
                                    </div>
                                    <div class="w-auto px-2">
                                        <Popper hover arrow placement="top" :content="'Longest Shipment'">
                                            <div>
                                                {{`${longestDuration(obj.dateTime)} hrs`}}
                                            </div>
                                        </Popper>
                                    </div>
                                </div>
                                <div v-if="Array.isArray(obj.vehicles) && obj.vehicles.length > 0" class="w-auto flex items-center px-1">
                                    <div v-if="obj.vehicles.length > 1">
                                        <Popper hover arrow placement="top" :content="'# vehicles'">
                                            <div class="w-auto flex flex-row items-center px-1">
                                                    <div class="text-left px-1 whitespace-normal">{{obj.vehicles.length}}</div>
                                                    <TruckIcon class="w-4 h-4 flex-shrink-0" />
                                            </div>
                                        </Popper>
                                    </div>
                                    <div>
                                        <Popper hover arrow placement="top" :content="'# shipments'">
                                            <div class="w-auto flex flex-row items-center px-1">
                                                <div class="text-left px-1 whitespace-normal">{{numberOfShipments(obj.dateTime)}}</div>
                                                <BoxIcon class="w-3 h-3 flex-shrink-0"  />
                                            </div>
                                        </Popper>
                                    </div>
                                </div>
                            </div>
                            <div v-if="Array.isArray(obj.vehicles) && obj.vehicles.length > 0" class="w-full p-1 flex flex-col space-y-1">
                                <div v-for="(vehicle,index) in obj.vehicles" :key="index" class="w-full flex flex-col">
                                    <div class="w-full flex flex-row items-center truncate p-1 border panel rounded-none">
                                        <div class="flex-grow text-left whitespace-normal truncate">{{vehicle.vehicleName}}</div>
                                        <div v-if="vehicle.shipments">{{`${longestDurationByVehicle(vehicle.shipments)} hrs`}}</div>
                                    </div>
                                    <div v-if="Array.isArray(vehicle.shipments) && vehicle.shipments.length > 0" class="w-full p-1 border border-t-0 rounded-b-md">
                                        <div v-for="shipment in vehicle.shipments" :key="shipment._id" class="w-full flex flex-col items-center justify-between truncate py-1">
                                            <ScheduledShipmentCard :shipment="shipment" @shipmentId="showShipmentId = $event" :showDistance="true" :startAddress="deliveryStartAddress" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="showView === 'vehicle'" v-for="(day,index) in scheduleDays" :key="index" class="flex flex-col w-auto min-w-80 sm:min-w-96 p-1 flex-shrink-0 overscroll-y-auto">
                <div class="w-full flex flex-row items-center truncate panel py-1 px-2">
                    <div v-if="warehouseAuth" class="px-1">
                        <ButtonWithConfirm :popperContent="'email drivers'" :iconComponent="MailIcon" @confirmed="emailDrivers(day)"/>
                    </div>
                    <div class="w-full text-center whitespace-normal truncate">{{format(day,'MMM d, yyyy')}}</div>
                </div>
                <div class="w-full flex flex-col flex-grow flex-auto h-0 p-1 overflow-y-auto">
                    <div class="w-full flex flex-row flex-grow overflow-y-auto">
                        <div v-for="obj in groupByDateTime(day,'vehicle')" :key="obj" class=" p-1 w-80 sm:w-96">
                            <div class="w-full flex flex-row items-center justify-between truncate py-1 px-2 panel bg-opacity-60 flex-wrap">
                                <div class=" flex-grow text-left whitespace-normal overflow-hidden break-words font-semibold px-1">
                                    {{obj.vehicle}}
                                </div>
                                <div class="p-1">
                                    <Popper hover arrow placement="top" :content="'Print vehicle schedule'">
                                        <button class="bn-icon-only" @click="printVehicleSchedule(day,obj)">
                                            <PrinterIcon />
                                        </button>
                                    </Popper>
                                </div>
                            </div>
                            <div v-if="Array.isArray(obj.schedule) && obj.schedule.length > 0" class="w-full p-1 flex flex-col space-y-1">
                                <div v-for="(time,index) in obj.schedule" :key="index">
                                    <div class=" w-full flex flex-row items-center truncate p-1 border panel rounded-none bg-indigo-300 dark:bg-indigo-700">
                                        <div class="text-left whitespace-normal overflow-hidden break-words font-semibold">
                                            {{isValid(parseISO(time.dateTime)) ? format(parseISO(time.dateTime),'h:mm aaa') : 'Invalid Shipment Time'}}
                                        </div>
                                    </div>
                                    <div v-if="Array.isArray(time.shipmentsByPlace) && time.shipmentsByPlace.length > 0" class="w-full p-1 flex flex-col space-y-1">
                                        <div v-for="(place,index) in time.shipmentsByPlace" :key="index" class="w-full flex flex-col space-y-1 p-1 border">
                                            <div v-if="place.address && Object.keys(place.address).length > 0" class=" w-full p-1 rounded-none panel">
                                                <div class="w-full flex flex-row items-center truncate">
                                                    <div class="flex-grow p-1">
                                                        <DisplayAddress  :address="place.address" />
                                                    </div>
                                                    <div class="w-auto p-1 text-indigo-700 dark:text-indigo-500">{{`${distanceToAddress(place.address) || 'N/A'} mi.`}}</div>
                                                </div>
                                                <div v-if="(Array.isArray(place.shipments) && place.shipments.length > 1) && warehouseAuth" class="w-full text-left pt-1 px-1 border-t">
                                                    <Popper hover arrow placement="top" :content="'Change All'">
                                                        <input type="datetime-local" class="w-44 text-xs" :value="formatISODateforDateTimeInput(time.dateTime)"
                                                        @keydown="event=> event.key !== 'Tab' && event.preventDefault()"
                                                        @change="!$event.target.value ? $event.target.value = formatISODateforDateTimeInput(time.dateTime) : (Array.isArray(place.shipments) && place.shipments.length > 1) ? updateMultiple($event.target,time.dateTime,$event.target.value,place.shipments) : null"
                                                        >
                                                    </Popper>
                                                </div>
                                            </div>
                                            <div v-else class="w-full text-left whitespace-normal truncate p-1 rounded-none panel">No Address</div>
                                            <div v-if="Array.isArray(place.shipments) && place.shipments.length > 0" class="w-full p-1 border-t">
                                                <div v-for="shipment in place.shipments" :key="shipment._id" class="w-full flex flex-col items-center justify-between truncate py-1">
                                                    <ScheduledShipmentCard :shipment="shipment" @shipmentId="showShipmentId = $event" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal v-if="Object.keys(showShipmentObject).length > 0" :title="`${showShipmentObject.projectId?.companyProjectNumber} - ${showShipmentObject.shipmentNumber}`" @closeModal="showShipmentId = null">
        <template v-slot:content>
            <ScheduleShipment :shipmentId="showShipmentObject._id" @updateShipment="updateShipment($event)" @closeModal="showShipmentId = null" />
        </template>
    </Modal>

</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { isValid, format, parseISO, startOfDay, addDays, eachDayOfInterval, isSameDay, compareAsc, isWithinInterval} from 'date-fns'
import {formatISODateforInput,dateInputToISO,distanceBetweenPoints,formatISODateforDateTimeInput} from '@/shared'
import api from '@/api'
import printJS from 'print-js'
import {TruckIcon,ClockIcon,PrinterIcon,MailIcon} from '@heroicons/vue/solid'
import BoxIcon from '@/components/customIcons/BoxIcon.vue'
import ScheduledShipmentCard from './ScheduledShipmentCard.vue'
import DisplayAddress from '@/components/ui/DisplayAddress.vue'
import ScheduleShipment from '@/components/warehouse/schedule/ScheduleShipment.vue'
import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'
import ToolReturns from '@/components/warehouse/tools/ToolReturns.vue'


export default {
    props:{
        scheduleDate:{
            type:String,
            default:null
        },
        defaultView:{
            type:String,
            default:'time'
        },
        inModal:{
            type:Boolean,
            default:true
        }
    },
    components:{ScheduleShipment,DisplayAddress,ScheduledShipmentCard,ButtonWithConfirm,TruckIcon,BoxIcon,ClockIcon,PrinterIcon,ToolReturns},
    emits:["updateShipment"],
    setup(props,{emit}) {
        const global = inject('global')
        const {setModalBlocked,authenticated,sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const selectedDate = ref(props.scheduleDate)
        const searchDate = ref(null)
        const shipments = ref([])
        const deliveryStartAddress = ref({})
        const showView = ref(props.defaultView)
        const showShipmentId = ref(null)
        const warehouseAuth = ref(authenticated.value.role?.accessModel?.warehouseQueue > 0)

        const printStyles = `
                                .w-full {
                                    width: 100%;
                                }
                                .max-w-custom {
                                    max-width: 40rem;
                                }
                                .max-w-2xl {
                                    max-width: 42rem; /* 672px */;
                                }
                                .text-left {
                                    text-align: left;
                                }
                                .text-center {
                                    text-align: center;
                                }
                                .whitespace-normal {
                                    white-space: normal;
                                }
                                .truncate {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                                .flex {
                                    display: flex;
                                }
                                .flex-row {
                                    flex-direction: row;
                                }
                                .flex-col {
                                    flex-direction: column;
                                }
                                .flex-wrap {
                                    flex-wrap: wrap;
                                }
                                .space-x-1 > :not([hidden]) ~ :not([hidden]) {
                                    --tw-space-x-reverse: 0;
                                    margin-right: calc(0.25rem /* 4px */ * var(--tw-space-x-reverse));
                                    margin-left: calc(0.25rem /* 4px */ * calc(1 - var(--tw-space-x-reverse)));
                                }
                                .space-y-1 > :not([hidden]) ~ :not([hidden]) {
                                    --tw-space-y-reverse: 0;
                                    margin-top: calc(0.25rem /* 4px */ * calc(1 - var(--tw-space-y-reverse)));
                                    margin-bottom: calc(0.25rem /* 4px */ * var(--tw-space-y-reverse));
                                }
                                .text-base {
                                    font-size: 1rem /* 16px */;
                                    line-height: 1.5rem /* 24px */;
                                }
                                .text-lg {
                                    font-size: 1.125rem /* 18px */;
                                    line-height: 1.75rem /* 28px */;
                                }
                                .font-semibold {
                                    font-weight: 600 !important;
                                }
                                .border {
                                    border: 1px solid #E5E7EB; /* Border color approximation */
                                }
                                .border-b {
                                    border-bottom: 1px solid #E5E7EB;
                                }
                                .border-t {
                                    border-top: 1px solid #E5E7EB;
                                }
                                .border-y {
                                    border-top: 1px solid #E5E7EB;
                                    border-bottom: 1px solid #E5E7EB;
                                }
                                .p-0.5 {
                                    padding: 0.125rem /* 2px */;
                                }
                                .p-1 {
                                    padding: 0.25rem;
                                }
                                .p-2 {
                                    padding: 0.5rem /* 8px */;
                                }
                                .px-1 {
                                    padding-left: 0.25rem /* 4px */;
                                    padding-right: 0.25rem /* 4px */;
                                }
                                .py-1 {
                                    padding-top: 0.25rem /* 4px */;
                                    padding-bottom: 0.25rem /* 4px */;
                                }
                                .bg-gray-200 {background-color: #e5e7eb; -webkit-print-color-adjust: exact; color-adjust: exact;}
                                .bg-gray-300 {background-color: #D1D5DB; -webkit-print-color-adjust: exact; color-adjust: exact;}
                                .bg-gray-100 {background-color: #f3f4f6; -webkit-print-color-adjust: exact; color-adjust: exact;}
                            `


        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            window.addEventListener('process_complete',handleProcessNotification)

            if (!isValid(parseISO(selectedDate.value))) {
                selectedDate.value = new Date().toISOString()
            }
            searchDate.value = formatISODateforInput(selectedDate.value)
            getSchedule()
            getPrimaryAddress()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
            window.removeEventListener('process_complete',handleProcessNotification)
        })

        const scheduleDays = computed(()=>{
            if(searchDate.value && isValid(parseISO(searchDate.value))) {
                let startDay = startOfDay(parseISO(searchDate.value))
                let endDay = addDays(startDay,7)
                return eachDayOfInterval({start:startDay,end:endDay})
            } else {
                return []
            }
        })

        const showShipmentObject = computed(()=>{
            if(showShipmentId.value) {
                let shipment = shipments.value.find(x=>x._id === showShipmentId.value)
                if(shipment) {
                    return shipment
                } else {
                    return {}
                }
            } else {
                return {}
            }
        })

        const getSchedule = async (type)=>{
            if(type !== 'silent') {
                isWorking.value = true
                setModalBlocked(true)
            }
            responseError.value = null
            let body = {startDate:dateInputToISO(searchDate.value)}
            await api
            .post(`shipments/shipmentSchedule`,body)
            .then((res)=>{
                Array.isArray(res.data?.data)
                ? shipments.value = res.data.data
                :null
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const getPrimaryAddress = async ()=>{
            await api
            .get('organizations/primaryAddress')
            .then(res=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    deliveryStartAddress.value = res.data.data
                }
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const emailDrivers = async (day)=>{
            let body = {startDate:day}
            await api
            .post('shipments/emailDrivers',body)
            .then(()=>{})
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
        }

        const handleDateInput = ()=>{
            if(!searchDate.value) {
                searchDate.value = formatISODateforInput(new Date().toISOString())
            }
            getSchedule()
        }

        const groupByDateTime = (date,type='time')=>{
            if (date && isValid(date)) {
                let filteredShipments = shipments.value.filter((x) => {
                    const shipmentParsedDate = parseISO(x.shipmentDate);
                    return x.shipmentDate && isValid(shipmentParsedDate) && isSameDay(date, shipmentParsedDate);
                });
                
                filteredShipments.sort((a, b) => {
                    // If both have vehicleIds or both are null, then sort by date
                    const dateComparison = compareAsc(parseISO(a.shipmentDate), parseISO(b.shipmentDate));
                    if (dateComparison !== 0) return dateComparison;

                    // Check for null vehicleIds and prioritize them
                    if (!a.vehicleId && b.vehicleId) return -1;
                    if (a.vehicleId && !b.vehicleId) return 1;


                    // If dates are the same, sort by vehicle name (assuming vehicleId objects have a 'name' property)
                    if (a.vehicleId?.name && b.vehicleId?.name) {
                        return a.vehicleId.name.localeCompare(b.vehicleId.name);
                    } else {
                        // Handle cases where one or both names might be missing by defaulting to an empty string
                        return (a.vehicleId?.name || "").localeCompare(b.vehicleId?.name || "");
                    }
                });
                return type === 'time'
                ? groupShipmentsByDate(filteredShipments) 
                : groupShipmentsByVehicle(filteredShipments)

            } else {
                console.log('Bad Date');
                return [];  // Return an empty array if the input date is invalid
            }
        }

        const groupShipmentsByDate = (shipments) => {
            const groups = {};

            shipments.forEach((shipment) => {
                // Format or use shipmentDate directly
                const dateTimeKey = shipment.shipmentDate;

                // Get vehicle name if it exists or default to 'unassigned vehicle'
                const vehicleName = shipment && shipment.vehicleId?.name
                                    ? shipment.vehicleId?.name 
                                    : 'Vehicle not assigned';

                // Initialize date group if it does not exist
                if (!groups[dateTimeKey]) {
                    groups[dateTimeKey] = {};
                }

                // Initialize vehicle group within the date group if it does not exist
                if (!groups[dateTimeKey][vehicleName]) {
                    groups[dateTimeKey][vehicleName] = [];
                }

                // Add shipment to the correct vehicle group under the correct date
                groups[dateTimeKey][vehicleName].push(shipment);
            });

            // Convert the nested groups object into a structured array
            return Object.keys(groups).map(dateTime => ({
                dateTime: dateTime,
                vehicles: Object.keys(groups[dateTime]).map(vehicleName => ({
                    vehicleName: vehicleName,
                    shipments: groups[dateTime][vehicleName]
                }))
            }));
        };

        const groupShipmentsByVehicle = (shipments)=>{
            const groups = {}
            shipments.forEach((shipment) => {
                const dateTimeKey = shipment.shipmentDate;
                const vehicleName = shipment.vehicleId?.name ? shipment.vehicleId?.name : 'Vehicle not assigned';
                const googlePlacesId = shipment.shipmentAddress?.googlePlacesId ? shipment.shipmentAddress?.googlePlacesId : 'No Address';
                const shipmentAddress = shipment.shipmentAddress || {}

                if (!groups[vehicleName]) {
                    groups[vehicleName] = {};
                }

                if (!groups[vehicleName][dateTimeKey]) {
                    groups[vehicleName][dateTimeKey] = {};
                }

                if (!groups[vehicleName][dateTimeKey][googlePlacesId]) {
                    groups[vehicleName][dateTimeKey][googlePlacesId] = {
                        shipments:[],
                        address:shipmentAddress
                    }
                }

                groups[vehicleName][dateTimeKey][googlePlacesId].shipments.push(shipment);
            });

            return Object.keys(groups).sort().map(vehicle => ({
                vehicle: vehicle,
                schedule: Object.keys(groups[vehicle]).sort().map(dateTime => ({
                    dateTime: dateTime,
                    shipmentsByPlace: Object.keys(groups[vehicle][dateTime]).map(googlePlacesId => ({
                        googlePlacesId: googlePlacesId,
                        address: groups[vehicle][dateTime][googlePlacesId].address,
                        shipments: groups[vehicle][dateTime][googlePlacesId].shipments
                    }))
                }))
            }));
        }

        const longestDuration = (dateTime)=>{
            if(!dateTime) {return 1}
            const filteredShipments = shipments.value.filter(x => x.shipmentDate === dateTime);
            if (!filteredShipments.length) return 0; // Handle case when no shipments found

            let longestDuration = 0

            filteredShipments.forEach(shipment=>{
                if(shipment.duration && shipment.duration > longestDuration) {
                    longestDuration = shipment.duration
                }
            })
            return longestDuration
        }

        const longestDurationByVehicle = (shipments)=>{
            if(Array.isArray(shipments)) {
                let longestDuration = 0
                shipments.forEach(shipment=>{
                    if(shipment.duration && shipment.duration > longestDuration) {
                        longestDuration = shipment.duration
                    }
                })
                return longestDuration
            } else { 
                return 1
            }
        }

        const numberOfShipments = (dateTime)=>{
            if(dateTime) {
                return shipments.value.filter(x=>x.shipmentDate === dateTime).length
            } else {
                return null
            }
        }

        const updateShipment = (event)=>{
            let index = shipments.value.findIndex(x=>x._id === event._id)
            if(index > -1) {
                shipments.value[index] = event
                emit("updateShipment",event)
            }
        }

        const distanceToAddress = (address)=>{
            if(deliveryStartAddress.value.location && address.location) {
                if(!deliveryStartAddress.value.location?.coordinates[1] || !address?.location?.coordinates[1]) {
                    return null
                } else {
                    return distanceBetweenPoints(
                        deliveryStartAddress.value.location?.coordinates[1] || null,
                        deliveryStartAddress.value.location?.coordinates[0] || null,
                        address.location?.coordinates[1] || null,
                        address.location?.coordinates[0]
                    ) || null
                }
            }
        }

        const printDaySchedule = async (day)=>{
            let data = groupByDateTime(day)
            let list = '<div class="w-full flex flex-col space-y-1">'
                    list += '<div class="w-full text-left whitespace-normal truncate text-lg">'
                        list+= '<div class="w-full font-semibold border-b p-1">'
                            list+=`Delivery Schedule - ${format(day,'E MMM d, yyyy')}`
                        list+= '</div>'
                    list+='</div>'
                    list += '<div class="w-full flex flex-col space-y-1 py-1">'
                    data.forEach(item=>{
                        list+='<div class="w-full bg-gray-300 truncate">'
                            list+='<div class="w-full p-1 font-semibold">'
                                list+=`${format(new Date(item.dateTime),'hh:mm aaa')}`
                            list+='</div>'
                        list+='</div>'
                        if(Array.isArray(item.vehicles)) {
                            list += '<div class="w-full flex flex-col space-y-1 py-1">'
                            item.vehicles.forEach(vehicle=>{
                                list+='<div class="w-full bg-gray-200 truncate">'
                                    list+='<div class="w-full whitespace-normal p-1 font-semibold">'
                                        list+=vehicle.vehicleName
                                    list+='</div>'
                                list+='</div>'
                                if(Array.isArray(vehicle.shipments)) {
                                    list+='<div class="w-full truncate">'
                                    vehicle.shipments.forEach(shipment=>{
                                        list+='<div class="w-full bg-gray-100 flex truncate">'
                                            list+='<div class="text-left whitespace-normal truncate p-1 font-semibold">'
                                                list+=`${shipment.projectId?.companyProjectNumber} - ${shipment.shipmentNumber} - ${shipment.projectId?.nickName || shipment.projectId?.name}`
                                            list+='</div>'
                                        list+='</div>'
                                        if(shipment.shipmentAddress && Object.keys(shipment.shipmentAddress).length > 0) {
                                            list+='<div class="w-full p-1 truncate">'
                                                list+='<div class="flex flex-row space-x-1 p-0.5">'
                                                    list+='<div class="whitespace-normal">'
                                                        list+=shipment.shipmentAddress?.street_number
                                                    list+='</div>'
                                                    list+='<div class="whitespace-normal">'
                                                        list+=shipment.shipmentAddress?.street
                                                    list+='</div>'
                                                list+='</div>'
                                                if(shipment.method === 'tool') {
                                                    list+='<div class="flex flex-row p-0.5">'
                                                        list+='<div class="whitespace-normal font-semibold">'
                                                            list+='Tool Pickup'
                                                        list+='</div>'
                                                    list+='</div>'
                                                }
                                                if(shipment.shipmentAddress?.addressTwo || shipment.shipmentAddress?.address2) {
                                                    list+='<div class="flex flex-row p-0.5">'
                                                        list+='<div class="whitespace-normal">'
                                                            list+=shipment.shipmentAddress.addressTwo || shipment.shipmentAddress.address2
                                                        list+='</div>'
                                                    list+='</div>'
                                                }
                                                list+='<div class="w-full flex flex-row flex-wrap space-x-1">'
                                                    list+='<div>'
                                                        list+=shipment?.shipmentAddress?.city + ','
                                                    list+='</div>'
                                                    list+='<div>'
                                                        list+=shipment?.shipmentAddress?.state
                                                    list+='</div>'
                                                    list+='<div>'
                                                        list+=shipment?.shipmentAddress?.postalCode
                                                    list+='</div>'
                                                list+='</div>'
                                            list+='</div>'
                                        }
                                        if(shipment?.driverId?.name) {
                                            list+='<div class="w-full p-1 border-t">'
                                                list+=`Driver: ${shipment?.driverId?.name}`
                                            list+='</div>'
                                        }
                                    })
                                    list+='</div>'
                                }
                            })
                            list=='</div>'
                        }
                    })
                    //data.forEach(item=>{
                    //    list+= '<div class="w-full flex flex-col space-y-1 py-1">'
                    //        list+= '<div class="w-full font-semibold bg-gray-300 p-1">'
                    //            list+=`${format(new Date(item.dateTime),'hh:mm aaa')}`
                    //        list+= '</div>'
                    //    list+= '</div>'
                    //    if(Array.isArray(item.vehicles)) {
                    //        item.vehicles.forEach(vehicle=>{
                    //            list+='<div class="w-full p-1 truncate">'
                    //                list+='<div class="w-full text-left whitespace-normal p-1 bg-gray-200">'
                    //                    list+=vehicle.vehicleName
                    //                list+='</div>'
                    //            list+='</div>'
                    //            if(Array.isArray(vehicle.shipments)) {
                    //                list+='<div class="w-full p-1 truncate">'
                    //                vehicle.shipments.forEach(shipment=>{
                    //                    list+='<div class="w-full p-1 bg-gray-100 truncate">'
                    //                        list+='<div class="text-left whitespace-normal truncate">'
                    //                            list+=`${shipment.projectId?.companyProjectNumber} - ${shipment.shipmentNumber} - ${shipment.projectId?.name}`
                    //                        list+='</div>'
                    //                    list+='</div>'
                    //                    if(shipment.shipmentAddress && Object.keys(shipment.shipmentAddress).length > 0) {
                    //                        list+='<div class="w-full p-1">'
                    //                            list+='<div class="flex flex-row space-x-1 p-0.5">'
                    //                                list+='<div class="whitespace-normal">'
                    //                                    list+=shipment.shipmentAddress?.street_number
                    //                                list+='</div>'
                    //                                list+='<div class="whitespace-normal">'
                    //                                    list+=shipment.shipmentAddress?.street
                    //                                list+='</div>'
                    //                            list+='</div>'
                    //                            if(shipment.shipmentAddress?.addressTwo || shipment.shipmentAddress?.address2) {
                    //                                list+='<div class="flex flex-row p-0.5">'
                    //                                    list+='<div class="whitespace-normal">'
                    //                                        list+=shipment.shipmentAddress.addressTwo || shipment.shipmentAddress.address2
                    //                                    list+='</div>'
                    //                                list+='</div>'
                    //                            }
                    //                            list+='<div class="w-full flex flex-row flex-wrap space-x-1">'
                    //                                list+='<div>'
                    //                                    list+=shipment?.shipmentAddress?.city + ','
                    //                                list+='</div>'
                    //                                list+='<div>'
                    //                                    list+=shipment?.shipmentAddress?.state
                    //                                list+='</div>'
                    //                                list+='<div>'
                    //                                    list+=shipment?.shipmentAddress?.postalCode
                    //                                list+='</div>'
                    //                            list+='</div>'
                    //                        list+='</div>'
                    //                    }
                    //                    if(shipment?.driverId?.name) {
                    //                        list+='<div class="w-full p-1 border-t">'
                    //                            list+=`Driver: ${shipment?.driverId?.name}`
                    //                        list+='</div>'
                    //                    }
                    //                })
                    //                list+='</div>'
                    //            }
                    //        })
                    //    }
                    //})
                    list+= '</div>'
                list+='</div>'

            setTimeout(() => {
                printJS({
                    printable:list,type:'raw-html',maxWidth:840,
                    documentTitle:`Delivery Schedule ${day}`,
                    targetStyles:['*'],
                    style:printStyles
                })
            },200)
        }

        const printVehicleSchedule = (day,obj)=>{
            if(obj && typeof obj === 'object') {
                let list = '<div class="w-full flex flex-col space-y-1">'
                        list += '<div class="w-full text-left whitespace-normal truncate text-lg">'
                            list+= '<div class="w-full font-semibold border-b p-1">'
                                list+=`Delivery Schedule - ${format(day,'E MMM d, yyyy')} - ${obj.vehicle || ''}`
                            list+= '</div>'
                        list+='</div>'
                        list += '<div class="w-full flex flex-col space-y-1 py-1">'
                            if(Array.isArray(obj.schedule)) {
                                list += '<div class="w-full flex flex-col space-y-1">'
                                obj.schedule.forEach(schedule=>{
                                    list+= '<div class="w-full">'
                                        list+= '<div class="w-full font-semibold bg-gray-300">'
                                            list+='<div class="w-full p-1 whitespace-normal">'
                                                list+=`${format(new Date(schedule.dateTime),'hh:mm aaa')}`
                                            list+= '</div>'
                                        list+= '</div>'
                                    list+= '</div>'
                                    list+='<div class="">'
                                        if(Array.isArray(schedule.shipmentsByPlace)) {
                                            schedule.shipmentsByPlace.forEach(place=>{
                                                if(place.address && Object.keys(place.address).length > 0) {
                                                    list+='<div class="w-full bg-gray-200 truncate py-1">'
                                                        list+='<div class="w-full px-1">'
                                                            list+='<div class="w-full flex flex-row space-x-1 p-0.5">'
                                                                list+='<div class="whitespace-normal">'
                                                                    list+=place.address?.street_number
                                                                list+='</div>'
                                                                list+='<div class="whitespace-normal">'
                                                                    list+=place.address?.street
                                                                list+='</div>'
                                                            list+='</div>'
                                                        list+='</div>'
                                                        if(place.address?.addressTwo || place.address?.address2) {
                                                            list+='<div class="w-full px-1">'
                                                                list+='<div class="flex flex-row p-0.5">'
                                                                    list+='<div class="whitespace-normal">'
                                                                        list+=place.address.addressTwo || place.address.address2
                                                                    list+='</div>'
                                                                list+='</div>'
                                                            list+='</div>'
                                                        }
                                                        list+='<div class="w-full px-1">'
                                                            list+='<div class="w-full flex flex-row flex-wrap space-x-1">'
                                                                list+='<div>'
                                                                    list+=place?.address?.city + ','
                                                                list+='</div>'
                                                                list+='<div>'
                                                                    list+=place?.address?.state
                                                                list+='</div>'
                                                                list+='<div>'
                                                                    list+=place?.address?.postalCode
                                                                list+='</div>'
                                                            list+='</div>'
                                                        list+='</div>'
                                                    list+='</div>'
                                                    if(Array.isArray(place.shipments)) {
                                                        list+='<div class="w-full truncate flex flex-col space-y-1">'
                                                        place.shipments.forEach(shipment=>{
                                                            list += '<div class="w-full py-1">'
                                                                list+='<div class="w-full p-1 truncate bg-gray-100">'
                                                                    list+='<div class="text-left whitespace-normal truncate px-1 font-semibold">'
                                                                        list+=`${shipment.projectId?.companyProjectNumber} - ${shipment.shipmentNumber} - ${shipment.projectId?.nickName || shipment.projectId?.name}`
                                                                    list+='</div>'
                                                                list+='</div>'
                                                                if(shipment.method === 'tool') {
                                                                    list+='<div class="w-full p-1">'
                                                                        list+='<div class="w-full whitespace-normal p-2 font-semibold">'
                                                                            list+='Tool Pickup'
                                                                        list+='</div>'
                                                                    list+='</div>'
                                                                }
                                                                if(shipment.shipmentInstructions) {
                                                                    list+='<div class="w-full p-1">'
                                                                        list+='<div class="w-full whitespace-normal px-1 font-semibold">'
                                                                            list+='Instructions:'
                                                                        list+='</div>'
                                                                        list+='<div class="w-full whitespace-normal p-2">'
                                                                            list+=shipment.shipmentInstructions.replace(/\n/g, '<br>')
                                                                        list+='</div>'
                                                                    list+='</div>'
                                                                }
                                                                if(shipment.warehouseNote) {
                                                                    list+='<div class="w-full p-1">'
                                                                        list+='<div class="w-full whitespace-normal px-1 font-semibold">'
                                                                            list+='Warehouse Note:'
                                                                        list+='</div>'
                                                                        list+='<div class="w-full whitespace-normal p-2">'
                                                                            list+=shipment.warehouseNote.replace(/\n/g, '<br>')
                                                                        list+='</div>'
                                                                    list+='</div>'
                                                                }
                                                            list+='</div>'
                                                        })
                                                        list+='</div>'
                                                    }
                                                }
                                            })
                                        }
                                        list+='</div>'
                                    }
                                )
                            }
                        list+= '</div>'

                    list+= '</div>'


                setTimeout(() => {
                        printJS({
                            printable:list,type:'raw-html',maxWidth:780,
                            documentTitle:`Delivery Schedule ${format(day,'E MMM d, yyyy')} - ${obj.vehicle || ''}`,
                            targetStyles:['*'],
                            style:printStyles
                        })
                    },200)
            }
        }

        // eslint-disable-next-line no-unused-vars
        const updateMultiple = async (target,prevValue,date,shipments)=>{
            responseError.value = null
            let newDate = new Date(date).toISOString() || null
            let shipmentsToChange = shipments.filter(x=>!x.deliveredDate).map(s=>s._id)
            if(shipmentsToChange.length > 0) {
                isWorking.value = true
                let body = {
                    shipmentDate:newDate,
                    shipments:shipmentsToChange
                }
                await api
                .put('shipments/scheduleMany',body)
                .then(res=>{
                    if(Array.isArray(res.data.data)) {
                        res.data.data.forEach(x=>{
                            updateShipment(x)
                            sendChangeEvent('shipment',x.projectId?._id,'',{
                            projectId:x.projectId?._id,
                            shipmentId:x._id,
                            shipmentState:x.shipmentState,
                            shipmentDate:x.shipmentDate,
                            driverId:x.driverId?._id || x.driverId
                        })
                        })
                    }
                })
                .catch(err=>{
                    target.value = formatISODateforDateTimeInput(prevValue)
                    responseError.value = err.response?.data?.error || err.message
                })
                .finally(()=>{
                    isWorking.value = false
                    target.value = formatISODateforDateTimeInput(prevValue)
                })
            } else {
                responseError.value = 'All shipments have already been delivered.'
            }
        }

        const shipmentSocketUpdate = async (data)=>{
            try {
                    let startOfInterval = new Date(dateInputToISO(searchDate.value))
                    let endOfInterval = new Date(addDays(startOfInterval,7))
                    let shipmentDate = new Date(data.shipmentDate)
                    if (isWithinInterval(shipmentDate, { start: startOfInterval, end: endOfInterval })) {
                        await api
                        .get(`shipments/${data.shipmentId}`)
                        .then(res=>{
                            if(res.data?.data && typeof res.data?.data === 'object') {
                                let index = shipments.value.findIndex(x=>x._id === res.data?.data?._id)
                                if(index > -1) {
                                    shipments.value[index] = res.data.data
                                } else {
                                    shipments.value.push(res.data.data)
                                }
                            }
                        })
                        .catch(err=>{
                            console.log(err.response?.data?.error || err.message)
                        })
                    } else {
                        console.log('Not within Interval')
                        let index = shipments.value.findIndex(x=>x._id===data.shipmentId)
                        if(index > -1) {
                            console.log('Have Index')
                            shipments.value.splice(index,1)
                        } else {
                            console.log('No Index')
                        }
                    }
            } catch (err) {
                    console.error(err)
            }
        }

        //const toolSocketUpdate = async (id)=>{
        //    await api
        //    .get(`tools/${id}`)
        //    .then((res)=>{
        //        if(res.data?.data && typeof res.data.data === 'object') {
        //            if(res.data.data?.returnDate) {
        //                let toolIndex = toolReturns.value.findIndex(x=>x._id === res.data?.data?._id)
        //                if(toolIndex > -1) {
        //                    toolReturns.value[toolIndex] = res.data.data
        //                } else {
        //                    toolReturns.value.push(res.data.data)
        //                }
        //            } else {
        //                let toolIndex = toolReturns.value.findIndex(x=>x._id === res.data?.data?._id)
        //                if(toolIndex > -1) {
        //                    toolReturns.value.splice(toolIndex,1)
        //                }
        //            }
        //        }
        //    })
        //    .catch((err)=>{
        //        console.error(err.response?.data?.error || err.message)
        //    })
        //}

        const updateToolReturn = (event)=>{
            if(event.shipment &&  typeof event.shipment === 'object' && event.shipment?.shipmentDate) {
                    const shipment = event.shipment
                    let startOfInterval = new Date(dateInputToISO(searchDate.value))
                    let endOfInterval = new Date(addDays(startOfInterval,7))
                    let shipmentDate = new Date(shipment.shipmentDate)
                    if (isWithinInterval(shipmentDate, { start: startOfInterval, end: endOfInterval })) {
                        let index = shipments.value.findIndex(x=>x._id === shipment._id)
                        if(index > -1) {
                            shipments.value[index] = shipment
                        } else {
                            shipments.value.push(shipment)
                        }
                    }
                    sendChangeEvent('shipment',shipment.projectId?._id,'',{
                        projectId:shipment.projectId?._id,
                        shipmentId:shipment._id,
                        shipmentState:shipment.shipmentState,
                        shipmentDate:shipment.shipmentDate,
                        driverId:shipment.driverId?._id || shipment.driverId
                    })
            }
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type && e.detail.type === 'shipment') {
                if(e.detail?.data?.shipmentId) {
                    if(e.detail.data?.isDelete) {
                        let index = shipments.value.findIndex(x=>x._id ===  e.detail?.data?.shipmentId)
                        if(index > -1) {
                            shipments.value.splice(index,1)
                        }
                    } else {
                        if(e.detail?.data?.shipmentDate) {
                            shipmentSocketUpdate(e.detail?.data)
                        }
                    }
                } 
            }
        }

        const handleProcessNotification = (e)=>{
            if(e.detail?.data === 'scheduleToolPickups' ) {
                getSchedule('silent')
            }
        }

        return {
            isValid,
            format,
            isWorking,
            responseError,
            searchDate,
            handleDateInput,
            shipments,
            scheduleDays,
            groupByDateTime,
            parseISO,
            longestDuration,
            numberOfShipments,
            longestDurationByVehicle,
            groupShipmentsByVehicle,
            showView,
            showShipmentObject,
            showShipmentId,
            updateShipment,
            printDaySchedule,
            printVehicleSchedule,
            emailDrivers,
            MailIcon,
            warehouseAuth,
            deliveryStartAddress,
            distanceToAddress,
            formatISODateforDateTimeInput,
            updateMultiple,
            updateToolReturn
        }
    }
}
</script>

<style>

</style>