<template>
  <div class="w-full flex flex-col flex-grow items-center space-y-1">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div class="w-full flex flex-row items-center truncate flex-wrap border-b">
        <div class="text-left w-36 flex-shrink-0 p-1">
            <input v-model="selectedDate" type="date" class="w-full max-w-28" @keydown.prevent="null" @change="setDate()">
        </div>
        <div class="flex-grow text-left whitespace-normal truncate p-1 line-clamp-2">Total Hours calculated for install, service, commission, programming and rack build only</div>
    </div>
    <div v-if="!isWorking && typeof totalTimeSummary === 'object' && Object.keys(totalTimeSummary).length > 0" class="w-full flex flex-col flex-grow flex-auto h-0 items-center px-1 py-2 max-w-5xl space-y-1 overflow-y-auto">
        <div class="w-full flex items-center p-1 flex-wrap">
            <div class="w-auto whitespace-normal text-left truncate font-semibold pr-1">Total Travel:</div>
            <div class="flex-grow flex items-center px-2 truncate">
                <div class="w-full flex items-center flex-wrap px-2 justify-normal sm:justify-around truncate">
                    <div class="w-auto flex items-center flex-wrap-reverse p-1">
                        <div class="font-semibold pr-1">Travel:</div>
                        <div>{{`${totalTimeSummary.totalClockedTravel || 0} hrs`}}</div>
                    </div>
                    <div class="w-auto flex items-center flex-wrap-reverse p-1">
                        <div class="font-semibold pr-1">Overnight Travel:</div>
                        <div>{{`${totalTimeSummary.totalClockedOvernightTravel || 0} hrs`}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full text-left whitespace-normal p-1 bg-sky-700 dark:bg-sky-600 text-white font-semibold bg-opacity-60 dark:bg-opacity-60">
            Total Hours
        </div>
        <div class="w-full flex items-center flex-wrap text-sm">
            <div class="w-auto flex flex-col min-w-44 self-stretch p-0.5">
                <div class="w-auto flex flex-row items-center flex-wrap justify-start truncate">
                    <div class="w-auto p-1 whitespace-normal">Sold:</div>
                    <div class="w-auto p-1 whitespace-normal font-semibold text-green-700">{{totalTimeSummary.totalServiceHours || 0}}</div>
                </div>
            </div>
            <div class="w-auto flex flex-col min-w-44 self-stretch p-0.5">
                <div class="w-auto flex flex-row items-center flex-wrap justify-start truncate">
                    <div class="w-auto p-1 whitespace-normal">Remaining:</div>
                    <div class="w-auto p-1 whitespace-normal font-semibold" :class="{'text-red-700': totalTimeSummary.remainingHours < 0}">{{totalTimeSummary.remainingHours || 0}}</div>
                </div>
            </div>
            <div class="flex-grow flex flex-col min-w-44 self-stretch p-0.5">
                <div class="w-auto flex flex-row items-center flex-wrap justify-start truncate">
                    <div class="w-auto p-1 whitespace-normal">Scheduled:</div>
                    <div class="w-auto p-1 whitespace-normal font-semibold" :class="{'text-red-700': totalTimeSummary.totalScheduledHours > totalTimeSummary.totalServiceHours}">{{totalTimeSummary.totalScheduledHours || 0}}</div>
                </div>
                <div class="w-full flex flex-row items-center flex-wrap justify-start border-t">
                    <div class="w-auto flex flex-row items-center flex-nowrap justify-start px-2 truncate">
                        <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4" /></div>
                        <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': totalTimeSummary.totalScheduledPastHours > totalTimeSummary.totalServiceHours}">{{totalTimeSummary.totalScheduledPastHours || 0}}</div>
                    </div>
                    <div class="w-auto flex flex-row items-center flex-wrap justify-start px-2 truncate">
                        <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': totalTimeSummary.totalScheduledHours > totalTimeSummary.totalServiceHours}">{{totalTimeSummary.totalScheduledFutureHours || 0}}</div>
                        <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4 rotate-180" /></div>
                    </div>
                </div>
            </div>
            <div class="flex-grow flex flex-col min-w-44 self-stretch p-0.5">
                <div class="w-auto flex flex-row items-center flex-nowrap justify-start truncate">
                    <div class="w-auto p-1 whitespace-normal">Clocked:</div>
                    <div class="w-auto p-1 whitespace-normal font-semibold" :class="{'text-red-700': totalTimeSummary.totalClockedHours > totalTimeSummary.totalClockedHours}">{{totalTimeSummary.totalClockedHours || 0}}</div>
                </div>
                <div class="w-full flex flex-row items-center flex-wrap justify-start border-t">
                    <div class="w-auto flex flex-row items-center flex-wrap justify-start px-2">
                        <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4" /></div>
                        <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': totalTimeSummary.totalClockedPastHours > totalTimeSummary.totalServiceHours}">{{totalTimeSummary.totalClockedPastHours  || 0}}</div>
                    </div>
                    <div class="w-auto flex flex-row items-center flex-wrap justify-start px-2">
                        <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': totalTimeSummary.totalScheduledHours > totalTimeSummary.totalServiceHours}">{{totalTimeSummary.totalClockedFutureHours || 0}}</div>
                        <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4 rotate-180" /></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isWorking && typeof timeSummary === 'object' && Object.keys(timeSummary).length > 0" class="w-full flex flex-col items-center px-1 py-2 max-w-5xl space-y-1">
            <div class="w-full text-left whitespace-normal p-1 bg-sky-700 dark:bg-sky-600 text-white font-semibold bg-opacity-60 dark:bg-opacity-60">
                Breakdown
            </div>
            <div class="w-full p-1">
                <div v-for="[key, value] in Object.entries(timeSummary)" :key="key" class="w-full flex items-center truncate flex-wrap">
            <div class="w-full text-left whitespace-normal p-1 bg-indigo-700 text-white bg-opacity-60 dark:bg-opacity-60">
                {{capitalizeFirstLetter(key)}}
            </div>
            <div class="w-full flex items-center truncate flex-wrap">
                <div class="w-auto flex flex-col min-w-44 self-stretch p-0.5">
                    <div class="w-auto flex flex-row items-center flex-wrap justify-start">
                        <div class="w-auto p-1 whitespace-normal">Sold:</div>
                        <div class="w-auto p-1 whitespace-normal font-semibold">{{value.serviceHours || 0}}</div>
                    </div>
                </div>
                <div class="w-auto flex flex-col min-w-44 self-stretch p-0.5">
                    <div class="w-auto flex flex-row items-center flex-wrap justify-start">
                        <div class="w-auto p-1 whitespace-normal">Remaining:</div>
                        <div class="w-auto p-1 whitespace-normal font-semibold" :class="{'text-red-700': value.remainingHours < 0}">{{value.remainingHours || 0}}</div>
                    </div>
                </div>
                <div class="flex-grow flex flex-col min-w-44 self-stretch p-0.5">
                    <div class="w-auto flex flex-row items-center flex-wrap justify-start">
                        <div class="w-auto p-1 whitespace-normal">Scheduled:</div>
                        <div class="w-auto p-1 whitespace-normal font-semibold" :class="{'text-red-700': value.scheduledHours > value.serviceHours}">{{value.scheduledHours || 0}}</div>
                    </div>
                    <div class="w-full flex flex-row items-center flex-wrap justify-start border-t">
                        <div class="w-auto flex flex-row items-center flex-nowrap justify-start px-2 truncate ">
                            <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4" /></div>
                            <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': value.scheduledPastHours > value.serviceHours}">{{value.scheduledPastHours || 0}}</div>
                        </div>
                        <div class="w-auto flex flex-row items-center flex-wrap justify-start px-2">
                            <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': value.scheduledHours > value.serviceHours}">{{value.scheduledFutureHours || 0}}</div>
                            <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4 rotate-180" /></div>
                        </div>
                    </div>
                </div>
                <!--<div class="flex-grow flex flex-col min-w-44 self-stretch p-0.5">
                    <div class="w-auto flex flex-row items-center flex-nowrap justify-start truncate">
                        <div class="w-auto p-1 whitespace-normal">Clocked:</div>
                        <div class="w-auto p-1 whitespace-normal font-semibold" :class="{'text-red-700': value.totalClockedHours > value.totalClockedHours}">{{value.totalClockedHours || 0}}</div>
                    </div>
                    <div class="w-full flex flex-row items-center flex-wrap justify-start">
                        <div class="w-auto flex flex-row items-center flex-wrap justify-start px-2">
                            <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4" /></div>
                            <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': value.totalClockedPastHours > value.totalServiceHours}">{{value.totalClockedPastHours  || 0}}</div>
                        </div>
                        <div class="w-auto flex flex-row items-center flex-wrap justify-start px-2">
                            <div class="w-auto px-1"><ArrowSmLeftIcon class="w-4 h-4 rotate-180" /></div>
                            <div class="w-auto p-0.5 whitespace-normal font-semibold" :class="{'text-red-700': value.totalScheduledHours > value.totalServiceHours}">{{value.totalClockedFutureHours || 0}}</div>
                        </div>
                    </div>
                </div>-->
            </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import {formatISODateforInput,dateInputToISO,capitalizeFirstLetter} from '@/shared'
import {isBefore,isSameDay,isValid,startOfDay,format} from 'date-fns'
import {ArrowSmLeftIcon} from '@heroicons/vue/solid'
import api from '@/api'
export default {
	components: {ArrowSmLeftIcon},
  props:{
    project:{type:Object,default:()=>{return {}}},
    allowEdit:{type:Boolean,default:false},
    services:{type:Array,default:()=>{return []}}
  },
  setup (props) {
    const global = inject('global')
    const {setModalBlocked} = global
    const isWorking = ref(false)
    const responseError = ref(null)
    const selectedDate = ref(null)
    const cutoffDate = ref(startOfDay(new Date()))
    const clockedTime = ref([])
    const scheduledTime = ref([])
    const partNumberId = {
        commission:['COMMISH'],
        install:['INSTALL'],
        programming:['CSP','ADSP'],
        rackbuild:['RACKBUILD'],
        service:['SERVICE'],

    }

    onMounted(()=>{
        selectedDate.value = formatISODateforInput(cutoffDate.value.toISOString())
        window.addEventListener("data_change_from_socket",handleSocketChange)
        getProjectTime()
    })

    onUnmounted(()=>{
        window.removeEventListener("data_change_from_socket",handleSocketChange)
    })

    const timeSummary = computed(()=>{
        const relevantPartNumbers =  Object.values(partNumberId).flat()
        const relevantServiceObjects = props.services.filter(x=>x.partNumber && x.category && relevantPartNumbers.some(prefix=>x.partNumber.startsWith(prefix)))

        const result = {};
        
        // Iterate over each key in partNumberId
        Object.keys(partNumberId).forEach(key => {
            // Initialize the separate counters for service hours and scheduled hours
            let serviceHours = 0;
            let scheduledHours = 0;
            let scheduledPast = 0;
            let scheduledFuture = 0

            // Sum hours from props.services where partNumber starts with values in partNumberId[key]
            relevantServiceObjects.forEach(service => {
                if (partNumberId[key].some(prefix => service.partNumber && service.partNumber.startsWith(prefix))) {
                    serviceHours += service.hours || 0;
                }
            });

            // Sum hours from scheduledTime where type equals the current key
            scheduledTime.value.forEach(appointment => {
                if (appointment.appointmentType === key) {
                    scheduledHours += appointment.duration;
                    if(appointment.appointmentTime && isValid(new Date(appointment.appointmentTime)) && (isSameDay(new Date(appointment.appointmentTime),cutoffDate.value) || isBefore(new Date(appointment.appointmentTime),cutoffDate.value))) {
                        scheduledPast += appointment.duration || 0
                    } else {
                        scheduledFuture += appointment.duration || 0
                    }
                }
            });

             const remainingHours = serviceHours - scheduledHours

            // Store both the service hours and scheduled hours in the result object
            result[key] = {
                serviceHours: serviceHours,
                scheduledHours: scheduledHours,
                scheduledPastHours: scheduledPast,
                scheduledFutureHours:scheduledFuture,
                remainingHours
            };
        });

        return result;
    })

    const totalTimeSummary = computed(() => {
        const summary = timeSummary.value;
        let totalServiceHours = 0;
        let totalScheduledHours = 0;
        let totalScheduledPastHours = 0;
        let totalScheduledFutureHours = 0;
        let totalClockedHours = 0;
        let totalClockedPastHours = 0;
        let totalClockedFutureHours = 0;
        let totalClockedTravel = 0
        let totalClockedOvernightTravel = 0

        Object.values(summary).forEach((item) => {
            totalServiceHours += item.serviceHours || 0;
            totalScheduledHours += item.scheduledHours || 0;
            totalScheduledPastHours += item.scheduledPastHours || 0;
            totalScheduledFutureHours += item.scheduledFutureHours || 0;
        });

        clockedTime.value.forEach(allocation=>{
            totalClockedTravel += allocation.travelTime || 0
            totalClockedOvernightTravel += allocation.overnightTravel || 0
            totalClockedHours += allocation.totalTime || 0
            if(allocation.clockIn && isValid(new Date(allocation.clockIn)) && (isSameDay(new Date(allocation.clockIn),cutoffDate.value) || isBefore(new Date(allocation.clockIn),cutoffDate.value))) {
                totalClockedPastHours += allocation.totalTime || 0
            } else {
                totalClockedFutureHours += allocation.totalTime || 0
            }
        })

        const remainingHours = totalServiceHours - totalScheduledHours

        return {
            totalServiceHours,
            totalScheduledHours,
            totalScheduledPastHours,
            totalScheduledFutureHours,
            totalClockedHours,
            totalClockedPastHours,
            totalClockedFutureHours,
            totalClockedTravel,
            totalClockedOvernightTravel,
            remainingHours
        };
    });

    const getProjectTime = async (type)=>{
        if(type !== 'silent') {
            isWorking.value = true
            setModalBlocked(true)
        }
        responseError.value = null
        await api
        .get(`projects/projectTime/${props.project?._id}`)
        .then((res)=>{
            if(res.data?.data && typeof res.data.data === 'object' && !Array.isArray(res.data.data)) {
                if(Array.isArray(res.data.data.clocked)) {clockedTime.value = res.data.data.clocked}
                if(Array.isArray(res.data.data.scheduled)) {scheduledTime.value = res.data.data.scheduled}
            }
        })
        .catch((err)=>{
            responseError.value = err.response?.data?.error || err.message
        })
        .finally(()=>{
            isWorking.value = false
            setModalBlocked(false)
        })
    }

    const setDate = ()=>{
        isValid(new Date(selectedDate.value))
            ? cutoffDate.value = startOfDay(dateInputToISO(selectedDate.value))
            : selectedDate.value = formatISODateforInput(cutoffDate.value.toISOString())
    }

    const handleSocketChange = (e)=>{
        if(e.detail?.type) {
            console.log(e.detail?.type)
            console.log(e.detail?.data)
            switch(e.detail?.type) {
                case 'appointment':
                case 'project':
                    if(e.detail.data?.projectId && e.detail.data?.projectId === props.project._id) {
                        getProjectTime('silent')
                    }
                    break;
                case 'timeclock':
                    getProjectTime('silent')
                    break;
                case 'appointment_delete':
                    if(e.detail.data?.appointmentId) {
                        let apptIndex = scheduledTime.value.findIndex(x=>x._id === e.detail?.data?.appointmentId)
                        if(apptIndex > -1) {
                            scheduledTime.value.splice(apptIndex,1)
                        }
                    }
                    break;
                default:
                    break
            }
        }
    }


    return {
        isWorking,
        responseError,
        selectedDate,
        cutoffDate,
        setDate,
        isValid,
        format,
        timeSummary,
        totalTimeSummary,
        capitalizeFirstLetter
    }
  }
}
</script>

<style>

</style>