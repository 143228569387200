<template>
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <!-- Top Data -->
    <div class="w-full flex flex-row items-center space-x-2 p-1 border-b max-w-4xl">
        <div class="px-1">
            <Popper hover arrow placement="top" :content="'New Invoice'">
                <button class="bn-solid-green px-1 py-0.5" @click="saveInvoice()" :disabled="isWorking || !allowInvoiceActivity">
                    <DocumentDuplicateIcon />
                    <PlusIcon />
                </button>
            </Popper>
        </div>
        <div v-if="typeof creditDetails == 'object' && Object.keys(creditDetails).length > 0" class="w-full px-1 flex flex-row items-center truncate justify-end flex-wrap">
            <div v-if="creditDetails.creditLimit?.creditLimit && Number(creditDetails.creditLimit?.creditLimit)" class="px-1 truncate"
            :class="Number(creditDetails.outstandingBalance) > Number(creditDetails.creditLimit?.creditLimit) ? 'font-semibold text-red-700' : 'text-green-500'"
            >
                {{`credit limit: ${showAsUSCurrency(creditDetails.creditLimit?.creditLimit)}`}}
            </div>
            <div v-if="creditDetails.outstandingBalance && Number(creditDetails.outstandingBalance)" class="px-1 truncate"
            :class="(Number(creditDetails.outstandingBalance) + Number(totalInvoicedAmount)) > Number(creditDetails.creditLimit?.creditLimit) ? 'font-semibold text-red-700' : null"
            >
                <div v-if="creditDetails.outstandingBalance && Number(creditDetails.outstandingBalance)" class="px-1 truncate">
                    {{`balance: ${showAsUSCurrency(creditDetails.outstandingBalance)}`}}
                </div>
            </div>
        </div>
    </div>
    <div  class="w-full flex flex-col items-center p-2" >
    <!-- Summary -->
        <div class="w-full flex flex-col items-center space-y-1 max-w-4xl border-b p-1">
            <div class="w-full flex flex-row item-center panel p-1  justify-between flex-wrap truncate">
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="truncate text-green-600">{{showAsUSCurrency(sales)}}</div>
                    <div>Sales</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="truncate">{{showAsUSCurrency(totalRequested)}}</div>
                    <div>Requested</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="truncate">{{showAsUSCurrency(totalInvoicedAmount)}}</div>
                    <div>Invoiced</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="truncate">{{showAsUSCurrency(totalPayments)}}</div>
                    <div>Revenue</div>
                </div>
            </div>
            <div class="w-full flex flex-row item-center panel p-1 justify-between flex-wrap truncate">
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="truncate text-yellow-700">{{showAsUSCurrency(sales-totalInvoicedAmount)}}</div>
                    <div>Unbilled</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center truncate">
                    <div class="truncate text-red-500">{{showAsUSCurrency(totalInvoicedAmount-totalPayments)}}</div>
                    <div>Outstanding</div>
                </div>
                <div class="flex flex-col space-y-0.5 p-1 items-center justify-center truncate">
                    <div class="text-sm sm:text-lg font-semibold truncate">{{sales ? ((totalInvoicedAmount/sales)*100).toFixed(0) : 0}}%</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Current Invoices -->
    <div class="w-full flex flex-col flex-grow space-y-1 max-w-4xl">
        <div class="w-full flex p-1">{{`${invoices && invoices.length > 0 ? invoices.length : 0 } invoice${invoices && invoices.length !== 1 ? 's': ''}`}}</div>
        <div class="w-full flex flex-col flex-grow flex-auto h-0 items-center p-1 overflow-y-auto">
            <div class="w-full flex flex-col flex-grow flex-shrink-0 items-center overflow-y-auto space-y-1">
                <div v-for="inv in invoices" :key="inv._id" class="w-full truncate">
                    <div class="w-full flex flex-col space-y-1 justify-center truncate rounded-md border py-1 px-2">
                        <div class="w-full flex flex-row space-x-1 items-center truncate border-b py-1">
                            <div v-if="inv.dateInvoiced" class="w-full text-left text-green-700">{{format(new Date(inv.dateInvoiced),'MMM dd, yyyy')}}</div>
                            <div v-else-if="inv.requestDate" class="w-full text-left text-yellow-600">{{format(new Date(inv.requestDate),'MMM dd, yyyy')}}</div>
                            <div v-else-if="inv.forecastDate" class="w-full text-left text-sky-600">{{format(new Date(inv.forecastDate),'MMM dd, yyyy')}}</div>
                            <div class="w-full text-right whitespace-normal px-1">{{inv.requestor}}</div>
                            <div class="p-1">
                                <div v-if="inv.requestDate" class="">
                                    <LockClosedIcon class="h-4 w-4" />
                                </div>
                                <div v-else class="flex items-center">
                                    <Popper hover arrow placement="top" :content="'Send Invoice'">
                                        <button class="bn-icon-only" @click="sendToInvoicing(inv._id)" :disabled="isWorking || inv.requestDate || inv.requestedAmount === 0">
                                            <MailIcon />
                                        </button>
                                    </Popper>
                                </div>
                            </div>
                            <div class="p-1">
                                <button class="bn-icon-only" @click="showInvoiceId = inv._id">
                                    <ExternalLinkIcon  />
                                </button>
                            </div>
                            <div>
                                <ButtonWithConfirm :iconComponent="TrashIcon" :popperContent="'Delete Invoice'" @confirmed="deleteInvoice(inv._id)" :disabled="!!inv.requestDate || isWorking" />
                            </div>
                        </div>
                        <div class="w-full flex flex-row space-x-1 items-center justify-between flex-wrap truncate">
                            <div class="flex flex-col items-center truncate">
                                <div :class="Number(inv.requestedAmount) < 0 ? 'text-red-700' : null">{{showAsUSCurrency(inv.requestedAmount)}}</div>
                                <div>requested</div>
                            </div>
                            <div class="flex flex-col items-center truncate">
                                <div :class="Number(inv.requestedAmount) < 0 ? 'text-red-700' : null">{{showAsUSCurrency(inv.invoicedAmount)}}</div>
                                <div>invoiced</div>
                            </div>
                            <div class="flex flex-col items-center truncate">
                                <div>{{showAsUSCurrency(inv.totalPayments)}}</div>
                                <div>paid</div>
                            </div>
                        </div>
                        <div class="w-full text-left truncate p-1">
                            <div class="w-full p-1 border-b font-semibold">Internal Comment</div>
                            <textarea v-model="inv.internalComment" v-auto-resize maxlength="1000" class="w-full min-h-10 resize-y" placeholder="internal comment" :disabled="isWorking || !inv.isOpen"
                            @focus="prevValue = inv.internalComment" @blur="inv.internalComment !== prevValue ? updateInvoice(inv._id,'internalComment',inv.internalComment,prevValue) : null"
                            />
                        </div>
                        <div v-if="inv.invoicingComment" class="w-full text-left truncate p-1">
                            <div class="w-full p-1 border-b text-sky-600 font-semibold">Invoicing Comment (Internal)</div>
                            <div class="w-full whitespace-normal truncate p-1" v-html="inv.invoicingComment"></div>
                        </div>
                        <div class="w-full flex flex-row space-x-1 items-center justify-start flex-wrap truncate p-1 border-t">
                            <div v-if="!inv.isOpen" class="text-left px-1 truncate text-green-700">Closed</div>
                            <div v-else-if="inv.dateInvoiced && isValid(new Date(inv.dateInvoiced))" :class="getAge(inv.dateInvoiced) > 90 ? 'text-red-700' : null">
                                {{`Invoiced ` + formatDistanceToNow(new Date(inv.dateInvoiced),{addSuffix:true})}}
                            </div>
                            <div v-else-if="inv.requestDate && isValid(new Date(inv.requestDate))" :class="getAge(inv.requestDate) > 90 ? 'text-red-700' : null">
                                {{`Requested ` + formatDistanceToNow(new Date(inv.requestDate),{addSuffix:true})}}
                            </div>
                            <div v-else-if="inv.forecastDate && isValid(new Date(inv.forecastDate))" class="text-sky">
                                {{`Forecast `}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Suspense>
        <Modal v-if="Object.keys(invoiceToShow).length > 0" :title="`Invoice`" @closeModal="showInvoiceId = null">
            <template v-slot:content>
                <Invoice :invoice="invoiceToShow" :invoices="invoices" :projectId="projectId" @updateInvoice="invoiceUpdate($event)" :allowInvoiceActivity="allowInvoiceActivity" />
            </template>
        </Modal>
    </Suspense>
</template>

<script>

import { ref, computed, inject, onMounted, onUnmounted } from 'vue'
import api from "@/api"
import {format,isValid,differenceInCalendarDays,formatDistanceToNow} from "date-fns"
import {PlusIcon,MailIcon,DocumentDuplicateIcon,ExternalLinkIcon } from "@heroicons/vue/outline"
import {TrashIcon,LockClosedIcon} from "@heroicons/vue/solid"
import {showAsUSCurrency} from "@/shared"
import autoResize from '@/components/ui/autoResize'
import Invoice from '@/components/projects/invoices/Invoice.vue'
import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'

export default {
    directives:{
        autoResize
    },
    props:{
        projectId:{type:String,required:true},
        clientId:{type:String,required:true},
        invoices:{type:Array,default:()=>{return[]}},
        sales:{type:Number,default:0},
        allowInvoiceActivity:{type:Boolean,default:false}
    },
    components:{PlusIcon,MailIcon,DocumentDuplicateIcon,Invoice,ExternalLinkIcon, ButtonWithConfirm,LockClosedIcon },
    emits:["triggerProjectUpdate","invoiceUpdate"],
    setup (props,{emit}) {
        const isWorking = ref(false)
        const responseError = ref(null)
        const creditDetails = ref({})
        const showInvoiceId = ref(null)

        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global

        onMounted(()=>{
            window.addEventListener("data_change_from_socket",handleSocketChange)
            getCreditDetails()
        })

        onUnmounted(()=>{
            window.removeEventListener("data_change_from_socket",handleSocketChange)
        })

        const remaining = computed(()=>{
            return props.sales - (props.invoices ? props.invoices.filter(invoice => invoice.requestDate).reduce((acc,currentVal) => {return acc + currentVal.balance},0) : 0)
        })

        const totalRequested = computed(()=>{
            return props.invoices 
            ? props.invoices
                .filter(invoice => invoice.requestDate) // Only include invoices with a requestDate
                .reduce((acc, currentVal) => acc + (currentVal.requestedAmount || 0), 0) 
            : 0;
        })

        const totalInvoicedAmount = computed(()=>{
            return props.invoices ? props.invoices.filter(invoice => invoice.dateInvoiced).reduce((acc,currentVal) => {return acc + currentVal.invoicedAmount},0) : 0
        })

        const totalPayments = computed(()=>{
            return props.invoices ? props.invoices.filter(invoice => invoice.dateInvoiced).reduce((acc,currentVal) => {return acc + currentVal.totalPayments},0) : 0
        })

        const invoiceToShow = computed(()=>{
            if(showInvoiceId.value) {
                let invoiceIndex = props.invoices.findIndex(x=>x._id === showInvoiceId.value)
                return invoiceIndex > -1 ? props.invoices[invoiceIndex] : {}
            } else {
                return {}
            }
        })

        const getCreditDetails = async ()=>{
            await api
            .get('organizations/credit/' + props.clientId)
            .then((res)=>{
                creditDetails.value = res.data.data
            })
            .catch((err)=>{
                console.error(err.response?.data?.error || err.message)
            })
        }

        const invoiceUpdate = (event)=>{
            sendChangeEvent('invoice','','',{projectId:props.projectId,organizationId:props.clientId,invoiceId:event._id,openUpdate:event.isOpen && event.dateInvoiced})
            emit('triggerProjectUpdate')
        }

        const getAge = (date)=>{
            let compareDate = new Date(date)
            return differenceInCalendarDays(new Date(),compareDate)
        }

        const saveInvoice = async ()=>{
            isWorking.value = true
            responseError.value = null
            setModalBlocked(true)
            let body = {
                //forecastDate:new Date().toISOString(),
                projectId:props.projectId,
                clientId:props.clientId
            }
            await api
            .post('invoices/create',body)
            .then((res)=>{
                console.log(res.data?.data)
                emit("triggerProjectUpdate")
                getCreditDetails()
                sendChangeEvent('invoice','','',{projectId:props.projectId,organizationId:props.clientId,invoiceId:res.data?.data?._id,openUpdate:res.data?.data?.isOpen && res.data?.data?.dateInvoiced})
                showInvoiceId.value = res.data.data._id
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }
        
        const updateInvoice = async (id,key,value)=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            let body = {[key]:value}
            await api
            .put(`invoices/edit/${id}`,body)
            .then(res=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    sendChangeEvent('invoice','','',{projectId:props.projectId,organizationId:props.clientId,invoiceId:res.data?.data?._id,openUpdate:res.data?.data?.isOpen && res.data?.data?.dateInvoiced})
                    emit('triggerProjectUpdate')
                }
            })
            .catch(err=>{
                responseError.value = err.data?.response?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const deleteInvoice = async (id)=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            await api
            .delete(`invoices/delete/${id}`)
            .then(res=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    sendChangeEvent('invoice','','',{projectId:props.projectId,organizationId:props.clientId,invoiceId:res.data?.data?._id,openUpdate:res.data?.data?.isOpen && res.data?.data?.dateInvoiced})
                    emit('triggerProjectUpdate')
                }
            })
            .catch(err=>{
                responseError.value = err.data?.response?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        const sendToInvoicing = async (id)=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            let body = {requestDate:new Date().toISOString()}
            await api
            .put(`invoices/sendToQueue/${id}`,body)
            .then(res=>{
                if(res?.data?.data && typeof res.data.data === 'object') {
                    sendChangeEvent('invoice','','',{projectId:props.projectId,organizationId:props.clientId,invoiceId:res.data?.data?._id,openUpdate:res.data?.data?.isOpen && res.data?.data?.dateInvoiced})
                    emit('triggerProjectUpdate')
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const handleSocketChange = (e)=>{
            if(e.detail?.type) {
                switch(e.detail.type) {
                    case 'invoice':
                        e.detail.data?.oganizationId === props.clientId
                        ? getCreditDetails()
                        : null
                        break;
                    case 'organization':
                        e.detail.data?.id === props.clientId
                        ? getCreditDetails()
                        : null
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            saveInvoice,
            isWorking,
            responseError,
            totalRequested,
            totalInvoicedAmount,
            totalPayments,
            showAsUSCurrency,
            remaining,
            format,
            isValid,
            getAge,
            creditDetails,
            updateInvoice,
            formatDistanceToNow,
            showInvoiceId,
            invoiceToShow,
            TrashIcon,
            deleteInvoice,
            invoiceUpdate,
            sendToInvoicing
        }
    }
}
</script>

<style>

</style>