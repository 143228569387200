<template>
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div class="w-full flex flex-col flex-grow p-1 items-center max-w-4xl">
        <div class="w-full flex p-1 justify-end">
            <Popper hover arrow placement="top" :content="'shipment schedule'">
                <button class="bn-solid px-2 py-0.5" @click="showScheduling = true">
                    <div>schedule</div>
                    <ExternalLinkIcon />
                </button>
            </Popper>
        </div>
        <div class="w-full flex flex-col space-y-1 items-center flex-grow p-1 truncate overflow-y-auto">
            <div class="w-full flex flex-col items-center py-1 px-2 rounded-md border">
<!-- Date and Shipment Type -->
                <div class="w-full flex flex-row items-center p-1 flex-wrap justify-between border-b">
                    <div class="w-auto flex items-center flex-wrap">
                        <input v-model="localDate" type="datetime-local" class="w-44" :disabled="isWorking">
                        <div class="w-auto flex items-center justify-start py-1 px-2">
                            <div class="hover:opacity-55 hover:cursor-pointer font-semibold" :class="newShipment.scheduleType === 'firm' ? 'text-red-600' : 'text-green-600'"
                            @click="newShipment.scheduleType === 'firm' ? newShipment.scheduleType = 'flexible' : newShipment.scheduleType = 'firm'"
                            >
                                {{newShipment.scheduleType}}
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow flex flex-row space-x-1 items-center justify-center p-1">
                        <input v-model="newShipment.duration" type="number" min="1" max="15" class="w-14 text-center flex-shrink-0" @blur="handleNumberInput()">
                        <div class="px-1">hrs</div>
                    </div>
                    <div class="flex flex-row items-center justify-between truncate">
                        <div v-for="(option,index) in shipmentOptions" :key="index" class="p-1">
                            <Popper hover arrow placement="top" :content="option.popperContent || ''">
                                <button class="bn-icon-only" :disabled="isWorking"
                                :class="newShipment.method === option.method ? 'text-sky-600' : 'opacity-70'"
                                @click="newShipment.method !== option.method ? setShipmentMethod(option.method) : null"
                                >
                                <component v-if="option.iconComponent" :is="option.iconComponent" />
                                </button>
                            </Popper>
                        </div>
                    </div>
                </div>
<!-- Shipping Account -->
                <div v-if="newShipment.method" class="w-full text-left p-1 font-semibold text-base whitespace-normal text-sky-700 dark:text-sky-600">{{newShipment.method.charAt(0).toUpperCase() + newShipment.method.slice(1)}}</div>
                <div v-if="newShipment.method" class="w-full flex items-center truncate">
                    <div v-if="newShipment.method === 'courier'" class="w-full flex flex-col space-y-1 p-1 border-t">
                        <select v-if="Array.isArray(organization.shippingAccounts)" v-model="localAccountSelect" class="w-full max-w-sm md:max-w-md" :disabled="isWorking"
                        @change="setShippingAccount($event)"
                        >
                            <option hidden :value="null">select account</option>
                            <option v-for="acct in organization.shippingAccounts" :key="acct._id" :value="acct._id" class="w-full whitespace-normal">
                                {{`${acct.carrier} ${acct.customName ? `- ${acct.customName}` : ''}`}}
                            </option>
                        </select>
                        <div class="w-full flex flex-row items-center">
                            <div class="w-full sm:max-w-md flex flex-row items-center justify-between flex-wrap">
                                <div class="py-1 w-52 flex-shrink-0">
                                    <input v-model="newShipment.carrier" type="text" class=" w-full" placeholder="carrier" maxlength="30" >
                                </div>
                                <div class="py-1 w-52 flex-shrink-0">
                                    <input v-model="newShipment.acctNumber" type="text" class="w-full" placeholder="account #" maxlength="30" >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!-- Contact -->
                <div v-if="!internalDeliveryContact._id && Object.keys(externalDeliveryContact).length === 0" class="w-full p-1 flex flex-col border-t">
                    <div class="w-full p-1 text-left whitespace-normal truncate font-semibold border-b">Add Shipment Contact</div>
                    <div class="w-full flex flex-col space-y-2 py-2">
                        <div class="w-full p-1 border rounded-md">
                            <div class="w-full p-1 border-b text-left whitespace-normal truncate">Search DLSTracker</div>
                            <div class="w-full flex p-1 max-w-md">
                                <searchApi :apiEndpoint="'search/internalContacts'" :displayResult="false" :placeholder="'DLSTracker Contact'" @resultSelected="setInternalDeliveryContact($event)" />
                            </div>
                        </div>
                        <div class="w-full p-1 border rounded-md">
                            <div class="w-full p-1 border-b text-left whitespace-normal truncate">External Contact</div>
                            <ExternalContact @emitContact="externalDeliveryContact = $event" :allowEdit="true" />
                        </div>
                    </div>
                </div>
                <div v-else class="w-full flex flex-col p-1 border-t">
                    <div class="w-full p-1 text-left whitespace-normal truncate font-semibold border-b">Shipment Contact</div>
                    <div class="w-full flex flex-row items-center truncate">
                        <div v-if="internalDeliveryContact._id" class="flex-grow text-left whitespace-normal truncate p-1 font-semibold">{{internalDeliveryContact.name}}</div>
                        <div v-else-if="Object.keys(externalDeliveryContact).length > 0" class="w-full flex flex-row items-center truncate flex-wrap">
                            <div class="w-full text-left p-1 whitespace-normal truncate font-semibold">
                                <div class="w-full max-w-xs">
                                    <input v-model="externalDeliveryContact.name" type="text" class="w-full" placeholder="name">
                                </div>
                            </div>
                            <div class="w-full max-w-xs p-1 whitespace-normal truncate">
                                <input v-model="externalDeliveryContact.email" type="email" class="w-full" placeholder="email">
                            </div>
                            <div class="w-auto p-1 whitespace-normal truncate">
                                <PhoneInput :allowEdit="true" :currentValue="externalDeliveryContact.phone" @phoneValidated="externalDeliveryContact.phone = $event" />
                            </div>
                        </div>
                        <div class="p-1">
                            <Popper hover arrow placement="top" :content="'clear contacts'">
                                <button class="bn-icon-only" @click="clearContacts()">
                                    <TrashIcon />
                                </button>
                            </Popper>
                        </div>
                    </div>
                </div>
<!-- Integrator Driver Contact -->
                <div v-if="newShipment.method === 'integrator'" class="w-full flex flex-col border-t p-1">
                    <div class="w-full p-1 border-b text-left whitespace-normal truncate font-semibold">Integrator Delivery Driver</div>
                    <div v-if="Object.keys(integratorDeliveryDriver).length > 0" class="w-full flex items-center truncate">
                        <div class="w-full flex flex-row items-center truncate flex-wrap">
                            <div class="w-full text-left p-1 whitespace-normal truncate font-semibold">
                                <div class="w-full max-w-xs">
                                    <input v-model="integratorDeliveryDriver.name" type="text" class="w-full" placeholder="name">
                                </div>
                            </div>
                            <div class="w-full max-w-xs p-1 whitespace-normal truncate">
                                <input v-model="integratorDeliveryDriver.email" type="email" class="w-full" placeholder="email">
                            </div>
                            <div class="w-auto p-1 whitespace-normal truncate">
                                <PhoneInput :allowEdit="true" :currentValue="integratorDeliveryDriver.phone" @phoneValidated="integratorDeliveryDriver.phone = $event" />
                            </div>
                        </div>
                        <div class="p-1">
                            <Popper hover arrow placement="top" :content="'clear driver'">
                                <button class="bn-icon-only" @click="clearIntegratorDeliveryDriver()">
                                    <TrashIcon />
                                </button>
                            </Popper>
                        </div>
                    </div>
                    <div v-else class="w-full p-1">
                        <ExternalContact @emitContact="integratorDeliveryDriver = $event" :allowEdit="true" />
                    </div>
                </div>
<!-- Address -->
                <div class="w-full flex flex-col space-y-1 p-1 border-y z-10">
                    <div class="w-full flex items-start">
                        <div class="w-auto flex flex-row space-x-2 items-center flex-wrap">
                            <div class="p-1">
                                <Popper hover arrow placement="top" :content="'Project Address'">
                                    <button class="bn-icon-only" 
                                    @click="setLocation('site')"
                                    :disabled="isWorking || newShipment.method === 'pickup' || !project.projectAddress">
                                        <ClipboardIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div class="p-1">
                                <Popper hover arrow placement="top" :content="'Customer Address'">
                                    <button class="bn-icon-only" :disabled="isWorking || newShipment.method === 'pickup' || !organization.mainAddress"
                                    @click="setLocation('customer','main')"
                                    >
                                        <OfficeBuildingIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div class="p-1">
                                <Popper hover arrow placement="top" :content="'Customer Shipping'">
                                    <button class="bn-icon-only" :disabled="isWorking || newShipment.method === 'pickup' || !organization.shippingAddress"
                                    @click="setLocation('customer','shipping')"
                                    >
                                        <TruckIcon />
                                    </button>
                                </Popper>
                            </div>
                            <div class="p-1">
                                <Popper hover arrow placement="top" :content="'Custom Address'">
                                    <button class="bn-icon-only" :disabled="isWorking || newShipment.method === 'pickup'"
                                    @click="setLocation('alt')"
                                    >
                                        <LocationMarkerIcon />
                                    </button>
                                </Popper>
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <div v-if="newShipment.locationType === 'pickup'" class="w-full py-1 px-2 text-left truncate">
                            Customer Pickup
                        </div>
                        <div v-else-if="newShipment.locationType === 'alt'">
                            <div class="w-full max-w-sm">
                                <addressLookup ref="addressLookupDiv" :placeholder_text="'custom address'" :showResults="false" @addressSelected="newShipment.shipmentAddress = $event" />
                            </div>
                        </div>
                        <div v-else-if="newShipment.shipmentAddress" class="w-full p-1">
                            <div class="w-full max-w-xl whitespace-normal truncate">
                                <DisplayAddress :address="newShipment.shipmentAddress" />
                            </div>
                        </div>
                    </div>
                </div>
<!-- Instructions -->
                <div class="w-full flex items-center p-1">
                    <textarea v-model="newShipment.shipmentInstructions" placeholder="instructions" rows="3" maxlength="150" class="w-full p-1 overflow-y-auto resize-none" :disabled="isWorking" />
                </div>
<!-- Save -->
                <div class="w-full flex flex-row space-x-2 truncate items-center py-2 justify-center border-t z-0">
                    <button class="px-2 py-1 bn-solid-green" @click="saveNewShipment()"
                    :disabled="isWorking || !canSaveShipment"
                    >
                        <SaveIcon />
                        <div>Save</div>
                    </button>
                </div>
            </div>
        </div>
<!-- Schedule Modal -->
        <Modal v-if="showScheduling" :title="'Delivery Schedule'" @closeModal="showScheduling = false" showFull >
            <template v-slot:content>
                <ShipmentSchedule :scheduleDate="localDate ? new Date(localDate).toISOString() : null" @updateShipment="emitUpdate($event)" />
            </template>
        </Modal>
    </div>
</template>

<script>
import { computed, ref, inject, onMounted } from 'vue'
//import {dateTimeInputToISO} from "@/shared"
import {isValid,parseISO,isFuture,isToday,format} from "date-fns"
import {SaveIcon,ExternalLinkIcon,TrashIcon} from "@heroicons/vue/outline"
import {OfficeBuildingIcon,TruckIcon,LocationMarkerIcon,ClipboardIcon} from "@heroicons/vue/solid"
import ShipmentSchedule from '@/components/warehouse/schedule/ShipmentSchedule.vue'
import VanShuttleIcon from '@/components/customIcons/VanShuttleIcon.vue'
import ProductDeliveryIcon from '@/components/customIcons/ProductDeliveryIcon.vue'
import CourierIcon from '@/components/customIcons/CourierIcon.vue'
import addressLookup from '@/components/ui/addressLookup.vue'
import DisplayAddress from '@/components/ui/DisplayAddress.vue'
import api from '@/api'
import searchApi from '@/components/ui/searchApi.vue'
import ExternalContact from '@/components/ui/ExternalContact.vue'
import PhoneInput from '@/components/ui/PhoneInput.vue'



export default {
    props:{
        project:{type:Object,default:()=>{return {}}},
        organization:{type:Object,default:()=>{return {}}}
    },
    emits:["newShipment","updateShipment"],
    components:{ShipmentSchedule,addressLookup,DisplayAddress,searchApi,ExternalContact,PhoneInput,VanShuttleIcon,ProductDeliveryIcon,CourierIcon,SaveIcon,OfficeBuildingIcon,TruckIcon,LocationMarkerIcon,ClipboardIcon,ExternalLinkIcon,TrashIcon},
    setup (props,{emit}) {
        const global = inject('global')
        const {setModalBlocked} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const addressLookupDiv = ref(null)
        const newShipmentInitial = {
            shipmentDate:null,
            method:'delivery',
            shipmentInstructions:null,
            locationType:null,
            shipmentAddress:null,
            carrier:null,
            acctNumber:null,
            scheduleType:'firm',
            duration:1,
            internalDeliveryContact:null,
            externalDeliveryContact:null,
            integratorDeliveryDriver:null
        }
        const internalDeliveryContact = ref({})
        const externalDeliveryContact = ref({})
        const integratorDeliveryDriver = ref({})

        const newShipment = ref({...newShipmentInitial})
        const localDate = ref(null)

        const localAccountSelect = ref(null)
        const showScheduling = ref(false)

        const shipmentOptions = [
            {method:'delivery',popperContent:'Delivery',iconComponent:VanShuttleIcon},
            {method:'courier',popperContent:'Courier',iconComponent:CourierIcon},
            {method:'pickup',popperContent:'Pickup',iconComponent:ProductDeliveryIcon},
            {method:'integrator',popperContent:'Integrator Delivery',iconComponent:TruckIcon}
        ]

        onMounted(()=>{

        })


        const canSaveShipment = computed(()=>{
            return isValid(parseISO(localDate.value)) && (isToday(parseISO(localDate.value)) || isFuture(parseISO(localDate.value)) )
            && (newShipment.value.method === 'courier' ? (newShipment.value.carrier && newShipment.value.acctNumber) : true )
            && (newShipment.value.method !== 'pickup' ? (newShipment.value.shipmentAddress && Object.keys(newShipment.value.shipmentAddress)) : true)
        })

        const startAPI = (type)=>{
            if(type !== 'silent') {
                setModalBlocked(true)
                isWorking.value = true
                responseError.value = null
            }
        }

        const stopAPI = ()=>{
            setModalBlocked(false)
            isWorking.value = false
        }

        const setShipmentMethod = (val)=>{
            newShipment.value.method = val
            if(val !== 'courier') {
                newShipment.value.carrier = null
                newShipment.value.acctNumber = null
            }
            if(val !== 'integrator') {
                integratorDeliveryDriver.value = {}
            }
            if(val === 'pickup') {
                newShipment.value.shipmentAddress = null 
                addressLookupDiv.value ? addressLookupDiv.value.clearAddress(): null
                newShipment.value.locationType = 'pickup'
            } else {
                newShipment.value.locationType = null
            }
        }

        const setShippingAccount = (event)=>{
            if(event.target?.value && Array.isArray(props.organization.shippingAccounts)) {
                let selectedAccount = props.organization.shippingAccounts.find(x=>x._id === event.target.value)
                if(selectedAccount) {
                    newShipment.value.carrier = selectedAccount.carrier
                    newShipment.value.acctNumber = selectedAccount.acctNumber
                }
            }
            localAccountSelect.value = null
        }

        const setLocation = (val,type)=>{
            newShipment.value.locationType = val
            switch (val) {
                case 'site':
                    newShipment.value.shipmentAddress = props.project.projectAddress
                    break;
                case 'customer':
                    if(type === 'shipping') {
                        newShipment.value.shipmentAddress = props.organization.mainAddress || {}
                    } else {
                        newShipment.value.shipmentAddress = props.organization.shippingAddress || {}
                    }
                    break;
                default:
                    break;
            }
        }

        const updateDateTime = (val)=>{
            if(isValid(parseISO(val))) {
                localDate.value = val
            } else {
                console.error('Bad Date')
                localDate.value = null
            }
        }

        const handleNumberInput = ()=>{
            if(!newShipment.value.duration) {
                newShipment.value.duration = 1
                return;
            } else {
                let value = parseFloat(newShipment.value.duration);
                value = Math.max(1, Math.min(value, 15)); // Clamp the value between 1 and 10
                // Round to nearest 0.25
                value = Math.round(value / 0.25) * 0.25;
                newShipment.value.duration = value; 
            }
        }

        const setInternalDeliveryContact = (event)=>{
            internalDeliveryContact.value = event
        }

        const clearContacts = ()=>{
            internalDeliveryContact.value = {}
            externalDeliveryContact.value = {}
        }

        const clearIntegratorDeliveryDriver = ()=>{
            integratorDeliveryDriver.value = {}
        }

        const saveNewShipment = async ()=>{
            startAPI()
            newShipment.value.shipmentDate = isValid(new Date(localDate.value)) ? new Date(localDate.value).toISOString() : null
            newShipment.value.projectId = props.project._id
            if(internalDeliveryContact.value._id) {
                newShipment.value.internalDeliveryContact = internalDeliveryContact.value._id
            } else if (externalDeliveryContact.value.name) {
                newShipment.value.externalDeliveryContact = externalDeliveryContact.value
            }
            if(newShipment.value.method === 'integrator' && integratorDeliveryDriver.value.name) {
               newShipment.value.integratorDeliveryDriver = integratorDeliveryDriver.value
            }
            console.log(newShipment.value)
            await api
            .post("shipments/create",newShipment.value)
            .then((res)=>{
                res.data?.data && typeof res.data.data === 'object'
                ? emit("newShipment",res.data.data)
                : null
                addressLookupDiv.value ? addressLookupDiv.value.clearAddress(): null
                localDate.value = null
                newShipment.value = {...newShipmentInitial}
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                stopAPI()
            })
        }

        const emitUpdate = (event)=>{
            emit("updateShipment",event)
        }

        return {
            isWorking,
            responseError,
            newShipment,
            setShipmentMethod,
            addressLookupDiv,
            setLocation,
            setShippingAccount,
            localAccountSelect,
            saveNewShipment,
            localDate,
            canSaveShipment,
            showScheduling,
            parseISO,
            isValid,
            updateDateTime,
            format,
            handleNumberInput,
            emitUpdate,
            setInternalDeliveryContact,
            internalDeliveryContact,
            externalDeliveryContact,
            clearContacts,
            clearIntegratorDeliveryDriver,
            shipmentOptions,
            integratorDeliveryDriver,
        }
    }
}
</script>

<style>

</style>