import { io } from 'socket.io-client';
import global from "../global";


export function socketConnect(token,secureCode) {

    if(!process.env.VUE_APP_API_SERVER) { console.error('No process env')}

    if (token || secureCode) {
        const socket = io(process.env.VUE_APP_API_SERVER, {
            auth: {
                token: token,
                secureCode
            },
            reconnection:true,
            reconnectionAttempts:Infinity,
            reconnectionDelay:2000,
            reconnectionDelayMax:5000,
            randomizationFactor:0.5,
            closeOnBeforeunload: true
        });

        socket.io.on("error",(err)=>{
            console.log('Socket IO Error:')
            console.log(err)
        })

        // Listener to close existing socket if issued new token at relogin before expiration
        window.addEventListener("closeExistingSocket", () => {
            console.log('Closing existing socket')
            socket.close()
            window.removeEventListener('closeExistingSocket', () => { })
        });

        // Listener issues requests to join a room from server
        window.addEventListener("requestRoom", (e) => {
            socket.emit('requestRoomAdmission', { route: e.detail?.route, room: e.detail?.room }, (response) => {
                console.log(response.status)
            })
        })

        // Listener issues request to leave a room from server
        window.addEventListener("leaveRoom", (e) => {
            socket.emit('requestRoomExit', e.detail?.room,)
        })

        window.addEventListener("local_data_change",(e)=>{
            socket.emit('data_change_broadcast',e.detail)
        })

        // Lets application know the initial websocket was successful. Used on refresh.
        socket.on("connect", () => {
            global.setAppStatus('ws', true)
            let socketDataEvent = new CustomEvent("socket_connected",{id:'Hi'})
            dispatchEvent(socketDataEvent)
        });

        socket.on("disconnect", () => {
            console.log("%csocket disconnected", "color:red;font-weight: bold;font-size: 12px");
            global.setAppStatus('ws', false)
            socket.removeAllListeners('disconnect');
            socket.removeAllListeners('connection');
        })

        socket.on("connect_error", (err) => {
            console.log(err instanceof Error);
            global.setAppStatus('ws', false)
            console.log(`%csocket connection failed: ${err}`, "color:red;font-weight: bold;font-size: 12px");
        });


        // Status sent from server if db connection is lost\reconnected.
        socket.on("db", (data) => {
            (data) ? console.log('%cServer connection to database connected', "color:green;font-weight: bold;font-size: 12px ") : console.log('%cServer connection to database disconnected', "color:red;font-weight: bold;font-size: 12px ")
            global.setAppStatus('db', data)
        })

        // received new CRUD data from server and dispatches event to appropriate component.
        socket.on("new_data", (type, room, data) => {
            if (type && room && data) {
                try {
                    const newData = JSON.parse(data)
                    const eventName = 'new_data:' + room
                    console.log(`Sending Event to new_data:${room}`)
                    let socketDataEvent = new CustomEvent(eventName, {
                        detail: { type: type, room: room, data: newData }
                    })
                    dispatchEvent(socketDataEvent)
                } catch (err) {
                    console.error(err)
                    console.error('invalid data received via socket request')
                }
            } else {
                console.log('socket request missing data')
            }
        })

        socket.on("queue_update", (e)=>{
                try {
                    const data = JSON.parse(e)
                    if(data.queue && data.count) {
                        let socketDataEvent = new CustomEvent(data.queue,{
                            detail: {data: data.count}
                        })
                        dispatchEvent(socketDataEvent)
                    }
                } catch (err) {
                    console.error('Invalid Data received in queue update socket.')
                }
        })

        socket.on("socket_data_change_trigger",(event)=>{
            console.log('Received data change from client. Socket ID:', socket.id);
            let changeTriggerEvent = new CustomEvent("data_change_from_socket",{detail:event});
            dispatchEvent(changeTriggerEvent);
            console.log(`Received Event From another Client.`)
        })

        socket.on('process_complete',(event)=>{
            if(event) {
                let socketDataEvent = new CustomEvent('process_complete',{
                    detail: {data: event}
                })
                dispatchEvent(socketDataEvent)
            }
        })

        // Message from server on successful socket connection.
        socket.on("welcomeMessage", (data) => { console.log(`%c${data}`, "color:green;font-weight: bold;font-size: 12px ") })

        // Logs message from server
        socket.on('message', (data) => {
            console.log(data)
        })

    } else {
        console.error('Missing Token or Code.')
    }
}




