<template>
    <div v-if="optionsError">
        <div class="error">{{optionsError}}</div>
    </div>
    <div v-else class="w-full flex flex-col">
        <div class="w-full flex flex-col space-y-1 cursor-pointer" ref="multiSelectDropdown">
            <div class="w-full flex flex-row items-center space-x-2 text-left rounded-md bg-neutral-200 dark:bg-neutral-900  p-0.5" @click="Object.keys(options).length > 0 ? showSelectMenu = !showSelectMenu : showSelectMenu = false">
                <div class="w-full flex-grow truncate px-1 py-0.5">
                    <div v-if="Object.keys(selectedItems).length > 0 ">
                        <div v-if="Object.keys(selectedItems).length <= 3" class="flex flex-row space-x-1 items-center truncate" >
                                <div v-for="(selection,index) in selectedItems" :key="index" class="w-auto">
                                    {{index === Object.keys(selectedItems).length -1 
                                    ? keyName 
                                        ? selection[keyName]
                                        : selection.certName || selection.skill || selection.name || selection
                                    : keyName
                                        ? selection[keyName] + ','
                                        : (selection.certName || selection.skill || selection.name || selection['avTask'] || selection) + ','
                                    }}
                                </div>
                        </div>
                        <div v-else class="flex flex-row space-x-1 items-center truncate">
                            {{`${Object.keys(selectedItems).length} items selected. `}}
                        </div>
                    </div>
                    <div v-else class="w-full truncate opacity-50">
                        {{Object.keys(options).length === 0 ? 'No Options Available': placeHolder}}
                    </div>
                </div>
                <div class="pointer-events-none" :class="Object.keys(options).length === 0 ? 'text-red-500 cursor-not-allowed' : null">
                    <ChevronDownIcon v-if="!showSelectMenu" class="h-4 w-4" />
                    <XIcon v-else class="h-4 w-4" />
                </div>
            </div>
            <div v-if="showSelectMenu && Object.keys(options).length > 0 && !disabled" class="relative w-full">
                <div class="w-full absolute top-0 left-0 max-h-36 p-1 overflow-y-auto shadow-md rounded-b-md z-30 panel rounded-t-none">
                    <div class="w-full flex flex-col space-y-1 px-2">
                        <div v-for="(option,index) in options" :key="index" class="w-full hover:bg-sky-700 hover:font-semibold cursor-pointer truncate text-left" 
                            :class="typeof option !== 'object' ? selectedItems.includes(option) ? 'bg-sky-600 font-semibold' : null : selectedItems.map((s)=>s._id).includes(option._id) ? 'bg-sky-600' : null"
                            @click="valueSelected(option)"
                            >
                            <div class="w-full flex flex-row space-x-1 items-center">
                                    <CheckIcon v-if=" typeof option !== 'object' ? selectedItems.includes(option) : selectedItems.map((s)=>s._id).includes(option._id)" class="h-4 w-4" />
                                    <div class="w-full flex-grow whitespace-normal truncate text-left p-0.5">
                                        {{
                                            keyName
                                            ? option[keyName]
                                            : option.certName || option.skill || option.name || option
                                        }}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ChevronDownIcon,XIcon,CheckIcon} from "@heroicons/vue/solid"
export default {
    components:{ChevronDownIcon,XIcon,CheckIcon},
    emits:["selectionUpdated"],
    props:{
        options:{type:Array,default:()=>{return[]}},
        placeHolder:{type:String,default:'Select'},
        defaultSelections:{type:Array,default:()=>{return []}},
        keyName:{type:String,default:''},
        disabled:{type:Boolean,default:false}
    },
    data:()=>{
        return {
            selectedItems: [],
            showSelectMenu:false,
            optionsError:''
        }
    },
    watch:{
        defaultSelections: function () {
            if(!this.defaultSelections.every((x)=>this.options.includes(x)|| this.options.map((o)=>o._id).includes(x._id))) {
                this.optionsError = 'Options mismatch.'
            } else {
                this.selectedItems = this.defaultSelections
            }
            
        }
    },
    mounted:function(){
        this.selectedItems = this.defaultSelections
        document.addEventListener('click',this.handleOutsideClick)
    },
    unmounted:function() {
        document.removeEventListener('click',this.handleOutsideClick)
    },
    methods:{
        valueSelected:function (item) {
            if(typeof item !== 'object') {
                if(this.selectedItems.includes(item)) {
                    let index = this.selectedItems.findIndex((x)=>x === item)
                    if(index >= 0) {
                        this.selectedItems.splice(index,1)
                    }
                } else {
                    this.selectedItems.push(item)
                }
            } else {
                if(this.selectedItems.map((x)=>x._id).includes(item._id)) {
                    let index = this.selectedItems.findIndex((x)=>x._id === item._id)
                    if(index >= 0) {
                        this.selectedItems.splice(index,1)
                    }
                } else {
                    this.selectedItems.push(item)
                }
            }

            this.$emit("selectionUpdated",this.selectedItems)
        },
        handleOutsideClick: function (event) {
            if(this.$refs.multiSelectDropdown && !this.$refs.multiSelectDropdown.contains(event.target)) {
                this.showSelectMenu = false
            } else {
                event.stopPropagation()
            }
        },

        clearSelections: function() {
            this.selectedItems = []
        }
    }
}
</script>

<style>

</style>