export default {
    mounted(el) {
      const handleInput = (event) => {
        let value = event.target.value;
  
        // Remove any non-digit characters
        value = value.replace(/\D/g, '');
  
        // Ensure the value is a whole number between 1 and 100
        let numericValue = parseInt(value, 10);
        if (isNaN(numericValue) || numericValue < 1) {
          numericValue = '';
        } else if (numericValue > 100) {
          numericValue = 100;
        }
  
        // Update the input field if necessary
        if (event.target.value !== numericValue.toString()) {
          event.target.value = numericValue;
          // Trigger the input event to update v-model
          el.dispatchEvent(new Event('input', { bubbles: true }));
        }
      };
  
      el.addEventListener('input', handleInput);
  
      el._cleanup = () => {
        el.removeEventListener('input', handleInput);
      };
    },
    beforeUnmount(el) {
      el._cleanup();
    },
  };