<template>
    <div class="w-full flex flex-row items-center flex-wrap truncate">
        <WorkingAndError :isWorking="isWorking" :error="responseError" />
        <div class="text-left flex-grow p-1">
            <input v-model="shipmentDate" type="datetime-local" class="w-44" :disabled="isWorking">
        </div>
        <div class="p-1">
            <Popper hover arrow placement="top" :content="'Schedule Tool Pickup'">
                <button class="bn-icon-only" :disabled="!isValid(parseISO(shipmentDate))"
                @click="newToolPickupDelivery(projectId)"
                >
                    <VanFillIcon class="-scale-x-100" />
                </button>
            </Popper>
        </div>
    </div>
</template>

<script>
import VanFillIcon from '@/components/customIcons/VanFillIcon.vue'
import { computed, inject, ref } from 'vue'
import {isValid,parseISO} from 'date-fns'
import api from '@/api'
export default {
    props:{
        projectId:{type:String,default:null},
        tools:{type:Array,default:()=>{return []}},
        address:{type:Object,default:()=>{return {}}}
    },
    emits:['update'],
    components:{VanFillIcon},
    setup (props,{emit}) {
  
        const global = inject('global')
        const {setModalBlocked} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const shipmentDate = ref(null)

        const selectedTools = computed(()=>{
            return props.tools.filter(x=>x.selected).map(x=>x._id)
        })

        const newToolPickupDelivery = async (id)=>{
            setModalBlocked(true)
            isWorking.value = true
            responseError.value = null
            let body = {
                projectId:id,
                shipmentDate:new Date(shipmentDate.value).toISOString(),
                toolsToReturn:selectedTools.value,
                shipmentAddress:props.address
            }
            await api
            .post(`shipments/createToolReturn`,body)
            .then(res=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    emit("update",res.data.data)
                }
            })
            .catch((err)=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                isWorking.value = false
                setModalBlocked(false)
            })
        }

        return {
            isWorking,
            responseError,
            shipmentDate,
            isValid,
            parseISO,
            newToolPickupDelivery,
            selectedTools
        }
    }

}
</script>

<style>

</style>