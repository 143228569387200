<template>
  <div v-show="!uploading" class="w-full flex flex-col p-2 flex-grow text-xs max-w-6xl">
    <WorkingAndError :isWorking="isWorking" :error="responseError" />
    <div class="w-full flex flex-col space-y-2 items-center p-1 border-b">
        <div class="border-2 max-w-min rounded-md py-2 px-3"
        :class="{'bg-sky-600 border-sky-900' : dropZoneActive}"
        @drop.prevent="addFilesToUpload"
        @dragover.prevent
        @dragenter.prevent="toggleDropZone"
        @dragleave.prevent="toggleDropZone"
        >
            <DocumentAddIcon class="h-10 w-10" />
        </div>
        <div class="self-center p-1">
            <label class="bn-label bn-solid-sky p-1 w-20 rounded-md text-xs">
                <span>Add Files</span>
                <input type="file" @change="addFilesToUpload($event)" hidden multiple />
            </label>
        </div>
    </div>
    <div v-if="Array.isArray(uploadErrors) && uploadErrors.length > 0" class="w-full flex flex-col space-y-1 p-1">
        <div v-for="(error,index) in uploadErrors" :key="index" class="w-full text-center ">
            <div class="w-full text-centertruncate whitespace-normal overflow-hidden break-words truncate text-red-600 p-1">
                {{error}}
            </div>
        </div>
    </div>
    <div v-if="route === 'photos' && Array.isArray(locationOptions) && locationOptions.length > 0 && !uploading" class="w-full p-1 flex flex-col items-center">
        <select v-model="defaultLocation" v-if="Array.isArray(locationOptions) && locationOptions.length > 0" class="w-full text-left max-w-72 p-1">
            <option value="">Apply Room/Rack</option>
            <option v-for="(option,index) in locationOptions" :key="index" :value="option.id">{{option.name}}</option>
        </select>
    </div>
    <div v-if="Array.isArray(filesToUpload) && filesToUpload.length > 0" class="w-full border-y px-1 py-2 flex flex-row items-center justify-between flex-wrap-reverse truncate">
        <div class="flex-grow text-left font-semibold overflow-hidden whitespace-normal truncate break-words p-1">
            {{`${cleanFilesToUpload.length} files to upload.`}}
        </div>
        <button v-if="emitResults" class="bn-solid-green py-0.5 px-2" :disabled="(Array.isArray(convertingFiles) && convertingFiles.length > 0)"
            @click="emitFiles()"
        >
            <div>Add</div>
            <PlusIcon />
        </button>
        <button v-else class="bn-solid-green py-0.5 px-2" :disabled="(Array.isArray(convertingFiles) && convertingFiles.length > 0) || (cleanFilesToUpload && cleanFilesToUpload.length === 0)"
            @click="uploadFiles()"
        >
            <UploadIcon />
            <div>Upload</div>
        </button>
    </div>
    <div v-if="Array.isArray(convertingFiles) && convertingFiles.length > 0" class="w-full py-1 px-2 text-left whitespace-normal animate-pulse text-sky-600">
        {{`Converting ${convertingFiles.length} files.`}}
    </div>
    <div v-if="Array.isArray(filesToUpload) && filesToUpload.length > 0" class="w-full flex flex-col flex-grow flex-auto h-0 rounded-md overflow-y-auto p-1">
        <div class="w-full flex flex-col flex-grow items-center p-1 overflow-y-auto space-y-2">
            <div v-for="(file,index) in filesToUpload" :key="index"  class="w-full flex flex-row items-center max-w-5xl">
                <div class="w-full flex items-center p-1 border rounded-md" :class="previewToShow === file.slotId ? 'flex-col sm:flex-row' : 'flex-row'">
                    <div v-if="!file.convertingFile && supportedImageTypes.includes(file.type)" class="items-center p-2" :class="previewToShow !== file.slotId ? 'w-auto items-center hidden sm:flex': 'w-full flex justify-center sm:w-auto' ">
                        <img :src="imagePreview(index)" alt="Preview" class="object-contain" :class="previewToShow !== file.slotId ? 'w-32' : 'w-44 sm:w-32'">
                    </div>
                    <div class="w-full flex flex-col">
                        <div class="w-full flex flex-row items-center truncate">
                            <div class=" text-left flex-grow p-1 overflow-hidden whitespace-normal break-words truncate">
                                {{file.name}}
                            </div>
                            <div class="p-1 flex items-center">
                                <button class="bn-icon-only" @click="removeFileFromUploadList(index)">
                                    <TrashIcon />
                                </button>
                            </div>
                        </div>
                        <div v-if="file.convertingFile" class="full text-left whitespace-normal truncate text-sky-600">
                            <LoadingData :message="`Converting ${file?.name || 'File'} name to jpeg...`" />
                        </div>
                        <div v-else-if="file.error" class="full text-left whitespace-normal truncate text-red-600 p-1">
                            {{'Failed to convert file.'}}
                        </div>
                        <div v-else class="w-full flex flex-col items-center">
                            <div class="w-full flex flex-row items-center truncate border-t flex-wrap">
                                <div v-if="['organizations','projects'].includes(model) && route !== 'photos'" class="text-left flex-grow p-1">
                                    <select v-model.trim="file.docPurpose" v-if="Array.isArray(documentTypeOptions) && documentTypeOptions.length > 0" class="w-full text-left max-w-72 p-1">
                                        <option hidden value="">document type</option>
                                        <option v-for="(option,index) in documentTypeOptions" :key="index" :value="option">{{option}}</option>
                                    </select>
                                </div>
                                <div v-if="['projects'].includes(model)" class="text-left p-1" :class="{'flex-grow':route==='photos'}">
                                    <select v-model.trim="file.location" v-if="Array.isArray(locationOptions) && locationOptions.length > 0" class="w-full text-left max-w-72  p-1">
                                        <option value="">Room/Rack</option>
                                        <option v-for="(option,index) in locationOptions" :key="index" :value="option.id">{{option.name}}</option>
                                    </select>
                                </div>
                                <div v-if="['organizations'].includes(model)" class="text-left p-1">
                                    <div class="w-full flex flex-col space-y-0.5">
                                        <input type="date" v-model="file.expirationDate" class="w-32 min-w-32 flex-shrink-0" />
                                        <div class="w-32 min-w-32 flex-shrink-0 px-1 text-left opacity-60">expires</div>
                                    </div>
                                </div>
                                <div v-else-if="route === 'photos'" class="text-left p-1">
                                    <div class="w-full flex flex-col space-y-0.5">
                                        <input type="date" v-model="file.photoDate" class="w-32 min-w-32 flex-shrink-0" 
                                        @keydown.prevent="null"
                                        />
                                        <div class="w-32 min-w-32 flex-shrink-0 px-1 text-left opacity-60">report date</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="route === 'photos'" class="w-full p-1 border-t">
                                <textarea v-model.trim="file.notes" v-auto-resize placeholder="photo notes" class="w-full resize-none min-h-12 max-h-72" rows="2" maxlength="100" @focus="previewToShow = file.slotId" @blur="previewToShow = null" />
                            </div>
                            <div v-else class="w-full p-1 border-t">
                                <textarea v-model.trim="file.description" v-auto-resize placeholder="description" class="w-full min-h-12 max-h-72" rows="1" maxlength="500" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div v-show="uploading" class="w-full flex flex-col p-2 flex-grow items-center text-xs max-w-6xl">
    <div v-if="uploading && uploadComplete" class="w-full flex flex-row items-center p-1 justify-end">
        <button class="px-2 py-0.5 bn-solid-green" @click="clearFiles()">
            <div>Upload More</div>
            <UploadIcon />
        </button>
    </div>
    <div class="w-full text-left whitespace-normal break-words overflow-x-clip max-w-4xl p-1">{{`${uploadPercentage.filter((x)=> x === 100).length} of ${cleanFilesToUpload.length} files uploaded.`}}</div>
    <div class="w-full flex flex-col flex-grow flex-auto items-center h-0 p-1 overflow-y-auto max-w-4xl space-y-1">
        <div v-for="(file,index) of cleanFilesToUpload" :key="index" class="w-full flex-col space-y-1 p-1 border rounded-md">
            <div class="w-full text-left whitespace-normal break-words overflow-x-clip p-1">
                {{file.name}}
            </div>
            <div v-if="uploadResponseErrors[index]" class="w-full p-1 text-left whitespace-normal break-words overflow-x-clip text-red-600">
                {{uploadResponseErrors[index]}}
            </div>
            <div v-else class="w-full p-1 text-left">
                <div v-if="uploadPercentage[index] < 100"><LoadingData :message="'Uploading File'" /></div>
                <progressBar :uploadPercentage="uploadPercentage[index]" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {DocumentAddIcon,TrashIcon,UploadIcon,PlusIcon} from '@heroicons/vue/outline'
import {isValid} from 'date-fns'
import {dateInputToISO,formatISODateforInput} from '@/shared'
import { computed,inject,ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import heic2any from 'heic2any'
import api from '@/api'
import progressBar from '../utils/progressBar.vue'
import autoResize from '@/components/ui/autoResize'

export default {
    directives:{
        autoResize
    },
    props:{
        emitResults:{type:Boolean,default:false},
        model:{type:String,default:'projects'},
        route:{type:String,default:'documents'},
        entity:{type:Object,default:()=>{return {}}},
        locationOptions:{type:Array,default:()=>{return []}},
        documentTypeOptions:{type:Array,default:()=>{return []}},
        secure:{type:Boolean,default:false},
        invoiceId:{type:String,default:null}

    },
    emits:["addFiles","fileUploaded"],
    components:{progressBar,DocumentAddIcon,TrashIcon,UploadIcon,PlusIcon},
    setup (props,{emit}) {
        const global = inject('global')
        const {setModalBlocked,sendChangeEvent} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const dropZoneActive = ref(false)
        const filesToUpload = ref([])
        const uploadErrors = ref([])
        const organizationFileOptions = ref([])
        const projectFileOptions = ref([])
        const disallowedFileTypes = ['.exe', '.bat', '.cmd', '.com', '.cpl', '.dll', '.scr','.js', '.vbs', '.sh', '.ps1', '.msi','.tar', '.gz','.jar', '.reg', '.iso', '.bin'];
        const supportedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp', 'image/svg+xml']
        const uploading = ref(false)
        const uploadResponseErrors = ref([])
        const uploadPercentage = ref([])
        const uploadComplete = ref(false)
        const defaultLocation = ref('')
        const previewToShow = ref(null)

        const convertingFiles = computed(()=>{
            return filesToUpload.value.filter(x=>x.convertingFile)
        })

        const cleanFilesToUpload = computed(()=>{
            return filesToUpload.value.filter(x=>!x.error)
        })

        const toggleDropZone = ()=>{
            dropZoneActive.value = !dropZoneActive.value
        }

        const addFilesToUpload = (event)=>{
            uploadErrors.value = []
            let addedFiles
            if(event?.target?.files) {
                addedFiles = event.target?.files
            } else if(event?.dataTransfer?.files) {
                addedFiles = event.dataTransfer?.files 
            }
            dropZoneActive.value = false

            let sizeLimit = 200 * 1000 * 1000 //200mb
            let numberOfFilesLimit = 10
            if(filesToUpload.value.length + addedFiles.length > numberOfFilesLimit) {
                uploadErrors.value.push(`File uploads limited to ${numberOfFilesLimit} at a time.`)
            } else {
                for (let i = 0; i < addedFiles.length; i++) {
                    const item = addedFiles[i]

                    const fileExtension = item.name.split('.').pop().toLowerCase();

                    if (disallowedFileTypes.includes(`.${fileExtension}`)) {
                        uploadErrors.value.push(`${item.name} has a disallowed file type.`);
                        continue;
                    }

                    if(item.name && !filesToUpload.value.some(x=>x.name && (x.name.toLowerCase() === item.name.toLowerCase()))) {
                        if(item.size > sizeLimit) {
                            uploadErrors.value.push(`${item.name} exceeds limit of ${sizeLimit/100000} Mb`)
                        } else {
                            let slotId = uuidv4()
                            item.slotId = slotId
                            item.description = ''
                            item.docPurpose = ''
                            item.location = defaultLocation.value || ''
                            item.expirationDate = ''
                            item.photoDate = props.route === 'photos' ? formatISODateforInput(new Date().toISOString()) : ''
                            item.notes = ''
                            item.invoiceId = props.invoiceId || ''
                            item.archived = false
                            item.error = null
                            item.convertingFile = item.type && item.type.toLowerCase().includes('hei')
                            ? true
                            : false
                            filesToUpload.value.push(item)
                        

                            if(item.type && item.type.toLowerCase().includes('hei')) {
                                let newFileName = item.name.substring(0,item.name.lastIndexOf(".")) || item.name
                                let blob = item
                                heic2any({
                                    blob:blob,
                                    toType:'image/jpeg'
                                })
                                .then((res)=>{
                                    let newItem = new File([res],`${newFileName}.jpeg`,{type:"image/jpeg"})
                                    newItem.slotId = slotId
                                    newItem.description = ''
                                    newItem.docPurpose = ''
                                    newItem.location = defaultLocation.value || ''
                                    newItem.expirationDate = ''
                                    newItem.photoDate = props.route === 'photos' ? formatISODateforInput(new Date().toISOString()) : ''
                                    newItem.notes = ''
                                    newItem.invoiceId = props.invoiceId || ''
                                    newItem.convertingFile = false
                                    newItem.error = false

                                    let itemToReplace = filesToUpload.value.findIndex(x=>x.slotId === slotId )
                                    if(itemToReplace > -1) {
                                        filesToUpload.value[itemToReplace] = newItem
                                    }
                                })
                                .catch(()=>{
                                    console.error('failed to convert file')
                                    item.convertingFile = false
                                    let newItem = new File([item],`${newFileName}.jpeg`,{type:"image/heic"})
                                    newItem.slotId = slotId
                                    newItem.description = ''
                                    newItem.docPurpose = ''
                                    newItem.expirationDate = ''
                                    newItem.photoDate = props.route === 'photos' ? formatISODateforInput(new Date().toISOString()) : ''
                                    newItem.notes = ''
                                    newItem.invoiceId = props.invoiceId || ''
                                    newItem.convertingFile = false
                                    newItem.error = true
                                    let itemToReplace = filesToUpload.value.findIndex(x=>x.slotId === slotId )
                                    if(itemToReplace > -1) {
                                        filesToUpload.value[itemToReplace] = newItem
                                    }
                                })
                            }
                        }
                    }
                }
            }
            if(event.target?.value) {event.target.value = ''}
        }

        const removeFileFromUploadList = (index)=>{
            filesToUpload.value.splice(index,1)
            uploadErrors.value.splice(index,1)
        }

        const imagePreview = (index)=>{
            try {
                if (index >= 0 && index < filesToUpload.value.length) {
                    return URL.createObjectURL(filesToUpload.value[index]);
                } else {
                    return ''
                }
            } catch (error) {
                console.error('Error creating image preview:', error.message);
                return ''; // Return an empty string or a placeholder URL
            }
        }

        const emitFiles = ()=>{
            const filesToSend = filesToUpload.value.filter(x=>!x.error)
            emit("addFiles",filesToSend)
            filesToUpload.value = []
            uploadErrors.value = []
        }

        const uploadFiles = async ()=>{
            uploading.value = true
            setModalBlocked(true)
            uploadResponseErrors.value = []
            for await (let[index,e] of cleanFilesToUpload.value.entries()) {
                const form = new FormData()
                form.append("dlsFile", e);
                form.append("expirationDate",e.expirationDate)
                form.append('docPurpose',e.docPurpose)
                form.append('description',e.description)
                form.append('location',e.location)
                form.append('photoDate',e.photoDate ? dateInputToISO(e.photoDate) : '')
                form.append('notes',e.notes)
                uploadPercentage.value[index] = 0
                await api
                .post(`${props.model}/${props.secure ? 'secure/':''}${props.route}/${props.entity._id}`,form,{
                    headers: {
                    "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: function (progressEvent) {
                    uploadPercentage.value[index] = parseInt(
                        Math.round((progressEvent.loaded / progressEvent.total) * 100)
                    );
                    },
                })
                .then((res)=>{
                    if(res.data?.data && typeof res.data.data === 'object') {
                        emit("fileUploaded",res.data.data)
                        let changeEventKey
                        switch (props.model) {
                            case 'projects':
                                changeEventKey = 'projectId'
                                break;
                            default:
                                changeEventKey = 'projectId'
                                break;
                        }
                        let changeEventPayload = {
                            [changeEventKey]:props.entity?._id,
                            secure:props.secure,
                            file:res?.data?.data
                        }
                        sendChangeEvent('document_upload','','',changeEventPayload)
                    }
                })
                .catch((err)=>{
                    uploadPercentage.value[index] = -1
                    uploadResponseErrors.value[index] = err.response?.data?.error || err.message
                    if(uploadResponseErrors.value[index] === "Network Error") {
                        uploadResponseErrors.value[index] = "Could Not Upload"
                    }
                })
            }
            setModalBlocked(false)
            uploadComplete.value = true
        }


        const clearFiles = ()=>{
            filesToUpload.value = []
            uploadResponseErrors.value = []
            uploadPercentage.value = []
            uploading.value = false
        }

        return {
            dropZoneActive,
            toggleDropZone,
            addFilesToUpload,
            filesToUpload,
            uploadErrors,
            removeFileFromUploadList,
            organizationFileOptions,
            projectFileOptions,
            convertingFiles,
            imagePreview,
            supportedImageTypes,
            emitFiles,
            isWorking,
            responseError,
            uploading,
            uploadFiles,
            cleanFilesToUpload,
            uploadResponseErrors,
            uploadPercentage,
            uploadComplete,
            clearFiles,
            isValid,
            formatISODateforInput,
            defaultLocation,
            previewToShow
        }
    }
}
</script>

<style>

</style>