<template>
    <div v-if="allowEdit" class="w-full flex max-w-4xl p-1">
        <button class="bn-solid px-2 py-0.5" @click="showChangeOrder = !showChangeOrder" :disabled="isModalBlocked">
            <div v-if="showChangeOrder" class="flex items-center space-x-1">
                <ChevronRightIcon class="rotate-180" />
                <div>Services</div>
            </div>
            <div v-else class="flex items-center space-x-1">
                <div>Add Services</div>
                <PlusIcon />
            </div>
        </button>
    </div>
    <WorkingAndError :isWorking="false" :error="responseError" />
    <div class="w-full flex flex-col flex-grow p-2 items-center max-w-4xl">
        <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto p-2">
            <div class="w-full flex flex-col flex-grow items-center overflow-y-auto">
                <ProjectServices v-show="!showChangeOrder" :services="project.services" :billingRegion="project.billingRegion" :allowEdit="allowEdit && Array.isArray(project.invoices) && project.invoices.length === 0" @deleteItem="deleteServiceItem($event)" />
                <ChangeOrder v-show="showChangeOrder" :project="project" @triggerProjectUpdate="triggerProjectUpdate()" />
            </div>
        </div>
    </div>
</template>

<script>

import {inject, onMounted, ref } from 'vue'
import {ChevronRightIcon,PlusIcon} from "@heroicons/vue/solid"
import ProjectServices from "@/components/projects/services/ProjectServices.vue"
import ChangeOrder from './ChangeOrder.vue'
import api from '@/api'


export default {
    props:{
        project:{type:Object,default:()=>{return {}}},
        allowEdit:{type:Boolean,default:false}
    },
    emits:["triggerProjectUpdate"],
    components:{ProjectServices,ChangeOrder,ChevronRightIcon,PlusIcon},
    setup (props,{emit}) {
        const responseError = ref(null)
        const global = inject('global')
        const {isModalBlocked,setModalBlocked,sendChangeEvent} = global
        const showChangeOrder = ref(false)

        onMounted(()=>{
        })

        const deleteServiceItem = async (event)=>{
            setModalBlocked(true)
            responseError.value = null
            await api
            .delete(`projects/services/${props.project._id}/${event}`)
            .then((res)=>{
                if(res.data?.data && typeof res.data.data === 'object') {
                    triggerProjectUpdate()
                    sendChangeEvent('project',props.project?._id,'',{projectId:props.project?._id})
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
            })
        }

        const triggerProjectUpdate = async ()=>{
            emit("triggerProjectUpdate")
        }

        //**Event Handler for Service Catalog

        return {
            responseError,
            showChangeOrder,
            triggerProjectUpdate,
            isModalBlocked,
            deleteServiceItem 
        }
    }

}
</script>

<style>

</style>