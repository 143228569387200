<template>
  <div class="w-full flex flex-col flex-grow">
    <WorkingAndError :isWorking="isWorking" :error="responseError"/>
        <div v-if="!invoice.requestDate" class="w-full flex items-center truncate p-1 border-b">
            <input v-model="localForecastDate" type="date" v-date-input-control
            @change="isValid(new Date(localForecastDate)) ? editForecastDate() : null "
            :disabled="isWorking"
            />
        </div>
    <!-- Top Actions -->
        <div class="w-full flex items-center border-b p-1 truncate flex-wrap">
            <div class="w-auto flex items-center flex-nowrap border rounded-md px-1 truncate">
                <div class="p-1">
                    <input v-percentage-only v-model="depositPercentage" type="number" class="max-w-10 text-right" :disabled="isWorking || !canEditInvoice || !Array.isArray(invoice.lineItems) || invoice.lineItems.length > 0">
                </div>
                <div class="p-1 font-semibold">%</div>
                <div class="p-1">
                    <button class="bn-solid-green px-1 py-0.5" @click="addPercentageOfAll()" :disabled="isWorking || !canEditInvoice || !Number(depositPercentage) || depositPercentage < 1 || depositPercentage > 100 || !Array.isArray(invoice.lineItems) || invoice.lineItems.length > 0">
                        <div v-if="canDeposit">Deposit +</div>
                        <div v-else>Invoice %</div>
                    </button>
                </div>
            </div>
            <div class="p-1">
                <button class="bn-solid-sky px-1 py-0.5" :disabled="isWorking || !canEditInvoice || !Array.isArray(invoice.lineItems) || invoice.lineItems.length > 0" @click="addRemainingUnbilled()">
                    <div>Remaining $</div>
                </button>
            </div>
            <div class="flex items-center flex-grow justify-end px-1 truncate">
                <div v-if="!canEditInvoice" class="w-auto px-1 text-sky opacity-70">
                    <LockClosedIcon class="w-5 h-5 flex-shrink-0" />
                </div>
                <button v-else class="bn-solid-green px-1 py-0.5" :disabled="invoice.requestedAmount === 0 || !canEditInvoice" @click="sendToInvoicing()">
                    <div>Send</div>
                    <MailIcon class="w-3 h-3 flex-shrink-0" />
                </button>
            </div>
        </div>
    <!-- Project Totals -->
        <div v-if="revenueSummary && typeof revenueSummary === 'object'" class="w-full p-1 flex items-center justify-between truncate flex-wrap">
        <div class="flex flex-col space-y-0.5 px-1 text-green-600">
                <div>{{showAsUSCurrency(revenueSummary.servicesRevenueTotal)}}</div>
                <div class="opacity-70">Sold</div>
            </div>
            <div class="flex flex-col space-y-0.5 px-1">
                <div>{{showAsUSCurrency(revenueSummary.lineItemsRevenueTotal)}}</div>
                <div class="opacity-70">Added to Invoice</div>
            </div>
            <div class="flex flex-col space-y-0.5 px-1" :class="revenueSummary.revenueDifference < 0 ? 'text-red-700' : 'text-green-700'">
                <div>{{showAsUSCurrency(revenueSummary.revenueDifference)}}</div>
                <div class="opacity-70">Remaining</div>
            </div>
        </div>
        <div class="w-full flex items-center flex-wrap truncate p-1 border-t">
            <div class="w-auto font-semibold">Invoice total:</div>
            <div class="flex-grow text-left px-1">{{showAsUSCurrency(invoice.requestedAmount)}}</div>
            <div class="p-1">
                <button class="bn-solid-white text-red-600 px-1 py-0.5" :disabled="isWorking || !canEditInvoice || !Array.isArray(invoice.lineItems) || invoice.lineItems.length === 0" @click="deleteAllLineItems()">
                    <div>Clear Invoice</div>
                </button>
            </div>
        </div>
    <!-- Services and Line Items -->
        <!-- Services -->
        <div class="w-full flex flex-col flex-auto h-0 overflow-auto p-1">
            <div class="w-full flex flex-col flex-grow border overflow-y-auto">
                <div class="w-full flex flex-grow flex-row items-start overflow-x-auto">
                    <div class="w-full h-full flex-grow min-w-80 overflow-y-auto p-1 border-r">
                        <div class="w-full flex flex-col flex-grow overflow-y-auto">
                            <div v-for="service in servicesSorted" :key="service._id" class="w-full p-1 flex flex-col">
                                <div class="w-full flex items-center truncate p-0.5 border-b">
                                    <div class="text-left whitespace-normal text-sky flex-grow">
                                        {{service.partNumber }}
                                    </div>
                                    <div class="text-left whitespace-normal truncate line-clamp-2">
                                        {{ service.description }}
                                    </div>
                                </div>
                                <div class="w-full flex items-center truncate flex-wrap p-0.5 border-b">
                                    <div class="flex-grow text-left whitespace-normal">{{ showAsUSCurrency(service.revenue) }}</div>
                                    <div class="flex flex-grow flex-nowrap justify-end">
                                        <div class="px-1 w-auto text-sky">
                                            Bal:
                                        </div>
                                        <div class="w-auto text-right whitespace-normal">
                                            {{allLineItemsGroup[service._id] ? showAsUSCurrency(service.revenue - (allLineItemsGroup[service._id] || 0)) : showAsUSCurrency(service.revenue) }}
                                        </div>
                                    </div>
                                </div>
                                <div v-if="canEditInvoice" class="w-full flex items-center truncate flex-wrap p-0.5">
                                    <div class="w-auto flex flex-grow flex-nowrap p-1 items-center">
                                        <input v-model="service.addAmount" type="text" v-currency maxlength="14" class="text-right" placeholder="$ amount" :disabled="isWorking">
                                        <button class="bn-icon-only" :disabled="isWorking || !service.addAmount || Number(service.addAmount) === 0"
                                            @click="addAmountToInvoice(service._id,service.addAmount)"
                                        >
                                            <ArrowSmRightIcon />
                                        </button>
                                    </div>
                                    <div class="w-auto flex flex-nowrap p-1 space-x-1 items-center">
                                        <input v-model="service.addPercentage" v-percentage-only type="number" class="max-w-10 text-right" placeholder="%" :disabled="isWorking">
                                        <button class="bn-icon-only" :disabled="isWorking || !service.addPercentage || service.addPercentage < 1 || service.addPercentage > 100"
                                            @click="addPercentageToInvoice(service._id,service.addPercentage)"
                                        >
                                            <ArrowSmRightIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- Line Items -->
                    <div class="w-full h-full min-w-80 overflow-y-auto p-1">
                        <div v-if="Array.isArray(lineItemsSorted)" class="w-full flex flex-col flex-grow overflow-y-auto">
                            <div class="w-full flex flex-col flex-grow overflow-y-auto">
                                <div v-for="(invoiceService,index) in lineItemsSorted" :key="index" class="w-full p-1 flex items-center">
                                    <div class="w-full flex flex-col">
                                        <div class="w-full flex items-center truncate p-0.5 border-b">
                                            <div class="text-left whitespace-normal text-sky flex-grow">
                                                {{invoiceService.partNumber }}
                                            </div>
                                            <div class="text-left whitespace-normal truncate line-clamp-2">
                                                {{ invoiceService.description }}
                                            </div>
                                        </div>
                                        <div class="w-full flex items-center truncate flex-wrap p-0.5 border-b">
                                            <div class="flex-grow text-right whitespace-normal">{{ showAsUSCurrency(invoiceService.revenue) }}</div>
                                        </div>
                                    </div>
                                    <div v-if="invoiceService._id" class="p-1">
                                        <ButtonWithConfirm :iconComponent="TrashIcon" :disabled="isWorking || !canEditInvoice" @confirmed="deleteLineItem(invoiceService._id)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref,watch } from 'vue'
import api from '@/api'
import percentageOnly from '@/components/ui/percentageOnly'
import currency from  '@/components/ui/currency'
import dateInputControl from '@/components/ui/dateInputControl'
import {showAsUSCurrency,formatISODateforInput,dateInputToISO} from '@/shared'
import {ArrowSmRightIcon,TrashIcon} from '@heroicons/vue/outline'
import {MailIcon,LockClosedIcon} from '@heroicons/vue/solid'
import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'
import { isValid } from 'date-fns'
export default {
    directives:{
        percentageOnly,
        currency,
        dateInputControl
    },
    props:{
        invoice:{type:Object,default:()=>{return {}}},
        invoices:{type:Array,default:()=>{return []}},
        projectId:{type:String,default:''},
        allowInvoiceActivity:{type:Boolean,default:false}
    },
    emits:["updateInvoice"],
    components:{ArrowSmRightIcon,ButtonWithConfirm,MailIcon,LockClosedIcon},
    setup (props,{emit}) {
        const global = inject('global')
        const {setModalBlocked} = global
        const isWorking = ref(false)
        const responseError = ref(null)
        const services = ref([])
        const depositPercentage = ref(20)
        const localForecastDate = ref(formatISODateforInput(props.invoice.forecastDate))

        onMounted(()=>{
            getServices()
        })

        watch(() => props.invoice,(newInvoice) => {
                localForecastDate.value = formatISODateforInput(newInvoice.forecastDate);
            },
            { immediate: true, deep: true } // 'immediate' ensures it's run on component mount
        );

        const canDeposit = computed(()=>{
            return Array.isArray(props.invoices) && props.invoices.length === 1 && Array.isArray(props.invoice.lineItems) && props.invoice.lineItems.length == 0
        })

        const canEditInvoice = computed(()=>{
            return !props.invoice.requestDate && props.allowInvoiceActivity
        })

        const servicesSorted = computed(() => {
            return services.value.slice().sort((a, b) => {
                // Sort by partNumber first
                if (a.partNumber < b.partNumber) return -1;
                if (a.partNumber > b.partNumber) return 1;

                // If partNumber is the same, sort by description
                if (a.description < b.description) return -1;
                if (a.description > b.description) return 1;

                return 0;
            });
        });

        const lineItemsSorted = computed(()=>{
            if(Array.isArray(props.invoice.lineItems)) {
                return props.invoice.lineItems.slice().sort((a,b)=>{
                    if (a.partNumber < b.partNumber) return -1;
                    if (a.partNumber > b.partNumber) return 1;

                    // If partNumber is the same, sort by description
                    if (a.description < b.description) return -1;
                    if (a.description > b.description) return 1;

                    return 0;
                })
            } else {
                return []
            }
        })

        const allLineItems = computed(()=>{
            return props.invoices.flatMap(invoice => invoice.lineItems || []);
        })
        
        const allLineItemsGroup = computed(() => {

            // Create an object to store the grouped sum by serviceId
            const groupedRevenue = {};

            // Loop through each item and group by serviceId
            allLineItems.value.forEach(item => {
                if (item.serviceId) {
                // Initialize the group if it doesn't exist
                if (!groupedRevenue[item.serviceId]) {
                    groupedRevenue[item.serviceId] = 0
                }

                // Add the revenue to the corresponding serviceId
                groupedRevenue[item.serviceId] += item.revenue || 0;
                }
            });
            // Return the object where keys are serviceIds and values are the revenue info
            return groupedRevenue;
        });

        const revenueSummary = computed(() => {
            let servicesRevenueTotal = 0;
            let lineItemsRevenueTotal = 0;

            // Manually sum up the revenue in the `services` array
            if (services.value && services.value.length > 0) {
                for (let i = 0; i < services.value.length; i++) {
                const service = services.value[i];
                servicesRevenueTotal += Number(service.revenue) || 0; // Convert revenue to a number, default to 0
                }
            }

            // Manually sum up the revenue in the `allLineItems` array
            if (allLineItems.value && allLineItems.value.length > 0) {
                for (let i = 0; i < allLineItems.value.length; i++) {
                const lineItem = allLineItems.value[i];
                lineItemsRevenueTotal += Number(lineItem.revenue) || 0; // Convert revenue to a number, default to 0
                }
            }

            // Calculate the difference between the two
            const revenueDifference = servicesRevenueTotal - lineItemsRevenueTotal;

            // Return the summary object
            return {
                servicesRevenueTotal,
                lineItemsRevenueTotal,
                revenueDifference
            };
        });

        const getServices = async ()=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            await api
            .get(`projects/projectServices/${props.projectId}`)
            .then(res=>{
                if(Array.isArray(res.data?.data?.services)) {
                    services.value = res.data.data.services
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const addPercentageToInvoice = (serviceId,percentage)=>{
            let serviceItem = services.value.find(x=>x._id === serviceId)
            if(serviceItem) {
                let pctToApply = percentage/100 || 1
                let revenue = serviceItem.revenue * pctToApply || 0
                let body = {
                    serviceId:serviceItem._id,
                    partNumber:serviceItem.partNumber,
                    description:serviceItem.description,
                    revenue
                }
                addLineItemToInvoice(body,serviceItem)
            }
        }

        const addAmountToInvoice = async (serviceId,amount)=>{
            let serviceItem = services.value.find(x=>x._id === serviceId)
            if(serviceItem) {
                let revenue =  Number(amount.replace(/,/g, ''));
                let body = {
                    serviceId:serviceItem._id,
                    partNumber:serviceItem.partNumber,
                    description:serviceItem.description,
                    revenue
                }
                addLineItemToInvoice(body,serviceItem)
                serviceItem.addAmount = null
            }
        }

        const addLineItemToInvoice = async (body,serviceItem=null) =>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            await api
            .put(`invoices/lineItem/${props.invoice?._id}`,body)
            .then(res=>{
                if(res?.data?.data && typeof res.data.data === 'object') {
                    emit("updateInvoice",res.data.data)
                }
                if(serviceItem) {
                    serviceItem.addAmount = null
                    serviceItem.addPercentage = null
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const addPercentageOfAll = async () => {
            isWorking.value = true
            responseError.value = null
            const percentage = parseFloat(depositPercentage.value); // Convert to number

            // Validate that depositPercentage is a valid number and greater than 0
            if (isNaN(percentage) || percentage <= 0) {
                responseError.value = "Invalid deposit percentage. It must be a number greater than 0."
                isWorking.value = false
                return;
            }

            let pctToApply = percentage/100 || 1

            let arrayOfLineItems = []

            services.value.forEach(service=>{
                let revenue = service.revenue * pctToApply;

            // Ensure that revenue is calculated correctly even if it's 0
            if (isNaN(revenue)) {
                revenue = 0; // Fallback in case revenue is invalid
            }

            let item = {
                    serviceId:service._id,
                    partNumber:service.partNumber,
                    description:service.description,
                    revenue
                }
                arrayOfLineItems.push(item)
            })

            arrayOfLineItems = arrayOfLineItems.filter(x=>x.revenue && x.revenue !== 0)

            if(arrayOfLineItems.length === 0) {
                isWorking.value = false
                return;
            }

            setModalBlocked(true)
            await api
            .put(`invoices/lineItemMany/${props.invoice?._id}`,arrayOfLineItems)
            .then(res=>{
                if(res?.data?.data && typeof res.data.data === 'object') {
                    emit("updateInvoice",res.data.data)
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        };

        const deleteLineItem = async (id)=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            await api
            .delete(`invoices/lineItem/${props.invoice?._id}/${id}`)
            .then(res=>{
                if(res?.data?.data && typeof res.data.data === 'object') {
                    emit("updateInvoice",res.data.data)
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const editForecastDate = async ()=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            let body = {forecastDate:dateInputToISO(localForecastDate.value)}
            await api
            .put(`invoices/edit/${props.invoice._id}`,body)
            .then(res=>{
                if(res?.data?.data && typeof res.data.data === 'object') {
                    emit("updateInvoice",res.data.data)
                }
            })
            .catch(err=>{
                localForecastDate.value = formatISODateforInput(props.invoice.forecastDate)
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })

        }

        const deleteAllLineItems = async ()=>{
            isWorking.value = true
            setModalBlocked(true)
            await api
            .delete(`invoices/lineItemMany/${props.invoice?._id}`)
            .then(res=>{
                if(res?.data?.data && typeof res.data.data === 'object') {
                    emit("updateInvoice",res.data.data)
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        const addRemainingUnbilled = async () => {
            isWorking.value = true;
            responseError.value = null;
            let arrayOfLineItems = [];

            // Create a map of all line items grouped by serviceId for fast lookup
            const lineItemsMap = {};
            allLineItems.value.forEach(item => {
            if (item.serviceId) {
            if (!lineItemsMap[item.serviceId]) {
            lineItemsMap[item.serviceId] = 0;
            }
            lineItemsMap[item.serviceId] += item.revenue || 0;
            }
            });

            services.value.forEach(service => {
            let totalLineItemRevenue = lineItemsMap[service._id] || 0;

            // Calculate remaining unbilled revenue
            let remainingRevenue = service.revenue - totalLineItemRevenue;

            // If no line item revenue, fallback to service.revenue
            if (totalLineItemRevenue === 0) {
            remainingRevenue = service.revenue;
            }

            // Ensure revenue is valid (not NaN)
            if (isNaN(remainingRevenue)) {
            remainingRevenue = 0;
            }

            let item = {
                serviceId: service._id,
                partNumber: service.partNumber,
                description: service.description,
                revenue: remainingRevenue,
            };

                arrayOfLineItems.push(item);
            
            });

            arrayOfLineItems = arrayOfLineItems.filter(x=>x.revenue && x.revenue !== 0)

            if (arrayOfLineItems.length === 0) {
                isWorking.value = false;
                return;
            }

            setModalBlocked(true);
            await api
            .put(`invoices/lineItemMany/${props.invoice?._id}`, arrayOfLineItems)
            .then(res => {
                if (res?.data?.data && typeof res.data.data === 'object') {
                emit("updateInvoice", res.data.data);
            }
            })
            .catch(err => {
                responseError.value = err.response?.data?.error || err.message;
            })
            .finally(() => {
                setModalBlocked(false);
                isWorking.value = false;
            });
        };

        const sendToInvoicing = async ()=>{
            isWorking.value = true
            setModalBlocked(true)
            responseError.value = null
            let body = {requestDate:new Date().toISOString()}
            await api
            .put(`invoices/sendToQueue/${props.invoice._id}`,body)
            .then(res=>{
                if(res?.data?.data && typeof res.data.data === 'object') {
                    emit("updateInvoice",res.data.data)
                }
            })
            .catch(err=>{
                responseError.value = err.response?.data?.error || err.message
            })
            .finally(()=>{
                setModalBlocked(false)
                isWorking.value = false
            })
        }

        return {
            isWorking,
            responseError,
            services,
            depositPercentage,
            servicesSorted,
            lineItemsSorted,
            showAsUSCurrency,
            revenueSummary,
            addPercentageToInvoice,
            addAmountToInvoice,
            allLineItemsGroup,
            TrashIcon,
            deleteLineItem,
            canEditInvoice,
            addPercentageOfAll,
            deleteAllLineItems,
            addRemainingUnbilled,
            canDeposit,
            sendToInvoicing,
            localForecastDate,
            isValid,
            editForecastDate
        }
    }

}
</script>

<style>

</style>